import React, { useState, useEffect } from 'react';
import { Box, Grid,  Select, MenuItem } from '@mui/material';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import AddSupervisors from './AddSupervisors/AddSupervisors';
import BodyAreas from '../../components/Supervisors/BodyAreas';
import BodyTurnos from '../../components/Supervisors/BodyTurnos';
import SpinnerLoader from '../../components/general/SpinnerLoader';
import GeneralService from '../../../services/general';
import StaffService from '../../../services/staff';
import ShiftService from '../../../services/shift';
import { CODES } from '../../../utils/responseCodes';
import { Toast } from '../../../utils/alerts';

const Supervisors = ({ userToken }) => {
    const url = "'" + window.location.hash.replace('#', '') + "'";
    const idUsuario = userToken?.user.id_usuario;
    const [openModalLoader, setOpenModalLoader] = useState(true);

    //********************* */
    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const [infoCompany, setInfoCompany] = useState(null);
    const [infoClient, setInfoClient] = useState(null);
    const [infoUnit, setInfoUnit] = useState(null);
    const [infoCampus, setInfoCampus] = useState(null);
    const [infoArea, setInfoArea] = useState(null);
    const [infoShift, setInfoShift] = useState(null);

    const [companies, setCompanies] = useState(null);
    const [clients, setClients] = useState(null);
    const [units, setUnits] = useState(null);
    const [campus, setCampus] = useState(null);
    const [areas, setAreas] = useState(null);
    const [shift, setShift] = useState(null);

    const [body, setBody] = useState(0); //0: body areas, 1: body turnos
    const handleChangeBody = (event, newValue) => {
        setBody(newValue);
    };
    //********************* */

    useEffect(() => {
        init();
    }, [])

    const init = () => {
        Promise.all([
            getEmpresas(),
            getStaff(),
            getTurno(),
        ]).then(() => setOpenModalLoader(false));
    }

    //*****************************************/
    const [dataStaffs, setDataStaffs] = useState(null);
    const [searchStaff, setSearchStaff] = useState("");
    const [pageStaff, setPageStaff] = useState(1);
    const pageSizeStaff = 8;
    const [totalPagesStaff, setTotalPagesStaff] = useState(1);

    useEffect(() => {
        getStaff();
    }, [searchStaff, pageStaff])

    const getStaff = async () => {
        try {
            const result = await StaffService.getStaff(url, { search: searchStaff, page: pageStaff, pageSize: pageSizeStaff, pagination: 0, situacion: 1 });
            if (result.status === CODES.SUCCESS_200) {
                setDataStaffs(result.data.data);
                setTotalPagesStaff(result.data.last_page);
            }
            return [];
        } catch (error) {
            console.log(`Error en getStaff: ${error}`);
        }
    }

    const getTurno = async () => {
        try {
            const result = await ShiftService.getTurno();
            if (result.status === CODES.SUCCESS_200) {
                if (result?.data?.length === 0) {
                    Toast.fire({
                        icon: 'info',
                        title: 'No se encontraron turnos'
                    })
                    return;
                }
                result.data?.forEach(element => {
                    element.value = Number(element.id_turno);
                    element.label = element.desTurno;
                })
                setShift(result.data);
            }
        } catch (error) {
            console.log(`Error en getAreasBySede: ${error}`);
        }
    }

    //*****************************************/
    // useEffect(() => {
    //     getEmpresas();
    // }, [])

    useEffect(() => {
        if (infoCompany)
            getClientesByEmpresa();
        setUnits(null);
        setCampus(null);

        setInfoClient(null);
        setInfoUnit(null);
        setInfoCampus(null);
        setAreas(null);
    }, [infoCompany])

    useEffect(() => {
        if (infoClient) {
            getUnidadesByCliente();
        }
        setCampus(null);

        setInfoUnit(null);
        setInfoCampus(null);
        setAreas(null);
    }, [infoClient])

    useEffect(() => {
        if (infoUnit)
            getSedesByUnidad();
        setInfoCampus(null);
        setAreas(null);
    }, [infoUnit])

    useEffect(() => {
        setAreas(null);
        if (infoCampus)
            getAreasBySede();
    }, [infoCampus])

    const getEmpresas = async () => {
        await GeneralService.getEmpresas()
            .then(result => {
                if (result.status === CODES.SUCCESS_200) {
                    result.data?.forEach(element => {
                        element.id = element.id_empresa
                        element.value = element.id_empresa
                        element.label = element.desEmpresa
                    })
                    setCompanies(result.data);
                } else {
                    Toast.fire({
                        icon: 'info',
                        title: 'No hay información de empresas'
                    })
                }
            })
            .catch(err => {
                console.log('getEmpresas', err)
            })

    }

    const getClientesByEmpresa = async () => {
        await GeneralService.getClientesByEmpresa(infoCompany?.value)
            .then(result => {
                if (result.status === CODES.SUCCESS_200) {
                    result.data?.forEach(element => {
                        element.id = element.id_cliente
                        element.value = element.id_cliente
                        element.label = element.desCliente
                    })
                    setClients(result.data);
                } else {
                    Toast.fire({
                        icon: 'info',
                        title: 'No hay información de clientes'
                    })
                }
            })
            .catch(err => {
                console.log('getClientesByEmpresa', err)
            })
    }

    const getUnidadesByCliente = async () => {
        await GeneralService.getUnidadesByCliente(infoClient?.value)
            .then(result => {
                if (result.status === CODES.SUCCESS_200) {
                    result.data?.forEach(element => {
                        element.id = element.id_unidad
                        element.value = element.id_unidad
                        element.label = element.desUnidad
                    })
                    setUnits(result.data);
                } else {
                    Toast.fire({
                        icon: 'info',
                        title: 'No hay información de unidades'
                    })
                }
            })
            .catch(err => {
                console.log('getEmpresas', err)
            })
    }

    const getSedesByUnidad = async () => {
        await GeneralService.getSedesByUnidad(infoUnit?.value)
            .then(result => {
                if (result.status === CODES.SUCCESS_200) {
                    result.data?.forEach(element => {
                        element.id = element.id_sede
                        element.value = element.id_sede
                        element.label = element.desSede
                    })
                    setCampus(result.data);
                } else {
                    Toast.fire({
                        icon: 'info',
                        title: 'No hay información de sedes'
                    })
                }
            })
            .catch(err => {
                console.log('getEmpresas', err)
            })

    }

    const getAreasBySede = async () => {
        setOpenModalLoader(true);
        await GeneralService.getAreasBySede(infoCampus?.value).then(result => {
            if (result.status === CODES.SUCCESS_200) {
                result.data?.forEach(element => {
                    element.id = element.id_area
                    element.value = element.id_area
                    element.label = element.desArea
                })
                setAreas(result.data);
                setOpenModalLoader(false);
            } else {
                Toast.fire({
                    icon: 'info',
                    title: 'No hay información de áreas'
                })
            }
        }).catch(err => {
            console.log('getEmpresas', err)
        })
    }

    return (
        <Box sx={{ flexGrow: 1 }}>
            <AddSupervisors
                open={open}
                handleOpen={handleOpen}
                handleClose={handleClose}
                setOpenModalLoader={setOpenModalLoader}
                body={body}
                idUsuario={idUsuario}
                searchStaff={searchStaff}
                setSearchStaff={setSearchStaff}
                totalPagesStaff={totalPagesStaff}
                pageStaff={pageStaff}
                setPageStaff={setPageStaff}

                dataStaffs={dataStaffs}
                infoArea={infoArea}
                setInfoArea={setInfoArea}
                infoShift={infoShift}
                setInfoShift={setInfoShift}
            />
            <Box sx={{ width: '100%', bgcolor: 'background.paper' }}>
                <Tabs value={body} onChange={handleChangeBody} centered>
                    <Tab label="Área" />
                    <Tab label="Turno" />
                </Tabs>
            </Box>
            <Grid container
                direction="row"
                justifyContent="center"
                alignItems="center"
                mt={2}
            >
                <Grid item xs={12} sm={10} md={8} paddingLeft={'1rem'} paddingRight={'1rem'}>
                    {body === 0 ?
                        <>
                            <Grid container p={1}>
                                <Grid item xs={3}>
                                    <fieldset>
                                        <legend style={{ fontSize: 'medium' }}>Empresas</legend>
                                        <Select style={{ width: '95%' }}
                                            size='small'
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            value={Number(infoCompany?.value) || 0}
                                            onChange={(e) => setInfoCompany(companies?.find(element => Number(element.value) === Number(e.target.value)))}
                                        >
                                            <MenuItem value={0}><em>Seleccionar empresa</em></MenuItem>
                                            {companies?.map((opt) => {
                                                return (
                                                    <MenuItem key={'comp' + opt.id} value={opt.id}>{opt.id + ' - ' + opt.label}</MenuItem>
                                                )
                                            })}
                                        </Select>
                                    </fieldset>
                                </Grid>
                                <Grid item xs={3}>
                                    <fieldset>
                                        <legend style={{ fontSize: 'medium' }}>Clientes</legend>
                                        <Select style={{ width: '95%' }}
                                            size='small'
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            value={Number(infoClient?.value) || 0}
                                            onChange={(e) => setInfoClient(clients?.find(element => Number(element.value) === Number(e.target.value)))}
                                        >
                                            <MenuItem value={0}><em>Seleccionar cliente</em></MenuItem>
                                            {clients?.map((opt) => {
                                                return (
                                                    <MenuItem key={'cli' + opt.id} value={opt.id}>{opt.id + ' - ' + opt.label}</MenuItem>
                                                )
                                            })}
                                        </Select>
                                    </fieldset>
                                </Grid>
                                <Grid item xs={3}>
                                    <fieldset>
                                        <legend style={{ fontSize: 'medium' }}>Unidades</legend>
                                        <Select style={{ width: '95%' }}
                                            size='small'
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            value={Number(infoUnit?.value) || 0}
                                            onChange={(e) => setInfoUnit(units?.find(element => Number(element.value) === Number(e.target.value)))}
                                        >
                                            <MenuItem value={0}><em>Seleccionar unidad</em></MenuItem>
                                            {units?.map((opt) => {
                                                return (
                                                    <MenuItem key={'und' + opt.id} value={opt.id}>{opt.id + ' - ' + opt.label}</MenuItem>
                                                )
                                            })}
                                        </Select>
                                    </fieldset>
                                </Grid>
                                <Grid item xs={3}>
                                    <fieldset>
                                        <legend style={{ fontSize: 'medium' }}>Sedes</legend>
                                        <Select style={{ width: '95%' }}
                                            size='small'
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            value={Number(infoCampus?.value) || 0}
                                            onChange={(e) => setInfoCampus(campus?.find(element => Number(element.value) === Number(e.target.value)))}
                                        >
                                            <MenuItem value={0}><em>Seleccionar sede</em></MenuItem>
                                            {campus?.map((opt) => {
                                                return (
                                                    <MenuItem key={'sed' + opt.id} value={opt.id}>{opt.id + ' - ' + opt.label}</MenuItem>
                                                )
                                            })}
                                        </Select>
                                    </fieldset>
                                </Grid>
                            </Grid>
                            <BodyAreas handleOpen={handleOpen} areas={areas} setInfoArea={setInfoArea} />
                        </>
                        : <BodyTurnos handleOpen={handleOpen} shift={shift} setInfoShift={setInfoShift} />
                    }
                </Grid>
            </Grid>
            <SpinnerLoader open={openModalLoader} />
        </Box>
    );
};

export default Supervisors;