import axios from "axios";
import { Global } from "../utils/general";
import headerRequest from "../utils/headers";

const API_URL = Global.url;

class BatService {
  executionValidation(bat) {
    var ruta = `${API_URL}/execution-bat/validation/${bat}`;
    return axios
      .get(ruta, {
        headers: headerRequest(),
      })
      .catch((error) => {
        return error;
      });
  }
  
  getExecuteBigQuery() {
    var ruta = `${API_URL}/execution-bat/big-query`;
    return axios
      .get(ruta, {
        headers: headerRequest(),
      })
      .catch((error) => {
        return error;
      });
  }

  executionBigQueryAnual() {
    var ruta = `${API_URL}/execution-bat/big-query-anual`;
    return axios
      .get(ruta, {
        headers: headerRequest(),
      })
      .catch((error) => {
        return error;
      });
  }

  getExecuteRotacion(fechaMes, fechaMesAnt) {
    var ruta = `${API_URL}/execution-bat/rotacion?fechaMes=${fechaMes}&fechaMesAnt=${fechaMesAnt}`;
    return axios
      .get(ruta, {
        headers: headerRequest(),
      })
      .catch((error) => {
        return error;
      });
  }
}

export default new BatService();
