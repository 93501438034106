import React, { useState, useEffect } from 'react';
import { IconButton, Box, Grid, Button, TextField, MenuItem, Select, FormControl, InputLabel, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import AddAlarmIcon from '@mui/icons-material/AddAlarm';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import OverTimeService from '../../../services/overTime';
import GeneralService from '../../../services/general';
import DeleteIcon from '@mui/icons-material/Delete';
import { getDateOL, minutesDiff } from '../../../utils/function';
import { CODES } from '../../../utils/responseCodes';
import { Toast } from '../../../utils/alerts';
import OrganizationalService from '../../../services/organizational';
import SpinnerLoader from '../../components/general/SpinnerLoader';

const newOverTime = {
    persona_id: null,
    jefe_id: null,
    motivo: "",
    cantMinutos: null,
    tipoSobreTiempoTrab: 1,
    fecha: getDateOL()
}

const OverTime = ({ userToken }) => {
    const theme = useTheme();
    const url = "'" + window.location.pathname + "'";
    const [openModalLoader, setOpenModalLoader] = useState(true);
    const [data, setData] = useState(newOverTime);
    const [hour, setHour] = useState(null);
    const [minute, setMinute] = useState(null);
    const idPersona = userToken?.user.persona_id;
    const [dataSupervisor, setDataSupervisor] = useState(null);
    const [file, setFile] = useState(null);
    const [nameFile, setNameFile] = useState(null);
    const [typeovertime, setTypeovertime] = useState(null);

    useEffect(() => {
        init();
    }, [])

    const init = async () => {
        Promise.all([
            getJefesTrabajador(),
            getTipoSobretiempo(),
        ]).then(() => setOpenModalLoader(false));
    }

    const getTipoSobretiempo = async () => {
        await GeneralService.getTipoSobretiempo()
            .then(result => {
                if (result.status === CODES.SUCCESS_200) {
                    result.data?.forEach(element => {
                        element.id = Number(element.corr3)
                        element.value = Number(element.corr3)
                        element.label = element.desc1
                    })
                    setTypeovertime(result.data);
                }
                setOpenModalLoader(false);
            })
            .catch(err => {
                console.log('getMotivoRechazo', err)
            })
    }

    const getJefesTrabajador = async () => {
        try {
            try {
                const result = await OrganizationalService.getJefesTrabajador(url, idPersona);
                if (result.status === CODES.SUCCESS_200) {
                    result?.data?.forEach((element) => {
                        element.id = element.id_jefe;
                        element.value = element.id_jefe;
                        element.label = element.nombreJefe;
                    });
                    let resultTmp = result?.data?.reduce((unique, item) => {
                        if (!unique.some((element) => element.id_jefe === item.id_jefe)) {
                            unique.push(item);
                        }
                        return unique;
                    }, []);
                    setDataSupervisor(resultTmp);
                }
            } catch (error) {
                console.log(`Error en getJefesTrabajador: ${error}`);
            }
            await Promise.all([]);
        } catch (error) {
            console.log(`Error en getJefesTrabajador: ${error}`);
        } finally {
            setOpenModalLoader(false);
        }
    };

    const saveOverTime = async (e) => {
        e.preventDefault();
        let upData = Object.assign({}, data);
        upData.persona_id = idPersona;

        upData.cantMinutos = (Number(hour) * 60 + Number(minute))
        upData.fecha = getDateOL();

        const dataI = new FormData();
        dataI.append("image", file);
        dataI.append("persona_id", idPersona);
        dataI.append("minutos", upData.cantMinutos); //(Number(hour) * 60 + Number(minute)));
        dataI.append("jefe_id", upData.jefe_id);
        dataI.append("motivo", upData.motivo);
        dataI.append("fecha", upData.fecha);
        dataI.append("tipoSobreTiempoTrab", upData.tipoSobreTiempoTrab);
        dataI.append("estado", 'E');

        if (upData.jefe_id === "" || upData.jefe_id === 0 || !upData.jefe_id) {
            Toast.fire({
                icon: 'error',
                title: '!Debe selecionar un jefe!'
            })
            return;
        }
        if (upData.cantMinutos <= 0 || upData.cantMinutos > 180 || !upData.cantMinutos) {
            Toast.fire({
                icon: 'error',
                title: '!El tiempo solicitado debe ser mayor a 1min. y menor a 3 hrs.!'
            })
            return;
        }
        if (upData.fecha === "" || !upData.fecha) {
            Toast.fire({
                icon: 'error',
                title: '!Debe selecionar una fecha!'
            })
            return;
        }
        /*if (upData.tipoSobreTiempoTrab === "" || upData.tipoSobreTiempoTrab === 0 || !upData.tipoSobreTiempoTrab) {
            Toast.fire({
                icon: 'error',
                title: '!Debe selecionar un tipo de sobretiempo!'
            })
            return;
        }*/

        let minutes = minutesDiff(getDateOL, upData.fecha);
        let hours = minutes / 60;
        let days = hours / 24;
        if (days > 10) {
            Toast.fire({
                icon: 'error',
                title: '!Solo se permite un maximo de 10 dias en adelante!'
            })
            return;
        }

        try {
            setOpenModalLoader(true);
            const result = await OverTimeService.saveRequestOverTime(dataI);

            if (result.status === CODES.CREATE_201) {
                Toast.fire({
                    icon: 'success',
                    title: result.data.message
                })
                cleanData();
            } else {
                Toast.fire({
                    icon: 'error',
                    title: result.response.data.message
                })
            }
            return [];
        } catch (error) {
            console.log(`Error en getUsuarios: ${error}`);
        } finally {
            setOpenModalLoader(false);
        }
    }

    const cleanData = () => {
        setData(newOverTime);
        setHour(null);
        setMinute(null);
        setFile(null);
        setNameFile(null);
    }

    return (
        <Box sx={{ flexGrow: 1 }}>
            <Box textAlign={'center'} mt={5}>
                <Typography variant="h3" gutterBottom color={'primary'}>
                    Solicitud de SobreTiempo
                </Typography>
            </Box>
            <form onSubmit={saveOverTime} >
                <Grid container direction="row" justifyContent="center" alignItems="center">
                    <Grid item xs={5} sm={5} md={3} style={{ display: 'flex', justifyContent: 'center' }}>
                        <FormControl variant="filled" sx={{ width: '80%' }}>
                            <InputLabel id="demo-simple-select-filled-label">Supervisores</InputLabel>
                            <Select
                                labelId="demo-simple-select-filled-label"
                                id="demo-simple-select-filled"
                                name="jefe_id"
                                value={data.jefe_id || ''}
                                onChange={(e) => setData({ ...data, jefe_id: e.target.value })}
                                color={'secondary'}
                            >
                                <MenuItem value={""}><em>Seleccionar</em></MenuItem>
                                {dataSupervisor?.map((supervisor, index) => {
                                    return (
                                        <MenuItem key={'supervisor' + index} value={supervisor.id_jefe}>
                                            {supervisor.nombreJefe}
                                        </MenuItem>
                                    )
                                })}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={5} sm={5} md={3} style={{ display: 'flex', justifyContent: 'center' }}>
                        <TextField
                            id="outlined-date"
                            variant="outlined"
                            size="small"
                            label="fecha"
                            style={{ marginTop: 5, width: '80%' }}
                            type='date'
                            name="motivo"
                            value={data?.fecha || ''}
                            onChange={(e) => setData({ ...data, fecha: e.target.value })}
                            required
                        />
                    </Grid>
                </Grid>
                <Grid container direction="row" justifyContent="center" alignItems="center">
                    <Grid item xs={12} sm={11} md={7} p={'0 1rem'} textAlign='center'>
                        <TextField
                            id="outlined-number"
                            placeholder="Escriba 500 caracteres máximo."
                            variant="outlined"
                            size="small"
                            label="Motivo"
                            multiline
                            minRows={8}
                            InputLabelProps={{ shrink: true, }}
                            inputProps={{ maxLength: 500 }}
                            style={{ marginTop: '3%', width: '90%' }}
                            required
                            name="motivo"
                            value={data.motivo || ''}
                            onChange={(e) => setData({ ...data, motivo: e.target.value })}
                        />
                    </Grid>
                </Grid>

                <Grid container direction="row" justifyContent="center" alignItems="center">
                    <Grid item xs={5} sm={3} md={1} textAlign='center'>
                        <Box display={'flex'} justifyContent={'center'} mb={1} mt={1}>
                            <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
                                <AddAlarmIcon sx={{ color: 'action.active', mr: 1.5, my: 0.5 }} />
                                <TextField id="input-with-sx" label="horas" variant="standard"
                                    inputProps={{ type: 'number', min: 0, max: 3 }} sx={{ width: 50 }}
                                    value={hour || ''}
                                    onChange={(e) => setHour(e.target.value)}
                                />
                                &nbsp;
                                <TextField id="input-with-sx" label="minutos" variant="standard"
                                    inputProps={{ type: 'number', min: 0, max: 59 }} sx={{ width: 65 }}
                                    value={minute || ''}
                                    onChange={(e) => setMinute(e.target.value)}
                                />
                            </Box>
                        </Box>
                    </Grid>
                </Grid>

                <Grid container direction="row" justifyContent="center" alignItems="center">
                    <Grid item xs={7} sm={7} md={5} pl={'1rem'} pr={'1rem'} textAlign='center'>
                        <div className='group-upload' style={{ width: '100%' }} >
                            <div className="group-upload__contenido">
                                {nameFile === null ?
                                    <Button size="small" variant="outlined" endIcon={<CloudUploadIcon />} component="label" sx={{ borderStyle: 'dashed' }}>
                                        Evidencia
                                        <input hidden accept="image/*" type="file" onChange={(e) => { setFile(e.target.files[0]); setNameFile(e.target.files[0].name) }} />
                                    </Button> :
                                    <>
                                        <label className="group-upload__contenido--label">{nameFile || 'vacio'}</label>
                                        <IconButton aria-label="delete" size="small" color='quaternary'
                                            onClick={(e) => { setFile(null); setNameFile(null); }}
                                        >
                                            <DeleteIcon fontSize="inherit" />
                                        </IconButton>
                                    </>
                                }
                            </div>
                        </div>
                    </Grid>
                </Grid>

                <Grid container direction="row" justifyContent="center" alignItems="center" mt={2} >
                    <Grid item xs={12} sm={8} md={6} paddingLeft={'1rem'} paddingRight={'1rem'}>
                        <Box display={'flex'} justifyContent={'center'} mb={1}>
                            <button
                                className="main-button"
                                type='submit'
                                style={{
                                    color: 'white',
                                    background: theme.palette.primary.main,
                                    border: `1px solid white`
                                }}
                            >
                                Solicitar
                            </button>
                        </Box>
                    </Grid>
                </Grid>
            </form>
            <SpinnerLoader open={openModalLoader} />
        </Box >
    );
};

export default OverTime;