import React, { useState,useEffect,useMemo} from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Radio, FormControlLabel } from '@mui/material';
import _ from "lodash";
const columns = [
  { id: 'descripcion', label: 'Movimientos del Puesto', minWidth: 220, align: 'left' },
];

const BodyMovimientos = (props) => {
  const { datosMof, handleUpdateDatosMof,opcionVer,mofxUsuarioBody, radioValue } = props;

  // Filtrar las preguntas que pertenecen a la subcategoría "Movimientos"
  const preguntasMovimientos = useMemo(() => datosMof.filter(pregunta => pregunta.subCategoria_id === "6"), [datosMof]);
 
   // Obtener las descripciones de las alternativas para la cabecera
   const alternativasCabecera = preguntasMovimientos.length > 0 ? preguntasMovimientos[0].alternativas.map(alternativa => alternativa.desc1) : [];

   
  // Inicializar el estado seleccionados basado en datosMof
  const initializeSeleccionados = () => {
    const newSeleccionados = {};
    preguntasMovimientos.forEach(pregunta => {
      if (pregunta.detalle_mof && pregunta.detalle_mof.respuesta_id) {
        newSeleccionados[pregunta.id_preguntamof] = pregunta.detalle_mof.respuesta_id;
      }
    });
    return newSeleccionados;
  };

  const [seleccionados, setSeleccionados] = useState(initializeSeleccionados);

  // Recalcular seleccionados si datosMof cambia
  useEffect(() => {
    setSeleccionados(initializeSeleccionados());
  }, [datosMof]);

  // Manejar el cambio en los radios
  const handleChange = (preguntaId, corr3) => {
    setSeleccionados(prevState => ({
      ...prevState,
      [preguntaId]: corr3.toString()
    }));
    handleUpdateDatosMof(preguntaId, corr3.toString(), "respuesta_id");
  };


   return (
     <div className='page-body-main'>
       <div className='page-body__table' style={{ overflow: 'hidden' }}>
         <TableContainer className='table-height'>
           <Table stickyHeader aria-label="sticky table">
             <TableHead>
               <TableRow>
                 <TableCell>Movimientos</TableCell>
                 {alternativasCabecera.map((alternativa, index) => (
                   <TableCell key={index} align="center">{alternativa}</TableCell>
                 ))}
               </TableRow>
             </TableHead>
             <TableBody>
               {preguntasMovimientos.map((pregunta, index) => (
                 <TableRow key={index}>
                   <TableCell>{pregunta.descripcion}</TableCell>
                   {pregunta.alternativas.map((alternativa, index) => (
                     <TableCell key={index} align="center">
                       <FormControlLabel
                         value={alternativa.desc1}
                         control={
                          <Radio
                            color="primary"
                            checked={seleccionados[pregunta.id_preguntamof] == +alternativa.corr3.toString()}
                            onChange={() => handleChange(pregunta.id_preguntamof, alternativa.corr3)}
                            disabled={opcionVer === 5 || +mofxUsuarioBody?.permiso_id === 1 ||  +radioValue === 0}
                        />
                         }
                       />
                     </TableCell>
                   ))}
                 </TableRow>
               ))}
             </TableBody>
           </Table>
         </TableContainer>
       </div>
     </div>
   );
 };

export default BodyMovimientos;