import React from 'react';
import { Select, MenuItem, Grid, Typography, FormControl, InputLabel, TextField, } from '@mui/material';
import PersonService from '../../../services/person';
import { CODES } from '../../../utils/responseCodes';
import { Toast } from '../../../utils/alerts';
import { useTheme } from '@mui/material/styles';
import MainButton from '../general/MainButton';

const Datos = (props) => {
    const { infoPerson, setInfoPerson, setOpenModalLoader } = props;
    const theme = useTheme();

    const onchange = async (e) => {
        let upData = Object.assign({}, infoPerson);
        upData.minutos = e.target.value;
        let body = { 'minutos': e.target.value };

        setInfoPerson(upData)

        await PersonService.updRecordatorioUser(upData.id_usuario, body)
            .then(result => {
                if (result.status === CODES.SUCCESS_200) {
                    Toast.fire({
                        icon: 'success',
                        title: result.data.message
                    })
                }
            })
            .catch(err => {
                console.log('getPersonByUser', err)
            })
    }

    const updPersonalInformation = async () => {
        try {
            setOpenModalLoader(true);
            const result = await PersonService.updPersonalInformation(infoPerson.id_persona, infoPerson)
            if (result.status === CODES.CREATE_201) {
                Toast.fire({
                    icon: 'success',
                    title: result?.data?.message
                })
            } else {
                Toast.fire({
                    icon: 'error',
                    title: result?.response?.data?.message || result?.response?.data?.error || 'Error al actualizar!'
                })
            }
        } catch (err) {
            console.log('getPersonByUser', err)
        } finally {
            setOpenModalLoader(false);
        }
    }

    return (
        <div className='page-body-main'>
            <div className='page-body__table page-body__table--information'>
                <div className='page-body-table' style={{ paddingTop: '2rem' }}>
                    <Grid container pt={0} pr={0} pb={2} pl={2}>
                        <Grid item md={4} xs={12} pb={1}>
                            <Typography variant="h6" gutterBottom color="primary" className='font-information'>Nombre:  </Typography>
                        </Grid>
                        <Grid item md={8}>
                            <Typography variant="h6" gutterBottom color="initial"><small className="text-muted">
                                {(infoPerson?.apePaterno || '') + ' ' +
                                    (infoPerson?.apeMaterno || '') + ', ' +
                                    (infoPerson?.nombres || '')}
                            </small></Typography>
                        </Grid>
                        <Grid item md={4} xs={12} pb={1}>
                            <Typography variant="h6" gutterBottom color="primary" className='font-information'>Telefono:  </Typography>
                        </Grid>
                        <Grid item md={8}>
                            <TextField
                                id="outlined-date"
                                variant="outlined"
                                size="small"
                                type='text'
                                name="telefono"
                                value={infoPerson?.telefono || ''}
                                onChange={(e) => setInfoPerson({ ...infoPerson, telefono: e.target.value })}
                                fullWidth
                                required
                            />
                        </Grid>
                        <Grid item md={4} xs={12} pb={1}>
                            <Typography variant="h6" gutterBottom color="primary" className='font-information'>Correo:  </Typography>
                        </Grid>
                        <Grid item md={8}>
                            <TextField
                                id="outlined-date"
                                variant="outlined"
                                size="small"
                                type='email'
                                name="email"
                                value={infoPerson?.email || ''}
                                onChange={(e) => setInfoPerson({ ...infoPerson, email: e.target.value })}
                                fullWidth
                                required
                            />
                        </Grid>
                        <Grid item md={4} xs={12} pb={1}>
                            <Typography variant="h6" gutterBottom color="primary" className='font-information'>Sede:  </Typography>
                        </Grid>
                        <Grid item md={8}>
                            <Typography variant="h6" gutterBottom color="initial"><small className="text-muted">
                                {(infoPerson?.desEmpresa || '') + ' / ' +
                                    (infoPerson?.desCliente || '') + ' / ' +
                                    (infoPerson?.desUnidad || '') + ' / ' +
                                    (infoPerson?.desSede || '') + ' / ' +
                                    (infoPerson?.desArea || '')}
                            </small></Typography>
                        </Grid>
                        <Grid item md={4} xs={12} pb={1}>
                            <Typography variant="h6" gutterBottom color="primary" className='font-information'>Turno:  </Typography>
                        </Grid>
                        <Grid item md={8}>
                            <Typography variant="h6" gutterBottom color="initial"><small className="text-muted">
                                {infoPerson?.desTurno ?
                                    (infoPerson?.desTurno + ' (' + infoPerson?.ingreso?.substr(0, 5) + ' - ' + infoPerson?.salida?.substr(0, 5) + ')')
                                    : 'Sin turno asignado'
                                }
                            </small></Typography>
                        </Grid>
                        <Grid item md={4} xs={12} pb={1}>
                            <Typography variant="h6" gutterBottom color="primary" className='font-information'>Puesto:  </Typography>
                        </Grid>
                        <Grid item md={8}>
                            <Typography variant="h6" gutterBottom color="initial"><small className="text-muted">
                                {infoPerson?.desPuesto || ''}
                            </small></Typography>
                        </Grid>
                        <Grid item md={12} display={'flex'} justifyContent={'center'} mb={1}>
                            <MainButton
                                onClick={updPersonalInformation}
                                title="Actualizar datos"
                                color="white"
                                background={theme.palette.primary.main}
                            />
                        </Grid>
                        <Grid item md={4} xs={12} pb={1}>
                            <Typography variant="h6" gutterBottom color="primary" className='font-information'>Alerta asistencia:</Typography>
                        </Grid>
                        <Grid item md={8}>
                            <FormControl variant="filled" sx={{ m: 1, width: 200 }}>
                                <InputLabel id="demo-simple-select-filled-label">Recordatorio</InputLabel>
                                <Select
                                    labelId="demo-simple-select-filled-label"
                                    id="demo-simple-select-filled"
                                    value={infoPerson?.minutos || ''}
                                    onChange={onchange}
                                    color={'secondary'}
                                >
                                    <MenuItem value=""><em>Seleccionar</em></MenuItem>
                                    <MenuItem value={60}>60 Min.</MenuItem>
                                    <MenuItem value={50}>50 Min.</MenuItem>
                                    <MenuItem value={40}>40 Min.</MenuItem>
                                    <MenuItem value={30}>30 Min.</MenuItem>
                                    <MenuItem value={20}>20 Min.</MenuItem>
                                    <MenuItem value={10}>10 Min.</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                    </Grid>
                </div>
            </div>
        </div>
    );
};

export default Datos;