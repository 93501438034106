import React from 'react';
import { useTheme } from '@mui/material/styles';
import { TextField, FormControl, InputLabel, Box, Grid, Divider, Modal, Button, Card, CardContent, CardActions, MenuItem, Select } from '@mui/material';
import AddAlarmIcon from '@mui/icons-material/AddAlarm';

const ConsumeTime = (props) => {
    const { open, setOpen, saveConsumeTime, dataSupervisor, data, setData, hour, setHour, minute, setMinute, setHoraInit, horaInit, horaFin, setHoraFin } = props;
    const theme = useTheme();

    const onChangeMinutes = (value, type) => {
        let [hrsInit, minInit] = horaInit.split(':').map(Number);
        if (type === 'H') {
            hrsInit = (hrsInit ? hrsInit : 0) + value;
            minInit = (minInit ? minInit : 0) + minute;
        } else {
            hrsInit = (hrsInit ? hrsInit : 0) + hour;
            minInit = (minInit ? minInit : 0) + value;
        }

        const fecha = new Date(0, 0, 0, hrsInit, minInit);
        //fecha.setMinutes(fecha.getMinutes() + minutos);
        const nuevaHora = fecha.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
        return setHoraFin(nuevaHora);
    }

    const onChangeHrInit = (value) => {
        let [hrsInit, minInit] = value.split(':').map(Number);
        hrsInit = (+hour || 0) + hrsInit;
        minInit = (+minute || 0) + minInit;

        const fecha = new Date(0, 0, 0, hrsInit, minInit);
        //fecha.setMinutes(fecha.getMinutes() + minutos);
        const nuevaHora = fecha.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
        return setHoraFin(nuevaHora);
    }

    return (
        <Modal
            open={open}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box
                sx={{
                    position: 'absolute',
                    top: '40%', //'50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: '40%', //400,
                    maxHeight: '65vh',
                    bgcolor: 'background.paper',
                    borderRadius: '3%',
                    p: 2,
                    overflow: 'scroll'
                }}
            >
                <form onSubmit={saveConsumeTime}>
                    <Card >
                        <CardContent>
                            <Grid container direction="row" justifyContent="center" alignItems="center" mb={1}>
                                <Grid item xs={8} sm={5} md={8} >
                                    <FormControl variant="filled" sx={{ width: '100%' }}>
                                        <InputLabel id="demo-simple-select-filled-label">Supervisores</InputLabel>
                                        <Select
                                            labelId="demo-simple-select-filled-label"
                                            id="demo-simple-select-filled"
                                            name="jefe_id"
                                            value={data.jefe_id || ''}
                                            onChange={(e) => setData({ ...data, jefe_id: e.target.value })}
                                            color={'secondary'}
                                        >
                                            <MenuItem value={""}><em>Seleccionar</em></MenuItem>
                                            {dataSupervisor?.map((supervisor, index) => {
                                                return (
                                                    <MenuItem key={'supervisor' + index} value={supervisor.id_jefe}>
                                                        {supervisor.nombreJefe}
                                                    </MenuItem>
                                                )
                                            })}
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={4} sm={5} md={4} display={'flex'} justifyContent={'center'}>
                                    <TextField
                                        id="outlined-date"
                                        variant="outlined"
                                        size="small"
                                        label="fecha"
                                        style={{ marginTop: 5, width: '90%' }}
                                        type='date'
                                        name="motivo"
                                        value={data?.fecha || ''}
                                        onChange={(e) => setData({ ...data, fecha: e.target.value })}
                                        required
                                    />
                                </Grid>
                            </Grid>
                            <Grid container direction="row" justifyContent="center" alignItems="center">
                                <Grid item xs={12} sm={8} md={12} textAlign='center'>
                                    <TextField
                                        id="outlined-number"
                                        placeholder="Escriba 500 caracteres máximo."
                                        variant="outlined"
                                        label="Motivo"
                                        multiline
                                        minRows={6}
                                        InputLabelProps={{ shrink: true, }}
                                        inputProps={{ maxLength: 500 }}
                                        style={{ marginTop: '5%', width: '100%' }}
                                        required
                                        name="motivo"
                                        value={data.motivo || ''}
                                        onChange={(e) => setData({ ...data, motivo: e.target.value })}
                                    />
                                </Grid>
                            </Grid>
                            <Grid container direction="row" justifyContent="center" alignItems="center" mb={1}>
                                <Grid item xs={8} sm={5} md={4} >
                                    <Box display={'flex'} mb={1} mt={1}>
                                        <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
                                            <AddAlarmIcon sx={{ color: 'action.active', mr: 1.5, my: 0.5 }} />
                                            <TextField id="input-with-sx" label="horas" variant="standard"
                                                inputProps={{ type: 'number', min: 0, max: 8 }} sx={{ width: 50 }}
                                                value={hour || ''}
                                                onChange={(e) => { setHour(e.target.value); onChangeMinutes(e.target.value, 'H'); }}
                                            />
                                            &nbsp;
                                            <TextField id="input-with-sx" label="minutos" variant="standard"
                                                inputProps={{ type: 'number', min: 0, max: 59 }} sx={{ width: 65 }}
                                                value={minute || ''}
                                                onChange={(e) => { setMinute(e.target.value); onChangeMinutes(e.target.value, 'M'); }}
                                            />
                                        </Box>
                                    </Box>
                                </Grid>
                                <Grid item xs={8} sm={5} md={4} >
                                    <Box display={'flex'} justifyContent={'center'} mb={1} mt={1}>
                                        <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
                                            <TextField id="input-with-sx" label="Hora Inicio" variant="standard"
                                                inputProps={{ type: 'time' }} sx={{ width: 80 }}
                                                value={horaInit || '00:00'}
                                                onChange={(e) => { setHoraInit(e.target.value); onChangeHrInit(e.target.value); }}
                                            />
                                            &nbsp;- &nbsp;
                                            <TextField id="input-with-sx" label="Hora Fin" variant="standard"
                                                inputProps={{ type: 'time' }} sx={{ width: 80 }}
                                                value={horaFin || '00:00'}
                                                onChange={(e) => setHoraFin(e.target.value)}
                                            />
                                        </Box>
                                    </Box>
                                </Grid>
                            </Grid>
                        </CardContent>

                        <Divider />

                        <CardActions disableSpacing sx={{ height: '14%', justifyContent: 'center' }}>
                            <Button
                                onClick={() => setOpen(false)}
                                sx={{
                                    background: 'red',
                                    marginRight: '10px',
                                    color: 'white',
                                    border: '1px solid white',
                                }}
                            >
                                Cerrar
                            </Button>

                            <Button
                                type='submit'
                                sx={{
                                    color: 'white',
                                    background: theme.palette.primary.main,
                                    border: `1px solid white`,
                                }}
                            >
                                Guardar
                            </Button>

                        </CardActions>
                    </Card>
                </form>
            </Box>
        </Modal>





    );
};

export default ConsumeTime;