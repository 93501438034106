import React, { } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, ButtonGroup, IconButton } from '@mui/material';
import AddBoxIcon from '@mui/icons-material/AddBox';

const columns = [
  { id: 'id_turno', label: '#', minWidth: 100, align: 'left', },
  { id: 'desTurno', label: 'Descripción', minWidth: 200, align: 'left', },
  { id: 'horario', label: 'Código', minWidth: 120, align: 'left', },
  { id: 'actions', label: 'Acciones', minWidth: 120, align: 'left', },
];

const BodyTurnos = (props) => {
  const { handleOpen, shift, setInfoShift, } = props;

  return (
    <div className='page-body-main'>
      <div className='page-body__table' style={{ overflow: 'hidden' }}>
        <div className='page-body-table'>
          <TableContainer className='table-height'>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  {columns.map((column) => (
                    <TableCell
                      key={column.id}
                      align={column.align}
                      style={{ minWidth: column.minWidth }}
                    >
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {shift?.map((record, indexRow) => {
                  return (
                    <TableRow hover role="checkbox" tabIndex={-1} key={"row" + indexRow}>
                      {columns.map((column, indexColumn) => {
                        const value = record[column.id];
                        return (
                          <TableCell key={'col' + indexColumn} align={column.align}>
                            {column.id === 'actions' ?
                              <ButtonGroup
                                disableElevation
                                variant="contained"
                                aria-label="Disabled elevation buttons"
                              >
                                <IconButton aria-label="delete" size="small" style={{ border: `solid 1px green` }}
                                  onClick={() => { setInfoShift(record); handleOpen(); }}
                                  title='Agregar supervisor'
                                >
                                  <AddBoxIcon color={'success'} fontSize={'12px'} />
                                </IconButton>
                              </ButtonGroup>
                              : column.id === 'horario' ?
                                record.ingreso.substring(0, 5) + ' - ' + record.salida.substring(0, 5)
                                : value}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      </div>
    </div>
  );
}
export default BodyTurnos;