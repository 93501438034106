import React, { useState, useEffect } from 'react';
import { Box, Grid, } from '@mui/material';
import BodyUserPerfil from '../../components/UsuarioPerfil/BodyUserPerfil';
import { CODES } from '../../../utils/responseCodes';
import SpinnerLoader from '../../components/general/SpinnerLoader';
import UsuarioPerfilService from '../../../services/usuarioPerfil';
import PerfilesService from '../../../services/Perfiles';


const UsuarioPerfil = ({ userToken }) => {
    const [openModalLoader, setOpenModalLoader] = useState(true);
    const [records, setRecords] = useState(null);
    const [profiles, setProfiles] = useState(null);

    useEffect(() => {
        init();
    }, [])

    const init = () => {
        //getMarcasAsistenciaRangoByPersona();
        getUserPerfil();
        getPerfiles();
    }


    const getUserPerfil = async () => {
        setOpenModalLoader(true);
        await UsuarioPerfilService.getUserPerfil()
            .then(result => {
                if (result.status === CODES.SUCCESS_200) {
                    let dataArray = result.data;
                    if (Array.isArray(dataArray)) {
                        setRecords(dataArray);
                    } else if (dataArray.hasOwnProperty('data') && Array.isArray(dataArray.data)) {
                        setRecords(dataArray.data);
                    } else {
                        console.log('getUserPerfil: No se pudo acceder a la lista de elementos', dataArray);
                    }
                }
                setOpenModalLoader(false);
            })
            .catch(err => {
                console.log('getUserPerfil', err)
            })
    }

    const getPerfiles = async () => {
        setOpenModalLoader(true);
        await PerfilesService.getPerfiles()
            .then(result => {
                if (result.status === CODES.SUCCESS_200) {
                    let dataArray = result.data;
                    if (Array.isArray(dataArray)) {
                        setProfiles(dataArray);
                    } else if (dataArray.hasOwnProperty('data') && Array.isArray(dataArray.data)) {
                        setProfiles(dataArray.data);
                    } else {
                        console.log('getPerfiles: No se pudo acceder a la lista de elementos', dataArray);
                    }
                }
                setOpenModalLoader(false);
            })
            .catch(err => {
                console.log('getUserPerfil', err)
            })
    }



    return (
        <Box sx={{ flexGrow: 1 }}>
            <Grid container
                direction="row"
                justifyContent="center"
                alignItems="center"
                mt={2}
            >
                <Grid item xs={12} sm={8} md={6} paddingLeft={'1rem'} paddingRight={'1rem'}>
                    <BodyUserPerfil records={records} profiles={profiles} setRecords={setRecords} />
                </Grid>
            </Grid>
            <SpinnerLoader open={openModalLoader} />
        </Box>
    );
};

export default UsuarioPerfil;