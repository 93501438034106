import axios from "axios";
import { Global } from '../utils/general';
import headerRequest from "../utils/headers"

const API_URL = Global.url;

class OverTimeService {
    saveRequestOverTime(body) {
        var ruta = `${API_URL}/over-time`;
        return axios
            .post(ruta, body, {
                headers: headerRequest(),
            })
            .catch((error) => {
                return error;
            });
    }

    getOverTimeByBoss(jefe_id, date1, date2) {
        var ruta = `${API_URL}/over-time-boss/${jefe_id}/${date1}/${date2}`;
        return axios.get(ruta, {
            headers: headerRequest(),
        }).catch((error) => {
            return error;
        });
    }

    getBolsaSobreTiempo(persona_id) {
        var ruta = `${API_URL}/over-time/bag/${persona_id}`;
        return axios.get(ruta, {
            headers: headerRequest(),
        }).catch((error) => {
            return error;
        });
    }

    getSobreTiempoCompensado(persona_id) {
        var ruta = `${API_URL}/over-time/compensated/${persona_id}`;
        return axios.get(ruta, {
            headers: headerRequest(),
        }).catch((error) => {
            return error;
        });
    }

    getSobreTiempoCompensadoBoss(jefe_id, date1, date2) {
        var ruta = `${API_URL}/over-time-boss/compensated/${jefe_id}/${date1}/${date2}`;
        return axios.get(ruta, {
            headers: headerRequest(),
        }).catch((error) => {
            return error;
        });
    }

    saveConsumeTime(body) {
        var ruta = `${API_URL}/over-time/consumetimes`;
        return axios
            .post(ruta, body, {
                headers: headerRequest(),
            })
            .catch((error) => {
                return error;
            });
    }

    saveApproveCompensation(body) {
        var ruta = `${API_URL}/over-time/approve-compensation`;
        return axios.post(ruta, body, {
            headers: headerRequest(),
        }).catch((error) => {
            return error;
        });
    }
}

export default new OverTimeService();
