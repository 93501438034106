import axios from "axios";
import { Global } from '../utils/general';
import headerRequest from "../utils/headers"

const API_URL = Global.url;

class PersonService {
    getPersonByUser(id) {
        let ruta = `${API_URL}/person-user/${id}`;
        return axios.get(ruta, {
            headers: headerRequest(),
        }).catch((error) => {
            return error;
        });
    }

    updRecordatorioUser(id, body) {
        let ruta = `${API_URL}/recall-user/${id}`;
        return axios
            .put(ruta, body, {
                headers: headerRequest(),
            })
            .catch((error) => {
                return error;
            });
    }

    getTimeSummaryWorker(id, date1, date2) {
        let ruta = `${API_URL}/worker/time-summary/${id}/${date1}/${date2}`;
        return axios.get(ruta, {
            headers: headerRequest(),
        }).catch((error) => {
            return error;
        });
    }

    updPersonalInformation(id, body) {
        let ruta = `${API_URL}/person/information/${id}`;
        return axios.put(ruta, body, {
            headers: headerRequest(),
        }).catch((error) => {
            return error;
        });
    }
}

export default new PersonService();
