import React, { useState, useEffect } from 'react';
import { TextField,Box, Grid, } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import MainButton from '../../components/general/MainButton';
import SpinnerLoader from '../../components/general/SpinnerLoader';
import AddTrustedWorker from './AddTrustedWorker/AddTrustedWorker';
import BodyTrustedWorker from '../../components/TrustedWorker/BodyTrustedWorker';
import TrustedWorkerService from '../../../services/trustedWorker';
import AreaOrgService from '../../../services/areaOrg';
import { CODES } from '../../../utils/responseCodes';

const TrustedWorker = ({ userToken }) => {
    const theme = useTheme();
    const url = "'" + window.location.pathname + "'";
    const idUser = userToken?.user.id_usuario;
    const [openModalLoader, setOpenModalLoader] = useState(true);
    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const [dataTrusted, setDataTrusted] = useState(null);
    const [areasOrg, setAreasOrg] = useState(null);
    const [infoAreas, setInfoAreas] = useState(null);

    //*****************************************/
    const [dataStaffs, setDataStaffs] = useState(null);
    const [search, setSearch] = useState(null);
    const [searchTrusted, setSearchTrusted] = useState(null);

    useEffect(() => {
        init();
    }, [])

    const init = async () => {
        Promise.all([
            getTrustedWorker(),
            getAreaOrganigrama(),
        ]).then(() => setOpenModalLoader(false));
    }

    const getAreaOrganigrama = async () => {
        try {
            const result = await AreaOrgService.getAreaOrganigrama();
            if (result.status === CODES.SUCCESS_200) {
                result.data?.forEach(element => {
                    element.id = element.id_areaOrg
                    element.value = element.id_areaOrg
                    element.label = element.desAreaOrg
                })
                setAreasOrg(result.data);
            }
        } catch (error) {
            console.log(`Error en getAreaOrganigrama: ${error}`);
        }
    }

    const getOrganigramaArea = async () => {
        try {
            setOpenModalLoader(true);
            const result = await AreaOrgService.getOrganigramaArea(url, infoAreas);
            if (result.status === CODES.SUCCESS_200) {
                setDataStaffs(result.data);
            }
        } catch (error) {
            console.log(`Error en getOrganigramaArea: ${error}`);
        } finally {
            setOpenModalLoader(false);
        }
    }

    const getTrustedWorker = async () => {
        try {
            setOpenModalLoader(true);
            const result = await TrustedWorkerService.getTrustedWorker();
            if (result.status === CODES.SUCCESS_200) {
                result.data?.forEach((item) => {
                    item.editable = 1;
                });
                setDataTrusted(result.data);
                setOpenModalLoader(false);
            }
        } catch (error) {
            console.log(`Error en getTrustedWorker: ${error}`);
        }
    }

    return (
        <Box sx={{ flexGrow: 1 }}>
            <AddTrustedWorker
                idUser={idUser}
                open={open}
                handleClose={handleClose}
                setOpenModalLoader={setOpenModalLoader}
                dataStaffs={dataStaffs}
                setDataStaffs={setDataStaffs}
                search={search}
                setSearch={setSearch}
                getTrustedWorker={getTrustedWorker}
                infoAreas={infoAreas}
                setInfoAreas={setInfoAreas}
                areasOrg={areasOrg}
                getOrganigramaArea={getOrganigramaArea}
            />
            <Grid container direction="row" justifyContent="center" alignItems="center">
                <Grid item xs={12} sm={10} md={8} paddingLeft={'1rem'} paddingRight={'1rem'}>
                    <Box display={'flex'} justifyContent={'center'} mb={1}>
                        <MainButton
                            onClick={handleOpen}
                            title="Agregar"
                            color="white"
                            background={theme.palette.primary.main}
                        />
                    </Box>
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }} >
                        <TextField
                            id="input-with-sx"
                            variant="standard"
                            label="busqueda de personal"
                            size='small'
                            sx={{ paddingBottom: 1 }}
                            fullWidth
                            value={searchTrusted || ''}
                            onChange={(e) => setSearchTrusted(e.target.value)}
                        />
                    </div>
                    <BodyTrustedWorker
                        setOpenModalLoader={setOpenModalLoader}
                        data={dataTrusted}
                        setData={setDataTrusted}
                        getTrustedWorker={getTrustedWorker}
                        searchTrusted={searchTrusted}
                    />
                </Grid>
            </Grid>

            <SpinnerLoader open={openModalLoader} />
        </Box>
    )
}

export default TrustedWorker