import React, { } from 'react';
import { Box, Modal, ImageListItem, ImageListItemBar, IconButton } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 800,
    p: 1,
    // bgcolor: 'background.paper',
    // border: '2px solid #000',
    // boxShadow: 24,
    // p: 4,
};

const ViewImage = (props) => {
    const { open, setOpen, infoImage } = props;
    const handleClose = () => setOpen(false);

    return (
        <div>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <ImageListItem>
                        <img
                            src={`${infoImage?.rutaImagen}?w=248&fit=crop&auto=format`}
                            srcSet={`${infoImage?.rutaImagen}?w=248&fit=crop&auto=format&dpr=2 2x`}
                            alt={infoImage?.nombres}
                            loading="lazy"
                        />
                        <ImageListItemBar
                            title={infoImage?.nombres.trim() + ' ' + infoImage?.apePaterno.trim() + ' ' + infoImage?.apeMaterno.trim()}
                            subtitle={infoImage?.motivo}
                            actionIcon={
                                <IconButton
                                    sx={{ color: 'rgba(255, 255, 255, 0.54)' }}
                                    aria-label={`info about item.title`}
                                >
                                    <InfoIcon />
                                </IconButton>
                            }
                        />
                    </ImageListItem>
                </Box>
            </Modal>
        </div>
    );
};

export default ViewImage;