import React, { } from 'react';
import { useTheme } from '@mui/material/styles';
import TrustedWorkerService from '../../../services/trustedWorker';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { IconButton } from '@mui/material';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import RemoveIcon from '@mui/icons-material/Remove';
import ModeEditOutlineIcon from '@mui/icons-material/ModeEditOutline';
import CancelIcon from '@mui/icons-material/Cancel';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import SaveAsIcon from '@mui/icons-material/SaveAs';
import { filterData } from '../../../utils/function';
import { CODES } from '../../../utils/responseCodes';
import { Toast } from '../../../utils/alerts';
import _ from 'lodash';

const columns = [
  { id: '#', label: '#', minWidth: 20, },
  { id: 'trabajador', label: 'Trabajador', minWidth: 150, align: 'center', },
  { id: 'confianza', label: 'Confianza', minWidth: 80, align: 'center', type: 'icon' },
  { id: 'noFiscalizado', label: 'No Fiscalizado', minWidth: 80, align: 'center', type: 'icon' },
  { id: 'direccion', label: 'Dirección', minWidth: 80, align: 'center', type: 'icon' },
  { id: 'fiscalizado', label: 'Fiscalizado', minWidth: 80, align: 'center', type: 'icon' },
  { id: 'action', label: 'Edit', minWidth: 100, align: 'center' },
];

const BodyTrustedWorker = (props) => {
  const { setOpenModalLoader, data, setData, getTrustedWorker, searchTrusted } = props;
  const url = "'" + window.location.pathname + "'";
  const theme = useTheme();
  let resultSearch = filterData(data, searchTrusted, ['trabajador', 'trabajador']);

  const handleChange = (info, key, value) => {
    let updData = _.cloneDeep(data);
    updData?.forEach(element => {
      if (+element.id_personaConfianza === +info.id_personaConfianza) {
        element[key] = value;
      }
    });
    return setData(updData);
  };

  const deleteTrustedWorker = async (info) => {
    try {
      setOpenModalLoader(true);
      const result = await TrustedWorkerService.deleteTrustedWorker(url, info.id_personaConfianza);
      if (result.status === CODES.DELETE_204) {
        Toast.fire({
          icon: 'success',
          title: 'Persona retirada'
        })
        getTrustedWorker();
      } else {
        Toast.fire({
          icon: 'error',
          title: result.response.data.message || 'Error no identificado'
        })
        setOpenModalLoader(false);
      }
    } catch (error) {
      console.log(`Error en deleteTrustedWorker: ${error}`);
    }
  };

  const saveTrustedWorker = async (infoTrusted) => {
    try {
      setOpenModalLoader(true);
      const result = await TrustedWorkerService.saveTrustedWorker(url, infoTrusted);
      if (result.status === CODES.CREATE_201) {
        Toast.fire({
          icon: 'success',
          title: result.data.message || 'Guardado correctamente'
        })
        getTrustedWorker();
      } else {
        Toast.fire({
          icon: 'error',
          title: result.response.data.message || 'Error no identificado'
        })
        setOpenModalLoader(false);
      }
    } catch (error) {
      console.log(`Error en saveTrustedWorker: ${error}`);
    }
  };

  return (
    <div className='page-body-main'>
      <div className='page-body__table' style={{ overflow: 'hidden' }} >
        <div className='page-body-table'>
          <TableContainer className='table-height'>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  {columns.map((column) => (
                    <TableCell
                      key={column.id}
                      align={column.align}
                      width={column.minWidth}
                    >
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {resultSearch?.map((row, index) => {
                  return (
                    <TableRow hover role="checkbox" tabIndex={-1} key={"row" + index}>
                      {columns.map((column, indexColumn) => {
                        const value = row[column.id];
                        return (
                          <TableCell key={'col' + indexColumn} align={column.align}>
                            {column.id === '#' ?
                              index + 1
                              : +row.editable === 1 ?
                                (column.id === 'action' ?
                                  <IconButton aria-label="editar" title='Editar' size="small" color='success' style={{ border: 'solid 0.02rem', borderRadius: 0 }}
                                    onClick={() => handleChange(row, 'editable', 0)}
                                  >
                                    <ModeEditOutlineIcon fontSize="inherit" />
                                  </IconButton>
                                  : column.type === 'icon' ?
                                    (+value === 1 ?
                                      <CheckCircleOutlineIcon fontSize="inherit" color='success' />
                                      : <RemoveIcon fontSize="inherit" />)
                                    : value)
                                : (column.id === 'action' ?
                                  <>
                                    <IconButton aria-label="eliminar" title='Eliminar' size="small" style={{ border: 'solid 0.02rem', color: theme.palette.quaternary.main, borderRadius: 0 }}
                                      onClick={() => deleteTrustedWorker(row)}
                                    >
                                      <DeleteForeverIcon fontSize="inherit" />
                                    </IconButton>
                                    &nbsp;
                                    <IconButton aria-label="guardar" title='Guardar' size="small" color='success' style={{ border: 'solid 0.02rem', borderRadius: 0 }}
                                      onClick={() => saveTrustedWorker(row)}
                                    >
                                      <SaveAsIcon fontSize="inherit" />
                                    </IconButton>
                                  </>
                                  : column.type === 'icon' ?
                                    (+value === 1 ?
                                      <IconButton aria-label="retirar" title='Retirar' size="small" style={{ border: 'solid 0.02rem', color: theme.palette.quaternary.main }}
                                        onClick={() => handleChange(row, column.id, 0)}
                                      >
                                        <CancelIcon fontSize="inherit" />
                                      </IconButton>
                                      : <IconButton aria-label="agregar" title='Agregar' size="small" style={{ border: 'solid 0.02rem', color: theme.palette.primary.main }}
                                        onClick={() => handleChange(row, column.id, 1)}
                                      >
                                        <AddCircleIcon fontSize="inherit" />
                                      </IconButton>
                                    )
                                    : value)
                            }
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>

        </div>
      </div>
    </div>
  );
}
export default BodyTrustedWorker;