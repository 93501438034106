import axios from "axios";
import { Global } from '../utils/general';
import headerRequest from "../utils/headers"

const API_URL = Global.url;
const SYS = Global.system;

class StaffService {
    getStaff(url, { search = '', page = 1, pageSize = 20, pagination = '', situacion = '' }) {
        let ruta = `${API_URL}/staff?sys=${SYS}&url=${url}&search=${search}&page=${page}&pageSize=${pageSize}&pagination=${pagination}&situacion=${situacion}`;
        return axios.get(ruta, {
            headers: headerRequest(),
        }).catch((error) => {
            return error;
        });
    }
}



export default new StaffService();
