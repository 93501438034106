import React, { useState, useEffect } from 'react';
import { Box, Grid } from '@mui/material';
import PersonService from '../../../services/person';
import { CODES } from '../../../utils/responseCodes'
import { Toast } from '../../../utils/alerts';
import Datos from '../../components/PersonalData/Datos';
import SpinnerLoader from '../../components/general/SpinnerLoader';

const PersonalData = ({ userToken }) => {
    const [openModalLoader, setOpenModalLoader] = useState(true);
    const [infoPerson, setInfoPerson] = useState(null);
    useEffect(() => {
        init();
    }, [])

    const init = async () => {
        Promise.all([
            getPersonByUser(),
        ]).then(() => setOpenModalLoader(false));
    }
    const getPersonByUser = async () => {
        try {
            const result = await PersonService.getPersonByUser(userToken?.user.id_usuario)
            if (result.status === CODES.SUCCESS_200) {
                setInfoPerson(result.data);
            } else {
                Toast.fire({
                    icon: 'error',
                    title: 'No se encontro información del usuario'
                })
            }
            return []
        } catch (error) {
            console.log(`Error en getPersonByUser: ${error}`);
        }
    }

    return (
        <Box sx={{ flexGrow: 1 }}>

            <Grid container
                direction="row"
                justifyContent="center"
                alignItems="center"
            >
                <Grid item md={5} xs={12} marginTop={2} paddingLeft={'1rem'} paddingRight={'1rem'}>
                    <Datos
                        infoPerson={infoPerson}
                        setInfoPerson={setInfoPerson}
                        setOpenModalLoader={setOpenModalLoader}
                    />
                </Grid>
            </Grid>
            <SpinnerLoader open={openModalLoader} />
        </Box>
    );
};

export default PersonalData;