import React from 'react';
import { useTheme } from '@mui/material/styles';
import { FormControl, InputLabel, Divider, Modal, Card, CardContent, CardActions, MenuItem, Select } from '@mui/material';

const AddMark = (props) => {
    const { open, handleClose, motivesMark, mark, setMark, saveMark } = props;
    const theme = useTheme();

    return (
        <Modal aria-labelledby="parent-modal-title" aria-describedby="parent-modal-description"
            open={open} onClose={handleClose} >
            <form onSubmit={saveMark} >
                <Card className="modal-customizar modal-customizar__xsmall">
                    <CardContent>
                        <FormControl sx={{ m: 1, width: 300 }}>
                            <InputLabel id="demo-simple-select-helper-label">Motivo</InputLabel>
                            <Select
                                labelId="demo-simple-select-helper-label"
                                id="demo-simple-select-helper"
                                value={mark?.id_motivo || ''}
                                label="Motivo"
                                name='id_motivo'
                                onChange={(e) => setMark({ ...mark, id_motivo: e.target.value })}
                            >
                                <MenuItem value={0}><em>Seleccionar</em></MenuItem>
                                {motivesMark?.map((motive, index) => {
                                    return (
                                        <MenuItem key={'motivo' + index} value={motive.id_MotMarca}>{motive.desMarca}</MenuItem>
                                    )
                                })}
                            </Select>
                        </FormControl>
                    </CardContent>

                    <Divider />
                    <CardActions disableSpacing sx={{ height: '14%', justifyContent: 'center' }} >
                        <button
                            className="main-button"
                            type='submit'
                            style={{
                                color: 'white',
                                background: theme.palette.primary.main,
                                border: `1px solid white`
                            }}
                        >
                            Guardar
                        </button>
                    </CardActions>
                </Card>
            </form>
        </Modal>
    );
};

export default AddMark;