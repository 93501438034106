import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter, } from 'react-router-dom';
// bootstrap
import * as bootstrap from 'bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css'

/**fonts */
import './assets/fonts/Montserrat-Black.ttf'
import './assets/fonts/Oswald-VariableFont_wght.ttf'
import './assets/fonts/DS-DIGII.TTF'
import './assets/fonts/DS-DIGIT.TTF'
import './assets/fonts/Letsfly2.ttf'
import './assets/fonts/Noto_Sans/NotoSans-Regular.ttf'

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
