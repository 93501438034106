const getDateOL = function (date = new Date(), type = 'short', moreDays = 0) {
    let dateTmp = date;
    let firstDay = Number(new Date(dateTmp.getFullYear(), dateTmp.getMonth(), 1).getDate());
    let lastDay = Number(new Date(dateTmp.getFullYear(), dateTmp.getMonth() + 1, 0).getDate());

    let newDate;
    let fecha = date,
        hours = fecha.getHours(),
        minutes = fecha.getMinutes(),
        seconds = fecha.getSeconds(),
        day =
            (fecha.getDate() + moreDays) > lastDay ?
                lastDay :
                (fecha.getDate() + moreDays) < firstDay ?
                    firstDay :
                    (fecha.getDate() + moreDays)
        ,
        month = fecha.getMonth() + 1,
        year = fecha.getFullYear();

    if (type.toLowerCase() === 'short') {
        newDate = year + '-' + (month < 10 ? '0' + (month || '0') : month || '') + '-' + (day < 10 ? '0' + (day || '0') : day || '');
    }
    if (type.toLowerCase() === 'large') {
        newDate = (year + '-' + (month < 10 ? '0' + (month || '0') : month || '') + '-' + (day < 10 ? '0' + (day || '0') : day || '') + ' ' + hours + ':' + (minutes < 10 ? '0' + (minutes || '0') : minutes || '') + ':' + (seconds < 10 ? '0' + (seconds || '0') : seconds || ''));
    }
    return newDate;
}

const validateUser = function () {
    let user = localStorage.getItem("infoAssist");
    return user;
}

function filterData(data, searchValue, searchProperties) {
    //return !searchValue ? data : data?.filter((dato) => dato[searchProperty].toLowerCase().includes(searchValue.toLowerCase()));
    return !searchValue ? data : data?.filter((dato) => {
        return searchProperties?.some(prop => {
            return dato[prop]?.toLowerCase().includes(searchValue.toLowerCase());
        });
    });
};

function sortDataListSimple(dataList, sortConfig) {
    dataList?.sort((a, b) => {
        if (a[sortConfig.key] < b[sortConfig.key]) {
            return sortConfig.direction === "ascending" ? -1 : 1;
        }
        if (a[sortConfig.key] > b[sortConfig.key]) {
            return sortConfig.direction === "ascending" ? 1 : -1;
        }
        return 0;
    });

    return dataList
};

const formatDate = function (date = new Date(), type = 'date-time', typeTime = 'short') {
    //let fecha = new Date(date);
    let year = date.getFullYear();
    let month = String(date.getMonth() + 1).padStart(2, '0');
    let day = String(date.getDate()).padStart(2, '0');
    let hour = String(date.getHours()).padStart(2, '0');
    let minute = String(date.getMinutes()).padStart(2, '0');
    let seconds = String(date.getSeconds()).padStart(2, '0');

    let formattedDate;
    if (type === 'date') {
        formattedDate = `${year}-${month}-${day}`;
    } else if (type === 'time') {
        if (typeTime === 'long') {
            formattedDate = `${hour}:${minute}:${seconds}`;
        } else {
            formattedDate = `${hour}:${minute}`;
        }
    } else {
        if (typeTime === 'long') {
            formattedDate = `${year}-${month}-${day} ${hour}:${minute}:${seconds}`;
        } else {
            formattedDate = `${year}-${month}-${day} ${hour}:${minute}`;
        }
    }

    return formattedDate;
};

const minutesDiff = (date1, date2, type = 'date') => {
    let diffEnMin;
    if (type === 'date') {
        let fecha1 = (/^\d{4}-\d{2}-\d{2}$/.test(date1) ? new Date(`${date1}T00:00:00`) : new Date(date1));
        let fecha2 = (/^\d{4}-\d{2}-\d{2}$/.test(date2) ? new Date(`${date2}T00:00:00`) : new Date(date2));

        let diffEnMs = fecha1.getTime() - fecha2.getTime();
        diffEnMin = Math.floor(Math.abs(diffEnMs / 60000));
    } else {
        let fecha1 = new Date();
        let fecha2 = new Date();
        let [horas1, minutos1] = date1.split(':').map(Number);
        let [horas2, minutos2] = date2.split(':').map(Number);

        if (horas2 < horas1) {
            fecha2.setDate(fecha1.getDate() + 1);
        }
        fecha1 = new Date(`${formatDate(fecha1, 'date')}T${date1}`);
        fecha2 = new Date(`${formatDate(fecha2, 'date')}T${date2}`);

        let diffEnMs = fecha2.getTime() - fecha1.getTime();
        diffEnMin = diffEnMs / 60000;
    }
    return diffEnMin;
};

const convertHour = (total) => {
    let hours = parseInt(total / 60);
    let minute = total - (hours * 60);
    let viewHours = '';
    if (hours > 0) {
        viewHours = viewHours + hours + 'H '
    }
    if (minute > 0) {
        viewHours = viewHours + minute + 'm'
    }
    return viewHours;
}

const clearBrowserCache = () => {
    const isiPhone = () => {
        return /iPhone|iPod/.test(navigator.userAgent);
    };
    if (!isiPhone()) {
        window.location.reload(true);
    }
};

const mapData = (data, properties) => {
    return data?.map(element => {
        const mappedElement = { value: element[properties[0]], label: element[properties[1]] };
        properties.forEach(property => {
            mappedElement[property] = element[property];
        });
        return mappedElement;
    });
};

export { getDateOL, validateUser, filterData, formatDate, minutesDiff, convertHour, sortDataListSimple, clearBrowserCache, mapData };