import React, { useState, useEffect } from 'react';
import { Box, Grid, } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { CODES } from '../../../utils/responseCodes';
import { Toast } from '../../../utils/alerts';
import Clock from '../../components/general/Clock';
import MainButton from '../../components/general/MainButton';
import BodyAssistance from '../../components/Home/BodyAssistance';
import AssistService from '../../../services/assist';
import GeneralService from '../../../services/general';
import AddMark from './AddMark/AddMark';
import { getDateOL, minutesDiff } from '../../../utils/function';
import SpinnerLoader from '../../components/general/SpinnerLoader';
import Swal from "sweetalert2";

const newMark = {
    fecha: "",
    id_usuario: null,
    id_persona: null,
    id_motivo: null
}

const Home = ({ userToken }) => {
    const theme = useTheme();
    const [openModalLoader, setOpenModalLoader] = useState(true);
    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const [mark, setMark] = useState(newMark);
    const [marks, setMarks] = useState(null);
    const [motivesMark, setMotivesMark] = useState(null);
    const idUser = userToken?.user.id_usuario;
    const idPersona = userToken?.user.persona_id;

    useEffect(() => {
        init();
    }, [])
    const init = async () => {
        getMotivosMarcas();
        getMarcasAsistencia();
    }
    const getMarcasAsistencia = async () => {
        setOpenModalLoader(true);
        await AssistService.getMarcasAsistencia(idPersona, getDateOL(new Date(), 'short'))
            .then(result => {
                if (result.status === CODES.SUCCESS_200) {
                    setMarks(result.data);
                }
                setOpenModalLoader(false);
            })
            .catch(err => {
                console.log('getMarcasAsistencia', err)
            })
    }
    const getMotivosMarcas = async () => {
        await GeneralService.getMotivosMarcas()
            .then(result => {
                if (result.status === CODES.SUCCESS_200) {
                    setMotivesMark(result.data);
                }
            })
    }
    const saveMark = async (e) => {
        e.preventDefault();
        let updMark = Object.assign({}, mark);
        updMark.id_usuario = idUser;
        updMark.id_persona = idPersona;
        let fecha = new Date();
        updMark.fecha = getDateOL(fecha, 'large');

        if (updMark.fecha === ""
            || updMark.id_usuario === 0 || updMark.id_usuario === null
            || updMark.id_persona === 0 || updMark.id_persona === null
            || updMark.id_motivo === 0 || updMark.id_motivo === null
        ) {
            Toast.fire({
                icon: 'error',
                title: '!Faltan datos!'
            })
        } else {
            await AssistService.saveMarcasAsistencia(updMark)
                .then(result => {
                    if (result.status === CODES.CREATE_201) {
                        Toast.fire({
                            icon: 'success',
                            title: result.data.msg
                        })
                        getMarcasAsistencia();
                        cleanData();
                    } else {
                        Toast.fire({
                            icon: 'error',
                            title: result.response.data.msg || 'Error importante comuniquese con TI'
                        })
                    }
                })
                .catch(err => {
                    console.log('saveMarcasAsistencia', err)
                })
            handleClose();
        }
    }
    const saveMark_new = async (motivo) => {
        if (+motivo === 2) {
            const markIn = marks?.find(e => +e.motMarca_id == 1);
            const dateIn = markIn.fechaHora
            const minutes = minutesDiff(dateIn, getDateOL(new Date(), 'large'))
            if (minutes < 10) {
                Toast.fire({
                    icon: 'warning',
                    title: '!Espere unos minutos para marcar su salida!'
                })
                return;
            }
            Swal.fire({
                title: "¿Seguro de marcar salida?",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#218838",
                cancelButtonColor: "#dc3545",
                confirmButtonText: "Si, marcar",
            }).then((result) => {
                if (result.isConfirmed) {
                    saveAssitance(motivo)
                }
            });
        } else {
            saveAssitance(motivo);
        }
    }
    const saveAssitance = async (motivo) => {
        let updMark = Object.assign({}, mark);
        updMark.id_usuario = idUser;
        updMark.id_persona = idPersona;
        let fecha = new Date();
        updMark.fecha = getDateOL(fecha, 'large');
        updMark.id_motivo = motivo;
        if (updMark.fecha === ""
            || updMark.id_usuario === 0 || updMark.id_usuario === null
            || updMark.id_persona === 0 || updMark.id_persona === null
            || updMark.id_motivo === 0 || updMark.id_motivo === null
        ) {
            Toast.fire({
                icon: 'error',
                title: '!Faltan datos!'
            })
        } else {
            try {
                setOpenModalLoader(true);
                const result = await AssistService.saveMarcasAsistencia(updMark)
                if (result.status === CODES.CREATE_201) {
                    Toast.fire({
                        icon: 'success',
                        title: result.data.msg
                    })
                    getMarcasAsistencia();
                    cleanData();
                } else {
                    setOpenModalLoader(false)
                    Toast.fire({
                        icon: 'error',
                        title: result.response.data.msg || 'Error importante comuniquese con TI'
                    })
                }
            } catch (error) {
                console.log(`Error en saveMarcasAsistencia: ${error}`);
            }
        }
    }
    const cleanData = () => {
        setMark(newMark);
    }
    return (
        <Box sx={{ flexGrow: 1 }}>
            <AddMark
                open={open}
                handleClose={handleClose}
                motivesMark={motivesMark}
                mark={mark}
                setMark={setMark}
                saveMark={saveMark}
            />
            <Clock />
            <Grid container
                direction="row"
                justifyContent="center"
                alignItems="center"
            >
                <Grid item xs={12} sm={8} md={6} paddingLeft={'1rem'} paddingRight={'1rem'}>
                    <Box display={'flex'} justifyContent={'center'} mb={1}>
                        {(marks?.find(e => +e.motMarca_id === 1) && marks?.find(e => +e.motMarca_id === 2)) ?
                            null
                            : marks?.find(e => +e.motMarca_id === 1) ?
                                <MainButton
                                    onClick={() => saveMark_new(2)}
                                    title="Salida"
                                    color="white"
                                    background={theme.palette.tertiary.main}
                                />
                                : <MainButton
                                    onClick={() => saveMark_new(1)}
                                    title="Entrada"
                                    color="white"
                                    background={theme.palette.secondary.main}
                                />
                        }
                    </Box>
                    <BodyAssistance marks={marks} />
                </Grid>
            </Grid>
            <SpinnerLoader open={openModalLoader} />
        </Box>
    );
};

export default Home;