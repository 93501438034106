import axios from "axios";
import { Global } from '../utils/general';
import headerRequest from "../utils/headers"

const API_URL = Global.url;

class GeneralService {
    getEmpresas() {
        let ruta = `${API_URL}/general/companies`;
        return axios
            .get(ruta, {
                headers: headerRequest(),
            })
            .catch((error) => {
                return error;
            });
    }

    getClientesByEmpresa(empresa_id) {
        let ruta = `${API_URL}/general/client/${empresa_id}`;
        return axios
            .get(ruta, {
                headers: headerRequest(),
            })
            .catch((error) => {
                return error;
            });
    }

    getUnidadesByCliente(cliente_id) {
        let ruta = `${API_URL}/general/units/${cliente_id}`;
        return axios
            .get(ruta, {
                headers: headerRequest(),
            })
            .catch((error) => {
                return error;
            });
    }

    getSedesByUnidad(unidad_id) {
        let ruta = `${API_URL}/general/campus/${unidad_id}`;
        return axios
            .get(ruta, {
                headers: headerRequest(),
            })
            .catch((error) => {
                return error;
            });
    }

    getAreasBySede(sede_id) {
        let ruta = `${API_URL}/general/areas/${sede_id}`;
        return axios
            .get(ruta, {
                headers: headerRequest(),
            })
            .catch((error) => {
                return error;
            });
    }

    getMotivosMarcas() {
        let ruta = `${API_URL}/general/motives-mark`;
        return axios
            .get(ruta, {
                headers: headerRequest(),
            })
            .catch((error) => {
                return error;
            });
    }

    getMarcaEstado() {
        let ruta = `${API_URL}/general/status-mark`;
        return axios
            .get(ruta, {
                headers: headerRequest(),
            })
            .catch((error) => {
                return error;
            });
    }

    getMotivoRechazo() {
        let ruta = `${API_URL}/general/motives-reject`;
        return axios
            .get(ruta, {
                headers: headerRequest(),
            })
            .catch((error) => {
                return error;
            });
    }

    getTipoSobretiempo() {
        let ruta = `${API_URL}/general/overtime-type`;
        return axios.get(ruta, {
            headers: headerRequest(),
        }).catch((error) => {
            return error;
        });
    }

    /* Servicios */
    getCentroCosto() {
        let ruta = `${API_URL}/general/cost-center`;
        return axios
            .get(ruta, {
                headers: headerRequest(),
            })
            .catch((error) => {
                return error;
            });
    }

    getTipoComprobante() {
        let ruta = `${API_URL}/general/voucher-type`;
        return axios
            .get(ruta, {
                headers: headerRequest(),
            })
            .catch((error) => {
                return error;
            });
    }

    getCondicionPago() {
        let ruta = `${API_URL}/general/payment-condition`;
        return axios
            .get(ruta, {
                headers: headerRequest(),
            })
            .catch((error) => {
                return error;
            });
    }

    getMoneda() {
        let ruta = `${API_URL}/general/moneda`;
        return axios
            .get(ruta, {
                headers: headerRequest(),
            })
            .catch((error) => {
                return error;
            });
    }

    getEstadoServ() {
        let ruta = `${API_URL}/general/status-services`;
        return axios
            .get(ruta, {
                headers: headerRequest(),
            })
            .catch((error) => {
                return error;
            });
    }
}

export default new GeneralService();
