import React, { useState, useEffect } from "react";
import {
  Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Checkbox,
} from "@mui/material";

const columns = [
  { id: "sel", label: "", width: 10, align: "left" },
  { id: "id_mof", label: "Id.", width: 100, align: "left" },
  { id: "codMof", label: "MOF", width: 200, align: "left" },
];


const AssignMofsBody = (props) => {
  const { mofClientePuesto, selectedRows, setSelectedRows, mofxUsuario,getDatosMofArray } = props;
  const [selectedIds, setSelectedIds] = useState([]);

  useEffect(() => {
    if (mofClientePuesto && mofxUsuario) {
      const selected = mofClientePuesto.filter((row) =>
        mofxUsuario.some((item) => +item.mof_id === row.id_mof)
      );
      setSelectedRows(selected);
    }
  }, [mofClientePuesto, mofxUsuario]);

  useEffect(() => {
    const ids = selectedRows.map(row => row.id_mof);
    setSelectedIds(ids);
    getDatosMofArray(ids); // Llama a getDatosMofArray con todos los IDs seleccionados
  }, [selectedRows]);

  /*const handleCheckboxChange = (event, row) => {
    if (row) {
      const selectedIndex = selectedRows.findIndex(
        (selectedRow) => selectedRow.id_mof === row.id_mof
      );
      
      if (selectedIndex === -1) {
        setSelectedRows([...selectedRows, row]);
      } else {
        const updatedRows = [...selectedRows];
        updatedRows.splice(selectedIndex, 1);
        setSelectedRows(updatedRows);
      }
    }
  };
  

  const handleHeaderCheckboxChange = (event) => {
    if (event.target.checked) {
      setSelectedRows([...mofClientePuesto]);
    } else {
      setSelectedRows([]);
    }
  };
  
  */

  const handleCheckboxChange = (event, row) => {
    if (row) {
      const selectedIndex = selectedRows.findIndex(
        (selectedRow) => selectedRow.id_mof === row.id_mof
      );
      let newSelectedRows;
      let newSelectedIds;
  
      if (selectedIndex === -1) {
        newSelectedRows = [...selectedRows, row];
        newSelectedIds = [...selectedIds, row.id_mof];
      } else {
        newSelectedRows = selectedRows.filter(
          (selectedRow) => selectedRow.id_mof !== row.id_mof
        );
        newSelectedIds = selectedIds.filter((id) => id !== row.id_mof);
      }
  
      setSelectedRows(newSelectedRows);
      setSelectedIds(newSelectedIds);
      getDatosMofArray(newSelectedIds); // Llama a getDatosMofArray con todos los IDs seleccionados
    }
  };

  const handleHeaderCheckboxChange = (event) => {
    if (event.target.checked) {
      const ids = mofClientePuesto.map(row => row.id_mof);
      setSelectedRows([...mofClientePuesto]);
      setSelectedIds(ids);
      getDatosMofArray(ids); // Llama a getDatosMofArray con todos los IDs seleccionados
    } else {
      setSelectedRows([]);
      setSelectedIds([]);
      getDatosMofArray([]); // Llama a getDatosMofArray con ningún ID seleccionado
    }
  };

  

  return (
    <div className="page-body-main">
      <div className="page-body__table" style={{ overflow: "hidden" }}>
        <div className="page-body-table">
          <TableContainer className="table-height">
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  {columns.map((column) => (
                    <TableCell
                      key={column.id}
                      align={column.align}
                      style={{ minWidth: column.minWidth }}
                    >
                      {column.id === "sel" ? (
                        <Checkbox
                          checked={selectedRows?.length === mofClientePuesto?.length}
                          onChange={handleHeaderCheckboxChange}
                        />
                      ) : (
                        column.label
                      )}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {mofClientePuesto &&
                  mofClientePuesto.map((row) => (
                    <TableRow key={row.id_mof}>
                      {columns.map((column) => {
                        const value = row[column.id];
                        return (
                          <TableCell key={column.id} align={column.align}>
                            {column.id === "sel" ? (
                              <Checkbox
                                checked={selectedRows.some(
                                  (selectedRow) => selectedRow.id_mof === row.id_mof
                                )}
                                onChange={(event) => {
                                  handleCheckboxChange(event, row);
                                 // getDatosMof(row.id_mof); 
                                }}
                              />
                            ) : (
                              value
                            )}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      </div>
    </div>
  );
};

export default AssignMofsBody;
