import React, { useState, useEffect } from 'react';
import { Box, Grid, Typography, } from '@mui/material';
import OverTimeService from '../../../services/overTime';
import { CODES } from '../../../utils/responseCodes';
import SpinnerLoader from '../../components/general/SpinnerLoader';
import { Toast } from '../../../utils/alerts';
import MainButton from '../../components/general/MainButton';
import { useTheme } from '@mui/material/styles';
import { getDateOL, minutesDiff } from '../../../utils/function';
import OrganizationalService from '../../../services/organizational';
import BodyEquipment from '../../components/Maintenance/BodyEquipment';
import AddEquipment from './AddEquipment/AddEquipment';

const newConsumeTime = {
    persona_id: null,
    jefe_id: null,
    motivo: "",
    cantMinutos: null,
    fecha: getDateOL()
}

const Equipment = ({ userToken }) => {
    const theme = useTheme();
    const url = "'" + window.location.pathname + "'";
    const idPersona = userToken?.user?.persona_id;
    const idUsuario = userToken?.user?.id_usuario;
    const [openModalLoader, setOpenModalLoader] = useState(false);
    const [infoTime, setInfoTime] = useState(null);
    const [dataTimeCompesated, setDataTimeCompesated] = useState(null);
    const [dataSupervisor, setDataSupervisor] = useState(null);

    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const [open, setOpen] = useState(false);

    const [data, setData] = useState(newConsumeTime);
    const [hour, setHour] = useState(null);
    const [minute, setMinute] = useState(null);
    const [horaInit, setHoraInit] = useState('00:00');
    const [horaFin, setHoraFin] = useState('00:00');

    useEffect(() => {
        init();
    }, [])

    const init = () => {
        Promise.all([
            getBolsaSobreTiempo(),
            getSobreTiempoCompensado(),
            getJefesTrabajador()
        ]).then(() => setOpenModalLoader(false));
    }

    const getBolsaSobreTiempo = async () => {
        setOpenModalLoader(true);
        try {
            const result = await OverTimeService.getBolsaSobreTiempo(idPersona);
            if (result.status === CODES.SUCCESS_200) {
                setInfoTime(result.data[0]);
            } else {
                Toast.fire({
                    icon: 'error',
                    title: result?.response?.data?.message || 'No hay tiempo acumulado'
                })
            }
        } catch (error) {
            console.log(`Error en getBolsaSobreTiempo: ${error}`);
        }
    }

    const getSobreTiempoCompensado = async () => {
        setOpenModalLoader(true);
        try {
            const result = await OverTimeService.getSobreTiempoCompensado(idPersona);
            if (result.status === CODES.SUCCESS_200) {
                setDataTimeCompesated(result.data);
            }
        } catch (error) {
            console.log(`Error en getBolsaSobreTiempo: ${error}`);
        }
    }

    const saveConsumeTime = async (e) => {
        e.preventDefault();
        let upData = Object.assign({}, data);
        upData.persona_id = idPersona;

        upData.minutos = (Number(hour) * 60 + Number(minute));
        upData.estado = "E";
        upData.cantMinutos = (Number(hour) * 60 + Number(minute));
        upData.minutosSobrantes = infoTime?.tiempo - upData.cantMinutos;
        upData.fecha = data?.fecha;
        upData.fechaRegistro = getDateOL();
        upData.horaInicio = horaInit;
        upData.horaFin = horaFin;
        upData.usuario_id = idUsuario;
        if (upData.horaInicio > upData.horaFin || upData.horaInicio == upData.horaFin) {
            Toast.fire({
                icon: 'error',
                title: '!Rango de horas incorrecto!'
            })
            return;
        }
        if (upData.jefe_id === "" || upData.jefe_id === 0 || !upData.jefe_id) {
            Toast.fire({
                icon: 'error',
                title: '!Debe selecionar un jefe!'
            })
            return;
        }
        if (upData.cantMinutos > 480 || upData.cantMinutos > infoTime?.tiempo || !upData.cantMinutos) {
            Toast.fire({
                icon: 'error',
                title: '!El tiempo consumido no puede superar las 8 horas o  exceder tus horas de sobretiempo'
            })
            return;
        }
        if (upData.fecha === "" || !upData.fecha) {
            Toast.fire({
                icon: 'error',
                title: '!Debe selecionar una fecha!'
            })
            return;
        }

        let minutes = minutesDiff(getDateOL, upData.fecha);
        let hours = minutes / 60;
        let days = hours / 24;
        if (days > 10) {
            Toast.fire({
                icon: 'error',
                title: '!Solo se permite un maximo de 10 dias en adelante!'
            })
            return;
        }

        try {
            setOpenModalLoader(true);
            const result = await OverTimeService.saveConsumeTime(upData);
            if (result.status === CODES.CREATE_201) {
                Toast.fire({
                    icon: 'success',
                    title: result.data.message
                })
                cleanData();
                setOpen(false);
                getSobreTiempoCompensado();
            } else {
                Toast.fire({
                    icon: 'error',
                    title: result.response.data.message
                })
            }
            return [];
        } catch (error) {
            console.log(`Error en getUsuarios: ${error}`);
        } finally {
            setOpenModalLoader(false);
        }
    }

    const getJefesTrabajador = async () => {
        try {
            try {
                const result = await OrganizationalService.getJefesTrabajador(url, idPersona);
                if (result.status === CODES.SUCCESS_200) {
                    result?.data?.forEach((element) => {
                        element.id = element.id_jefe;
                        element.value = element.id_jefe;
                        element.label = element.nombreJefe;
                    });
                    let resultTmp = result?.data?.reduce((unique, item) => {
                        if (!unique.some((element) => element.id_jefe === item.id_jefe)) {
                            unique.push(item);
                        }
                        return unique;
                    }, []);
                    setDataSupervisor(resultTmp);
                }
            } catch (error) {
                console.log(`Error en getJefesTrabajador: ${error}`);
            }
            await Promise.all([]);
        } catch (error) {
            console.log(`Error en getJefesTrabajador: ${error}`);
        } finally {
            setOpenModalLoader(false);
        }
    };
    
    const cleanData = () => {
        setData(newConsumeTime);
        setHour(null);
        setMinute(null);
    }

    return (
        <Box sx={{ flexGrow: 1 }}>

            <AddEquipment
                open={open}
               setOpen={setOpen}
            />
             <Box textAlign={'center'} mt={5}>
                <Typography variant="h4" gutterBottom color={'primary'}>
                    Mantenimiento equipo
                </Typography>
            </Box>
            <Grid container direction="row" justifyContent="center" alignItems="center" marginTop={'4rem'}>
                <Grid item xs={6} sm={2} md={2} display={'flex'} justifyContent={'center'}>
                    <Box display={'flex'} justifyContent={'center'} mb={1}>
                        <MainButton
                            onClick={handleOpen}
                            title="Agregar equipo"
                            color="white"
                            background={theme.palette.primary.main}
                        />
                    </Box>
                </Grid>
            </Grid>

            <Grid container direction="row" justifyContent="center" alignItems="center" mt={2}>
                <Grid item xs={12} sm={10} md={6} paddingLeft={'1rem'} paddingRight={'1rem'}>
                    <BodyEquipment
                        dataTimeCompesated={dataTimeCompesated}
                    />
                </Grid>
            </Grid>

            <SpinnerLoader open={openModalLoader} />
        </Box>
    );
};

export default Equipment;