import React, { useState } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, ButtonGroup, IconButton } from '@mui/material';
import ThumbUpAltIcon from '@mui/icons-material/ThumbUpAlt';
import ThumbDownAltIcon from '@mui/icons-material/ThumbDownAlt';
import HistoryToggleOffIcon from '@mui/icons-material/HistoryToggleOff';
import OverTimeService from '../../../services/overTime';
import { convertHour, filterData, sortDataListSimple } from '../../../utils/function';
import { Toast } from '../../../utils/alerts';
import { CODES } from '../../../utils/responseCodes';
import { OrderedListOutlined, } from '@ant-design/icons/lib/icons';
import _ from 'lodash';

const columns = [
  { id: 'id_fechaCom', label: '#', width: 50, align: 'left', },
  { id: 'time', label: 'Tiempo', width: 50, align: 'left', },
  { id: 'trabajador', label: 'Trabajador', width: 120, align: 'left', },
  { id: 'fecha', label: 'Fecha', width: 80, align: 'left', },
  { id: 'tiempo', label: 'Tiempo', width: 50, align: 'center', format: (value) => convertHour(value) },
  { id: 'motivo', label: 'Motivo', width: 150, align: 'left', },
  { id: 'estado', label: 'Estado', width: 80, align: 'center', },
  { id: 'range', label: 'Rango de tiempo', width: 100, align: 'center', },
  { id: 'actions', label: 'Acciones', width: 100, align: 'center', },
];

const BodyTimeCompensatedCheck = (props) => {
  const { searchComp, setOpenModalLoader, dataTimeCompesated, setDataTimeCompesated, viewSummary } = props;

  const approveCompensation = async (info, key, value) => {
    let updData = _.cloneDeep(dataTimeCompesated);
    let body;
    updData?.forEach((e) => {
      if (e.id_fechaCom === info.id_fechaCom) {
        e[key] = value;
        body = e;
      }
    })

    try {
      setOpenModalLoader(true);
      const result = await OverTimeService.saveApproveCompensation(body);
      if (result.status === CODES.SUCCESS_200) {
        Toast.fire({
          icon: (+result?.data?.status === 1 ? 'success' : 'error'),
          title: result?.data?.message
        })
        return setDataTimeCompesated(updData);
      } else {
        Toast.fire({
          icon: 'error',
          title: result?.response?.data?.message || 'Error durante la aprobación'
        })
      }
      return [];
    } catch (error) {
      console.log(`Error en saveApproveCompensation: ${error}`);
    } finally {
      setOpenModalLoader(false);
    }
  }
  // /** inicio ordenamiento de columnas */
  const [sortConfigTable, setSortConfigTable] = useState({ key: null, direction: "ascending" });

  const handleSortTable = (key) => {
    let direction = "ascending";
    if (sortConfigTable && sortConfigTable.key === key && sortConfigTable.direction === "ascending") {
      direction = "descending";
    }
    setSortConfigTable({ key, direction });
  };

  let resultSearch = filterData(dataTimeCompesated, searchComp, ['trabajador', 'motivo']);

  const sortedDataTimeCompesated = sortDataListSimple(resultSearch, sortConfigTable);
  // /** fin ordenamiento de columnas */

  return (
    <div className='page-body-main'>
      <div className='page-body__table page-body__table--overtime' style={{ overflow: 'hidden' }}>
        <div className='page-body-table'>
          <TableContainer className='table-height table-height--overtime'>
            {/* <Table aria-label="sticky table"> */}
            <Table size='small' stickyHeader aria-label="sticky table">
              <TableHead>
                {/* <TableHead className='sticky-top' > */}
                <TableRow>
                  {columns.map((column) => (
                    <TableCell
                      key={column.id}
                      align={column.align}
                      style={{ width: column.width, maxWidth: column.width }}
                    // onClick={() => handleSortTable(column.id)}
                    >
                      {/* <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <div style={{ marginRight: 'auto' }}>{column.label}</div>
                        <div><OrderedListOutlined style={{ paddingBottom: 2 }} /></div>
                      </div> */}
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody className="table-group-divider">
                {sortedDataTimeCompesated?.map((row, idxRow) => {
                  return (
                    <TableRow hover role="checkbox" tabIndex={-1} key={"row" + idxRow}>
                      {columns?.map((column, indexColumn) => {
                        const value = row[column.id];
                        return (
                          <TableCell key={'col' + indexColumn} align={column.align}>
                            {(column.id === 'actions' && row.estado === 'E') ?
                              <ButtonGroup disableElevation variant="contained" aria-label="Disabled elevation buttons" >
                                <IconButton aria-label="delete" size="small"
                                  onClick={() => approveCompensation(row, 'estado', 'A')}
                                >
                                  <ThumbUpAltIcon fontSize='medium' color={'success'} />
                                </IconButton>
                                <IconButton aria-label="delete" size="small"
                                  onClick={() => approveCompensation(row, 'estado', 'R')}
                                >
                                  <ThumbDownAltIcon fontSize='medium' color={'error'} />
                                </IconButton>
                              </ButtonGroup>
                              : column.id === 'time' ?
                                <ButtonGroup disableElevation variant="contained" aria-label="Disabled elevation buttons" >
                                  <IconButton aria-label="delete" size="small"
                                    onClick={() => viewSummary(row.persona_id)}
                                  >
                                    <HistoryToggleOffIcon fontSize='medium' color={'success'} />
                                  </IconButton>
                                </ButtonGroup>
                                : column.format ?
                                  column.format(+value)
                                  : value}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      </div>
    </div>
  );
}
export default BodyTimeCompensatedCheck;