import React, { useEffect, useState } from "react";
import { Route, Routes, Navigate } from "react-router-dom";

import "./App.css";
import "./assets/main.scss";

import { useNavigate } from "react-router-dom";
import { ThemeProvider } from "@mui/material/styles";
import theme from "./assets/themeConfig";

import MenuService from "./services/menu";
import { validateUser } from "./utils/function";
import { CODES } from "./utils/responseCodes";
import { Box } from "@mui/material";
import Header from "./app/components/Header";
import Login from "./app/pages/Login/Login";
import Home from "./app/pages/Home/Home";
import PersonalData from "./app/pages/PersonalData/PersonalData";

import Register from "./app/pages/Register/Register";
import RegisterBoss from "./app/pages/Register/RegisterBoss";
import Supervisors from "./app/pages/Supervisors/Supervisors";
import { Toast } from "./utils/alerts";
import OverTime from "./app/pages/OverTime/OverTime";
import CheckOverTime from "./app/pages/OverTime/CheckOverTime";
import ViewTime from "./app/pages/OverTime/ViewTime";
import UsuarioPerfil from "./app/pages/UsuarioPerfil/UsuarioPerfil";
import ExecuteBat from "./app/pages/ExecuteBat/ExecuteBat";
import ExecuteBatRotation from "./app/pages/ExecuteBat/ExecuteBatRotation";
import TrustedWorker from "./app/pages/TrustedWorker/TrustedWorker";
import BillingEdit from "./app/pages/BillingForm/BillingEdit";
import MofV2 from "./app/pages/MofV2/Mof";
import Requests from "./app/pages/Requests/Requests";
import CheckRequests from "./app/pages/Requests/CheckRequests";
import Equipment from "./app/pages/Maintenance/Equipment";
import { Global } from "./utils/general";

function App() {
  const [userToken, setUserToken] = useState(null);
 // console.log("🚀 ~ App ~ userToken:", userToken.user.id_usuario)
  const [userPlatform, setUserPlatform] = useState(false);
  const [menu, setMenu] = useState(null);
  const navigate = useNavigate();
  const pathInit = Global.pathInit;

  const validateWeb = () => {
    //let navegador = navigator.userAgent;
    if (
      navigator.userAgent.match(/Android/i) ||
      navigator.userAgent.match(/webOS/i) ||
      navigator.userAgent.match(/iPhone/i) ||
      navigator.userAgent.match(/iPad/i) ||
      navigator.userAgent.match(/iPod/i) ||
      navigator.userAgent.match(/BlackBerry/i) ||
      navigator.userAgent.match(/Windows Phone/i)
    ) {
      setUserPlatform(true);
    }
  };

  useEffect(() => {
    validateWeb();
  }, []);

  useEffect(() => {
    if (!userToken) {
      setUserToken(JSON.parse(validateUser()));
    } else {
      MenuService.getMenu("LA").then((result) => {
        if (result?.status === CODES.SUCCESS_200) {
          if (result?.data?.length === 0) {
            Toast.fire({
              icon: "info",
              title: "Lo sentimos el perfil asignado no tiene ningun permiso!",
            });
          }
          result?.data?.forEach((element) => {
            element.label = element.desPagina;
            element.key = element.link;
          });
          setMenu(result?.data);
        } else if (
          result?.response?.status === CODES.BAD_TOKEN_498 ||
          result?.message === "Network Error"
        ) {
          Toast.fire({
            icon: "error",
            title: result?.response?.data?.error || "Sesión expirada",
          });
          localStorage.removeItem("infoAssist");
          setUserToken(null);
          navigate(`${pathInit || '/'}`);
        }
      });
    }
  }, [userToken]);

  return (
    <ThemeProvider theme={theme}>
      {userToken ? (
        <Box sx={{ marginTop: "5.3rem", overflow: "auto" }}>
          <Header userToken={userToken} menu={menu} pathInit={pathInit} />
          <BodyWeb userToken={userToken} pathInit={pathInit} />
        </Box>
      ) : (
        <Login setUserToken={setUserToken} />
      )}
    </ThemeProvider>
  );
}

function BodyWeb({ userToken, pathInit }) {
  let userPerfil = userToken?.user?.perfil?.codPerfil;
  let routes;
  if (userPerfil === 'BATFIN') {
    routes = <RoutesBATFIN userToken={userToken} pathInit={pathInit} />;
  } else if (userPerfil === 'BATPLA') {
    routes = <RoutesBATPLA userToken={userToken} pathInit={pathInit} />;
  } else {
    routes = <RroutesDefault userToken={userToken} pathInit={pathInit} />;
  }

  return (
    <div style={{ height: "100%" }}>
      {routes}
    </div >
  )
}

const RoutesBATFIN = ({ userToken, pathInit }) => {
  return (
    <Routes>
      <Route path="/" element={<Navigate to={`${(pathInit || '/')}/execution-big-query`} />} />
      <Route path={`${(pathInit || '/')}`}>
        <Route index element={<Navigate to={`${(pathInit || '/')}/execution-big-query`} />} />
        <Route path="execution-big-query" element={<ExecuteBat userToken={userToken} />} />
      </Route>
    </Routes>
  )
}

const RoutesBATPLA = ({ userToken, pathInit }) => {
  return (
    <Routes>
      <Route path="/" element={<Navigate to={`${(pathInit || '/')}/execution-rotation`} />} />
      <Route path={`${(pathInit || '/')}`}>
        <Route index element={<Navigate to={`${(pathInit || '/')}/execution-rotation`} />} />
        <Route path="execution-rotation" element={<ExecuteBatRotation userToken={userToken} />} />
      </Route>
    </Routes>
  )
}

const RroutesDefault = ({ userToken, pathInit }) => {

  return (
    <Routes>
      {/* <Route path="/" element={<Navigate to={`${(pathInit || '/')}`} />} /> */}
      <Route path={`${(pathInit || '/')}`} element={<Home userToken={userToken} />} />
      <Route path={`${(pathInit || '')}/information`} element={<PersonalData userToken={userToken} />} />
      <Route path={`${(pathInit || '')}/overtime`} element={<OverTime userToken={userToken} />} />
      <Route path={`${(pathInit || '')}/check-overtime`} element={<CheckOverTime userToken={userToken} />} />
      <Route path={`${(pathInit || '')}/view-time-compensated`} element={<ViewTime userToken={userToken} />} />
      <Route path={`${(pathInit || '')}/register`} element={<Register userToken={userToken} />} />
      <Route path={`${(pathInit || '')}/user-perfil`} element={<UsuarioPerfil userToken={userToken} />} />
      <Route path={`${(pathInit || '')}/register-boss`} element={<RegisterBoss userToken={userToken} />} />
      <Route path={`${(pathInit || '')}/big-query`} element={<ExecuteBat userToken={userToken} />} />
      <Route path={`${(pathInit || '')}/supervisors`} element={<Supervisors userToken={userToken} />} />
      <Route path={`${(pathInit || '')}/execution-big-query`} element={<ExecuteBat userToken={userToken} />} />
      <Route path={`${(pathInit || '')}/execution-rotation`} element={<ExecuteBatRotation userToken={userToken} />} />
      <Route path={`${(pathInit || '')}/trusted-worker`} element={<TrustedWorker userToken={userToken} />} />
      <Route path={`${(pathInit || '')}/billing-edit`} element={<BillingEdit userToken={userToken} />} />
      <Route path={`${(pathInit || '')}/requests-ti`} element={<Requests userToken={userToken} />} />
      <Route path={`${(pathInit || '')}/check-requests`} element={<CheckRequests userToken={userToken} />} />
      <Route path={`${(pathInit || '')}/maintenance-equipment`} element={<Equipment userToken={userToken} />} />
      <Route path={`${(pathInit || '')}/mof`} element={<MofV2 userToken={userToken} />} />
      
    </Routes>
  )
}

export default App;
