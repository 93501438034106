import React, { useState } from 'react';
import { Box, Grid, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { CODES } from '../../../utils/responseCodes';
import { Toast } from '../../../utils/alerts';
import MainButton from '../../components/general/MainButton';
import BatService from '../../../services/bat';
import SpinnerLoader from '../../components/general/SpinnerLoader';

const ExecuteBat = ({ userToken }) => {
  const theme = useTheme();
  const [openModalLoader, setOpenModalLoader] = useState(false);
  const [titleModalLoader, setTitleModalLoader] = useState(null);
  const [msgResponse, setMsgResponse] = useState('Esperando Inicio...');

  //**Mensual */
  const executionValidation = async () => {
    try {
      setOpenModalLoader(true);
      setTitleModalLoader('Validando ejecucion Big query - mensual!');
      const result = await BatService.executionValidation(1); //! 1 = bigquery.bat, se consulta si esta bloqueado 
      if (result?.status === CODES.SUCCESS_200) {
        if (Number(result?.data?.nro1) !== 1) {
          executeBigQuery();
        } else {
          Toast.fire({
            icon: 'info',
            title: 'Actualmente el proceso esta en ejecución (espere a que termine)!',
          });
          setOpenModalLoader(false);
          setTitleModalLoader(null);
        }
      } else {
        Toast.fire({
          icon: 'error',
          title: 'Error comuniquese con TI!',
        });
        setOpenModalLoader(false);
        setTitleModalLoader(null);
      }
      return [];
    } catch (error) {
      setOpenModalLoader(false);
      setTitleModalLoader(null);
      console.log(`Error en executionValidation: ${error}`);
    }
  };

  const executeBigQuery = async () => {
    try {
      console.log('🚀🥵 ~ Inicio ~ executeBigQuery ~', new Date());
      setOpenModalLoader(true);
      setTitleModalLoader('Espere un momento: estamos ejecutando el programa BigQuery - mensual...');
      const result = await BatService.getExecuteBigQuery();
      console.log("🚀🥵 ~ result ~", result)
      if (result.status === CODES.SUCCESS_200) {
        Toast.fire({
          icon: 'success',
          title: 'Fin de la ejecución!',
        });
        setMsgResponse('Se termino la ejecución BigQuery - mensual');
      } else {
        setMsgResponse('Error en la ejecución comuniquese con TI');
        Toast.fire({
          icon: 'error',
          title: 'Error en la ejecución comuniquese con TI!',
        });
      }
      console.log('🚀🥵 ~ Fin ~ executeBigQuery ~', new Date());
      return [];
    } catch (error) {
      console.log(`Error en getAsistenciaCondicion: ${error}`);
    } finally {
      setOpenModalLoader(false);
    }
  };

  //**Anual */
  const executionValidationAnual = async () => {
    try {
      setOpenModalLoader(true);
      setTitleModalLoader('Validando ejecucion Big query - anual!');
      const result = await BatService.executionValidation(1);  //! 1 = bigquery.bat, se consulta si esta bloqueado 
      if (result?.status === CODES.SUCCESS_200) {
        if (Number(result?.data?.nro1) !== 1) {
          executeBigQueryAnual();
        } else {
          Toast.fire({
            icon: 'info',
            title: 'Actualmente el proceso esta en ejecución (espere a que termine)!',
          });
          setOpenModalLoader(false);
          setTitleModalLoader(null);
        }
      } else {
        Toast.fire({
          icon: 'error',
          title: 'Error comuniquese con TI!',
        });
        setOpenModalLoader(false);
        setTitleModalLoader(null);
      }
      return [];
    } catch (error) {
      setOpenModalLoader(false);
      setTitleModalLoader(null);
      console.log(`Error en executionValidation: ${error}`);
    }
  };

  const executeBigQueryAnual = async () => {
    try {
      console.log('🚀🥵 ~ Inicio ~ executeBigQueryAnual ~', new Date());
      setOpenModalLoader(true);
      setTitleModalLoader('Espere un momento: estamos ejecutando el programa BigQuery - anual...');
      const result = await BatService.executionBigQueryAnual();
      console.log("🚀🥵 ~ result ~", result)
      if (result.status === CODES.SUCCESS_200) {
        setMsgResponse('Se termino la ejecución BigQuery - anual');
        Toast.fire({
          icon: 'success',
          title: 'Fin de la ejecución!',
        });
      } else {
        setMsgResponse('Error en la ejecución comuniquese con TI');
        Toast.fire({
          icon: 'error',
          title: 'Error en la ejecución comuniquese con TI!',
        });
      }
      console.log('🚀🥵 ~ Fin ~ executeBigQueryAnual ~', new Date());
      return [];
    } catch (error) {
      console.log(`Error en getAsistenciaCondicion: ${error}`);
    } finally {
      setOpenModalLoader(false);
    }
  };

  return (
    <Box sx={{ flexGrow: 1 }}>
      <Grid container direction="row" justifyContent="center" alignItems="center"
        sx={{ paddingTop: '10%' }}
      >
        <Grid item xs={12} paddingLeft={'1rem'} paddingRight={'1rem'}
          style={{
            display: 'flex',
            justifyContent: 'center',
            paddingTop: '1%',
          }}
        >
          <MainButton
            title="Ejecutar BigQuery"
            onClick={executionValidation}
            color="white"
            background={theme.palette.primary.main}
          />
        </Grid>
        <Grid item xs={12} paddingLeft={'1rem'} paddingRight={'1rem'}
          style={{
            display: 'flex',
            justifyContent: 'center',
            paddingTop: '1%',
          }}
        >
          <MainButton
            title="Ejecutar BigQuery Anual"
            onClick={executionValidationAnual}
            color="white"
            background={theme.palette.primary.main}
          />
        </Grid>

        <Grid item xs={12} paddingLeft={'1rem'} paddingRight={'1rem'}
          style={{
            display: 'flex',
            justifyContent: 'center',
            paddingTop: '1%',
          }}
        >
          <Typography variant="h6" color="text.primary">
            {msgResponse}
          </Typography>
        </Grid>
      </Grid>
      <SpinnerLoader
        open={openModalLoader}
        title={titleModalLoader}
      />
    </Box>
  );
};

export default ExecuteBat;
