import React, { useState, useEffect } from 'react';
import { CardHeader, CardContent, CardActions, } from '@mui/material';
import MofService from '../../../../services/mof';
import { Modal } from 'antd';
import { Toast } from '../../../../utils/alerts';
import { CODES } from '../../../../utils/responseCodes';
import AssignMofs from '../../../components/MofV2/AssignMofs/AssignMofs';
import { Box } from '@mui/system';

const AssignMof = (props) => {
  const {open, handleClose, setOpenModalLoader, companies, puesto, infoUsuarioLA, listaMof, getUsuarioxMof, mofxUsuario ,datosMof,setDatosMof,getDatosMofArray} = props;
  const [selectedRows, setSelectedRows] = useState([]);

  

  const cleanInfo = () => {
    setSelectedRows([]);
    handleClose();
    setDatosMof([]);
    getUsuarioxMof();
  }
 

  const guardado = async () => {
    if (infoUsuarioLA) {
      setOpenModalLoader(true);

      try {
        const result = await MofService.saveUsuarioxMof(selectedRows, infoUsuarioLA?.value,datosMof);
        if (result.status === CODES.CREATE_201) {
          Toast.fire({
            icon: 'success',
            title: result.data.message || 'MOF Asignado correctamente'
          })
          cleanInfo();
        } else {
          const errorMessage = result?.response?.data?.error;
          const mofIds = result?.response?.data?.mof_id;
        
          // Construye el mensaje de error incluyendo todos los mof_id si están disponibles
          const fullErrorMessage = Array.isArray(mofIds) && mofIds.length > 0 
            ? `${errorMessage}${mofIds.join(', ')}`
            : errorMessage;
        
          Toast.fire({
            icon: 'error',
            title: fullErrorMessage,
            showConfirmButton: true, // Mostrar botón de confirmación (cerrar)
            confirmButtonText: 'Cerrar', // Texto del botón
            timer: false, // Desactiva el cierre automático
            toast: false, // Para que no se muestre como un toast sino como un modal
          });
        }
        return [];
      } catch (error) {
        console.log(`Error en guardado: ${error}`);
      } finally {
        setOpenModalLoader(false);
        //getUsuarioxMof();
      }
    } else {
      Toast.fire({
        icon: 'error',
        title: ('Debe elegir un Usuario antes de asignar')
      })
      setOpenModalLoader(false);
    }
  }

  return (
    <Modal
      title={
        <CardHeader
          title={"Asigancion de MOF"}
          subheader={"Asignar MOF "}
          sx={{
            paddingBottom: "0.3rem",
            paddingTop: 0,
            margin: "-0.5rem -1rem -0.1rem -1rem",
            borderBottom: `solid 0.05rem #D5DBDB`
          }}
        />
      }
      open={open}
      onOk={cleanInfo}
      onCancel={cleanInfo}
      destroyOnClose={true}
      footer={null}
      style={{ top: "10%" }}
      width={"70%"}

    >
      <Box sx={{ overflow: "scroll", p: 2, backgroundColor: "#" }}>
        <form >
          <CardContent
            sx={{
              padding: 0,
              margin: "0 -0.5rem", backgroundColor: ""
            }}
          >
            <AssignMofs
              setOpenModalLoader={setOpenModalLoader}
              companies={companies}
              puesto={puesto}
              infoUsuarioLA={infoUsuarioLA}
              listaMof={listaMof}
              selectedRows={selectedRows} setSelectedRows={setSelectedRows}
              mofxUsuario={mofxUsuario}
              getDatosMofArray={getDatosMofArray}
            />
          </CardContent>
          <CardActions
            sx={{
              margin: "0.5rem -1rem -1rem -1rem",
              borderTop: `solid 0.05rem #00337C`, backgroundColor: ""
            }}
          >
            <button
              type={"button"}
              onClick={guardado}
              className="btn btn-primary btn-sm"
              style={{ fontSize: "15px" }}
            >
              Guardar
            </button>
            <button
              type={"button"}
              onClick={cleanInfo}
              className="btn btn-danger btn-sm"
              style={{ fontSize: "15px" }}
            >
              Cerrar
            </button>
          </CardActions>
        </form>
      </Box>
    </Modal>
  );
};

export default AssignMof;