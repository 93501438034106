import React, { useEffect, useState } from 'react';
import { Modal, Typography, Box, Card, CardActions, CardContent, Button, TextField } from '@mui/material';
import SuperAreas from '../../../components/Supervisors/AddSupervisors/SuperAreas';
import SuperTurnos from '../../../components/Supervisors/AddSupervisors/SuperTurnos';
import SupervisorsService from '../../../../services/supervisors';
import { CODES } from '../../../../utils/responseCodes';
import { Toast } from '../../../../utils/alerts';

const AddSupervisors = (props) => {
    const { open, handleClose, setOpenModalLoader, body, idUsuario, searchStaff, setSearchStaff,
        totalPagesStaff, pageStaff, setPageStaff,
        dataStaffs, infoArea, setInfoArea, infoShift, setInfoShift, } = props;

    const url = "'" + window.location.hash.replace('#', '') + "'";
    const [dataSuperArea, setDataSuperArea] = useState(null);
    const [dataSuperAreaCopy, setDataSuperAreaCopy] = useState([]);
    const [dataSuperTurno, setDataSuperTurno] = useState(null);
    const [dataSuperTurnoCopy, setDataSuperTurnoCopy] = useState([]);

    useEffect(() => {
        if (body === 0) {
            if (infoArea)
                getSupervisorsArea();
        } else {
            if (infoShift)
                getSupervisorsTurno();
        }
    }, [body, infoArea, infoShift])

    const getSupervisorsArea = async () => {
        try {
            setOpenModalLoader(true);
            const result = await SupervisorsService.getSupervisorsArea(infoArea?.id_area);
            if (result.status === CODES.SUCCESS_200) {
                setDataSuperArea(result.data);
                setDataSuperAreaCopy(result.data);
            }
        } catch (error) {
            console.log(`Error en getSupervisorsArea: ${error}`);
        } finally {
            setOpenModalLoader(false);
        }
    };

    const getSupervisorsTurno = async () => {
        try {
            setOpenModalLoader(true);
            const result = await SupervisorsService.getSupervisorsTurno(infoShift?.id_turno);
            if (result.status === CODES.SUCCESS_200) {
                setDataSuperTurno(result.data);
                setDataSuperTurnoCopy(result.data);
            }
        } catch (error) {
            console.log(`Error en getSupervisorsTurno: ${error}`);
        } finally {
            setOpenModalLoader(false);
        }
    };

    const saveSupervisorArea = async () => {
        setOpenModalLoader(true);
        try {
            const savePromises = dataSuperAreaCopy?.map(async (superA) => {
                try {
                    superA.usuario_id = idUsuario;
                    const result = await SupervisorsService.saveSupervisorArea(url, superA);
                    if (result.status === CODES.CREATE_201) {
                        Toast.fire({
                            icon: 'success',
                            title: result.data.message
                        })
                    } else {
                        Toast.fire({
                            icon: 'error',
                            title: result.response.data.message
                        })
                    }
                } catch (error) {
                    console.log(`Error en saveUsuarioAreas: ${error}`);
                }
            });

            await Promise.all([...savePromises]);
            setTimeout(function () {
                Toast.fire({
                    icon: 'success',
                    title: 'Fin asignados'
                })
            }, 2000);
            cleanInfo();
        } catch (error) {
            console.log(`Error en saveAssociateAreas: ${error}`);
        } finally {
            setOpenModalLoader(false);
        }
    };

    const saveSupervisorTurno = async (e) => {
        try {
            setOpenModalLoader(true);
            const savePromises = dataSuperTurnoCopy?.map(async (superT) => {
                try {
                    superT.usuario_id = idUsuario;
                    const result = await SupervisorsService.saveSupervisorTurno(url, superT);
                    if (result.status === CODES.CREATE_201) {
                        Toast.fire({
                            icon: 'success',
                            title: result.data.message
                        })
                    } else {
                        Toast.fire({
                            icon: 'error',
                            title: result.response.data.message
                        })
                    }
                } catch (error) {
                    console.log(`Error en saveSupervisorTurno: ${error}`);
                }
            });

            await Promise.all([...savePromises]);

            setTimeout(function () {
                Toast.fire({
                    icon: 'success',
                    title: 'Fin asignados'
                })
            }, 2000);
            cleanInfo();
        } catch (error) {
            console.log(`Error en saveAssociateAreas: ${error}`);
        } finally {
            setOpenModalLoader(false);
        }
    };

    const cleanInfo = () => {
        setDataSuperArea(null);
        setDataSuperAreaCopy([]);
        setDataSuperTurno(null);
        setDataSuperTurnoCopy([]);
        setInfoArea(null);
        setInfoShift(null);
        handleClose();
    }

    return (
        <Modal
            open={open}
            onClose={cleanInfo}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box
                sx={{
                    position: 'absolute',
                    top: '45%', //'50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: '60%', //400,
                    height: '90vh',
                    bgcolor: 'background.paper',
                    borderRadius: '3%',
                    p: 2,
                    overflow: 'scroll'
                }}
            >
                <Card>
                    <CardContent>
                        <Typography gutterBottom variant="subtitle1" component="div">
                            {body === 0 ? 'Agregar supervisor área' : 'Agregar supervisor turno '}
                        </Typography>
                        <TextField
                            id="input-with-sx"
                            variant="standard"
                            label="busqueda de trabajadores"
                            sx={{ paddingBottom: 1 }}
                            fullWidth
                            value={searchStaff || ''}
                            onChange={(e) => setSearchStaff(e.target.value)}
                        />
                        {body === 0 ?
                            <SuperAreas
                                totalPagesStaff={totalPagesStaff}
                                pageStaff={pageStaff}
                                setPageStaff={setPageStaff}

                                idUsuario={idUsuario}
                                infoArea={infoArea}
                                dataStaffs={dataStaffs}
                                dataSuperArea={dataSuperArea}
                                setDataSuperArea={setDataSuperArea}
                                dataSuperAreaCopy={dataSuperAreaCopy}
                                setDataSuperAreaCopy={setDataSuperAreaCopy}
                            /> :
                            <SuperTurnos
                                totalPagesStaff={totalPagesStaff}
                                pageStaff={pageStaff}
                                setPageStaff={setPageStaff}

                                idUsuario={idUsuario}
                                infoShift={infoShift}
                                dataStaffs={dataStaffs}
                                dataSuperTurno={dataSuperTurno}
                                setDataSuperTurno={setDataSuperTurno}
                                dataSuperTurnoCopy={dataSuperTurnoCopy}
                                setDataSuperTurnoCopy={setDataSuperTurnoCopy}
                            />
                        }
                    </CardContent>
                    <CardActions>
                        <Button size="small" onClick={body === 0 ? saveSupervisorArea : saveSupervisorTurno} color='success'>Guardar</Button>
                        <Button size="small" onClick={cleanInfo} color='error'>Cerrar</Button>
                    </CardActions>
                </Card>
            </Box>
        </Modal>
    );
}

export default AddSupervisors;