import React, { useState, useEffect } from "react";
import { Select, Input, Form, Radio } from "antd";
import BodyAptitudes from "../BodyMofTabs/BodyAptitudes";
import BodyCompetencias from "../BodyMofTabs/BodyCompetencias";
import BodyAmbientales from "../BodyMofTabs/BodyAmbientales";
import BodyRiesgo from "../BodyMofTabs/BodyRiesgo";
import BodyExigencias from "../BodyMofTabs/BodyExigencias";
import BodyMovimientos from "../BodyMofTabs/BodyMovimientos";
import { Box, Grid } from "@mui/material";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import GeneralService from "../../../../services/general";
import MofService from "../../../../services/mof";
import { CODES } from "../../../../utils/responseCodes";
import { Toast } from "../../../../utils/alerts";
import { bottom } from "@popperjs/core";
import Swal from "sweetalert2";

import { getDateOL } from '../../../../utils/function';

const { Option } = Select;

const AddRegisterMofsV2 = (props) => {

  const { userToken, setOpenModalLoader, puesto, companies, datosMof, setDatosMof, puestoId, setPuestoId, companieId, setCompanieId, clienteId, setClienteId, opcionVer, mofxUsuarioBody, idPerfil, mofId, idUsuario } = props;

  const [radioValue, setRadioValue] = useState("0"); // Estado para el valor del radio
  const fechAprobacion = (getDateOL(new Date(), 'large'));
  const fechRecepcion = (getDateOL(new Date(), 'large'));
  const trabajador = userToken?.user.trabajador;


  const [clienteEmpresa, setClienteEmpresa] = useState(null);
  const [recpcionados, setRecepcionados] = useState(null);
  const [estandarMof, setEstandarMof] = useState(null);
  const [categoriaSel, setCategoriaSel] = useState({ desc1: "", corr3: null });
  const [cg, setCg] = useState({ 1: "", 2: "", 3: "", 4: "", 5: "", });
  const [ce, setCe] = useState({ 1: "", 2: "", 3: "", 4: "", 5: "", });
  const [body, setBody] = useState(0); //para manejar los body de aptitudes,riesgos,competencias etc

  const handleRadioChange = (e) => {
    setRadioValue(e.target.value);
  };

  const handleChangeBody = (event, newValue) => {
    setBody(newValue);
  };

  useEffect(() => {
    if (categoriaSel.corr3) getEstandarbyCategoria();
    setEstandarMof(null);
  }, [categoriaSel.corr3]);

  useEffect(() => {
    if (companieId) getClientesByEmpresa();
    setClienteEmpresa(null);
  }, [companieId]);

  // Verifica y establece el valor de "Perú" en el campo de ubicación geográfica al cargar el componente
  useEffect(() => {
    if (datosMof.length > 0) {
      const needsUpdate = datosMof.some(item => item.id_preguntamof === 2 && (!item.detalle_mof || !item.detalle_mof.respuesta));
      if (needsUpdate) {
        const updatedDatosMof = datosMof.map((item) => {
          if (item.id_preguntamof === 2 && (!item.detalle_mof || !item.detalle_mof.respuesta)) {
            return {
              ...item,
              detalle_mof: {
                ...item.detalle_mof,
                respuesta: "Perú",
              },
            };
          }
          return item;
        });
        setDatosMof(updatedDatosMof);
      }
    }
  }, [datosMof]);


  useEffect(() => {
    if (datosMof.length > 0) {
      const needsUpdate = datosMof.some(item => item.id_preguntamof === 134 && (!item.detalle_mof || item.detalle_mof.respuesta_id == null));
      if (needsUpdate) {
        const updatedDatosMof = datosMof.map((item) => {
          if (item.id_preguntamof === 134 && (!item.detalle_mof || item.detalle_mof.respuesta_id == null)) {
            return {
              ...item,
              detalle_mof: {
                ...item.detalle_mof,
                respuesta_id: item.detalle_mof?.respuesta_id ?? 1,
              },
            };
          }
          return item;
        });
        setDatosMof(updatedDatosMof);
      }
    }
  }, [datosMof]);



  const getClientesByEmpresa = async () => {
    await GeneralService.getClientesByEmpresa(companieId)
      .then((result) => {
        if (result.status === CODES.SUCCESS_200) {
          result.data?.forEach((element) => {
            element.id = element.id_cliente;
            element.value = element.id_cliente;
            element.label = element.desCliente;
          });
          setClienteEmpresa(result.data);
        } else {
          Toast.fire({
            icon: "info",
            title: "No hay información de clientes",
          });
        }
      })
      .catch((err) => {
        console.log("getClientesByEmpresa", err);
      });
  };

  const getEstandarbyCategoria = async () => {
    setOpenModalLoader(true);
    try {
      const result = await MofService.getEstandarbyCategoria(categoriaSel.corr3);
      if (result.status === CODES.SUCCESS_200) {
        if (result?.data?.length === 0) {
          Toast.fire({
            icon: "info",
            title: "No se encontraron datos estandares para la categoria",
          });
          return;
        }
        setEstandarMof(result.data);
        setOpenModalLoader(false);
        actualizarMultiplesPreguntas(result.data);
      }

    } catch (error) {
      console.log(`Error en getEstandarbyCategoria: ${error}`);
    }
  };

  // Para actualizar múltiples preguntas en datosMof
  const actualizarMultiplesPreguntas = (data) => {
    const newDataMof = datosMof.map((item) => {
      const estandarItem = data.find(estandar => estandar.pregunta_id === item.id_preguntamof.toString());
      if (estandarItem) {
        return {
          ...item,
          detalle_mof: {
            ...item.detalle_mof,
            respuesta_id: parseInt(estandarItem.respuesta_id)
          }
        };
      }
      return item;
    });
    setDatosMof(newDataMof);
  };
  //---INPUTS--
  let ubicacion = datosMof?.find(item => item.id_preguntamof === 2);
  let area = datosMof?.find(item => item.id_preguntamof === 3);
  //let nivel = datosMof?.find(item => item.id_preguntamof === 4);
  let reemplaza = datosMof?.find(item => item.id_preguntamof === 6);
  let mision = datosMof?.find(item => item.id_preguntamof === 7);
  let reporta = datosMof?.find(item => item.id_preguntamof === 8);
  let supervisa = datosMof?.find(item => item.id_preguntamof === 9);
  //let ascenso = datosMof?.find(item => item.id_preguntamof === 16);
  let relacionInterna = datosMof?.find(item => item.id_preguntamof === 17);
  let relacionCliente = datosMof?.find(item => item.id_preguntamof === 123);
  let relacionProveedor = datosMof?.find(item => item.id_preguntamof === 124);
  let general = datosMof?.find(item => item.id_preguntamof === 18);
  let diaria = datosMof?.find(item => item.id_preguntamof === 19);
  let periodica = datosMof?.find(item => item.id_preguntamof === 20);
  let eventual = datosMof?.find(item => item.id_preguntamof === 21);
  let especialidad = datosMof?.find(item => item.id_preguntamof === 23);
  let conocimiento = datosMof?.find(item => item.id_preguntamof === 24);
  let maquina = datosMof?.find(item => item.id_preguntamof === 27);
  let experiencia = datosMof?.find(item => item.id_preguntamof === 131);
  let aprobadoPor = datosMof?.find(item => item.id_preguntamof === 132);
  let fechaAprobacion = datosMof?.find(item => item.id_preguntamof === 133);
  let recepcionadoPor = datosMof?.find(item => item.id_preguntamof === 135);
  let valorEstado = datosMof?.find(item => item.id_preguntamof === 134)?.detalle_mof?.respuesta_id;// solo para ver qeu estado tiene el mof




  //----Esto ira para la cabecera del Mof------
  const selectedPuesto = puesto.find((opt) => opt.id_puesto == puestoId);
  const selectedEmpresa = companies.find((opt) => opt.id_empresa == companieId);
  const selectedCliente = clienteEmpresa?.find((opt) => opt.id_cliente == clienteId);

  //inicio----------------------------------------FUNCION PARA OBTENER EL DATO SELECCIONADO-----
  const obtenerSeleccion = (id_pregunta) => {
    const pregunta = datosMof.find((item) => item.id_preguntamof === id_pregunta);
    if (!pregunta) return { desc1: "", corr3: null };

    const seleccion = pregunta?.detalle_mof?.respuesta_id
      ? pregunta.alternativas.find(opcion => opcion.corr3 === parseInt(pregunta.detalle_mof.respuesta_id))
      : null;

    return {
      desc1: seleccion ? seleccion.desc1 : "",
      corr3: seleccion ? seleccion.corr3 : null
    };
  };

  useEffect(() => {
    setCategoriaSel(obtenerSeleccion(5));
  }, [datosMof]);

  // let categoriaSel = obtenerSeleccion(5);
  let supRecibeSel = obtenerSeleccion(10);
  let supEjercidaSel = obtenerSeleccion(11);
  let materiaSel = obtenerSeleccion(12);
  let equipoSel = obtenerSeleccion(13);
  let confidencialidadSel = obtenerSeleccion(14);
  let precisionSel = obtenerSeleccion(15);
  let formacionSel = obtenerSeleccion(22);
  let experienciaSel = obtenerSeleccion(25);
  let curvaSel = obtenerSeleccion(26);
  let esfuerzoVisualSel = obtenerSeleccion(121);
  let esfuerzoFisicoSel = obtenerSeleccion(122);
  let compGenericaSel = obtenerSeleccion(125);
  let nivelSel = obtenerSeleccion(4);
  let conocimientoSel01 = obtenerSeleccion(127);
  let conocimientoSel02 = obtenerSeleccion(128);
  let conocimientoSel03 = obtenerSeleccion(129);
  let conocimientoSel04 = obtenerSeleccion(130);
  useEffect(() => {
    const nivel = datosMof?.find(item => item.id_preguntamof === 4);
    if (nivel && nivel.detalle_mof && categoriaSel.corr3) {
      setDatosMof(prevNivel => {
        const isUpdated = prevNivel.some(item => item.id_preguntamof === 4 && item.detalle_mof?.respuesta !== nivelSel.desc1);
        if (isUpdated) {
          return prevNivel.map(item => {
            if (item.id_preguntamof === 4) {
              return {
                ...item,
                detalle_mof: {
                  ...item.detalle_mof,
                  respuesta: nivelSel.desc1  // Asigna el valor correcto a la propiedad respuesta
                }
              };
            }
            return item;
          });
        }
        return prevNivel;
      });
    }
  }, [categoriaSel.corr3, datosMof]);

  useEffect(() => {
    const competenciaGenerica = datosMof?.find(item => item.id_preguntamof === 125);

    if (competenciaGenerica?.detalle_mof?.respuesta_id) {
      const cgValues = compGenericaSel.desc1.split(',');
      const newCg = {
        1: cgValues[0] || "",
        2: cgValues[1] || "",
        3: cgValues[2] || "",
        4: cgValues[3] || ""
      };

      setCg(prevCg => {
        // Solo actualiza si los valores son diferentes para evitar bucles infinitos
        if (JSON.stringify(prevCg) !== JSON.stringify(newCg)) {
          return newCg;
        }
        return prevCg;
      });

      // Solo actualiza datosMof si la respuesta no es igual a compGenericaSel.desc1
      setDatosMof(prevDatosMof => {
        const isUpdated = prevDatosMof.some(item => item.id_preguntamof === 125 && item.detalle_mof.respuesta !== compGenericaSel.desc1);
        if (isUpdated) {
          return prevDatosMof.map(item => {
            if (item.id_preguntamof === 125) {
              return {
                ...item,
                detalle_mof: {
                  ...item.detalle_mof,
                  respuesta: compGenericaSel.desc1
                }
              };
            }
            return item;
          });
        }
        return prevDatosMof;
      });
    }
  }, [datosMof, compGenericaSel]);

  //Logica para extraer los valores para  los campos de competencias 
  useEffect(() => {
    const competenciaEspecifica = datosMof?.find(item => item.id_preguntamof === 126);
    if (competenciaEspecifica?.detalle_mof?.respuesta) {
      const cEValues = competenciaEspecifica.detalle_mof.respuesta.split(',');
      const newCE = {
        1: cEValues[0] || "",
        2: cEValues[1] || "",
        3: cEValues[2] || "",
        4: cEValues[3] || "",
        5: cEValues[4] || "",
      };

      setCe(newCE);

    }
  }, [datosMof]);

  // Logica para competencia generia concatenar varios inputs (id 125)
  const handleInputChange = (inputId, newValue, type) => {
    if (type === "ce") {
      setCe(prevInputs => {
        const newInputs = { ...prevInputs, [inputId]: newValue };
        const concatenatedValues = Object.values(newInputs).filter(Boolean).join(',');

        const newDataMof = datosMof.map(item => {
          if (item.id_preguntamof === 126) {
            return {
              ...item,
              detalle_mof: {
                ...item.detalle_mof,
                respuesta: concatenatedValues
              }
            };
          }
          return item;
        });

        setDatosMof(newDataMof);
        return newInputs;
      });
    }
  };

  //-----------------------------------------------fin-----FUNCION PARA OBTENER EL DATO SELECCIONADO--

  // Función para actualizar los datosMof
  const handleUpdateDatosMof = (id_preguntamof, newValue, property) => {
    const newDataMof = datosMof.map((item) => {
      if (item.id_preguntamof === +id_preguntamof) {
        return {
          ...item,
          detalle_mof: {
            ...item.detalle_mof,
            [property]: newValue
          }
        };
      }
      return item;
    });
    setDatosMof(newDataMof);
  };
  const handlePuestoChange = (value) => {
    setPuestoId(value);
  };


  const handleAprobar = () => {
    let updatedDatosMof = [...datosMof];
    // Actualizar "Aprobado Por"
    const aprobadoPorIndex = updatedDatosMof.findIndex(item => item.id_preguntamof === 132);
    if (aprobadoPorIndex !== -1) {
      updatedDatosMof[aprobadoPorIndex] = {
        ...updatedDatosMof[aprobadoPorIndex],
        detalle_mof: {
          ...updatedDatosMof[aprobadoPorIndex].detalle_mof,
          respuesta: trabajador
        }
      };
    }
    // Actualizar "Fecha de Aprobación"
    const fechaAprobacionIndex = updatedDatosMof.findIndex(item => item.id_preguntamof === 133);
    if (fechaAprobacionIndex !== -1) {
      updatedDatosMof[fechaAprobacionIndex] = {
        ...updatedDatosMof[fechaAprobacionIndex],
        detalle_mof: {
          ...updatedDatosMof[fechaAprobacionIndex].detalle_mof,
          respuesta: fechAprobacion
        }
      };
    }

    setDatosMof(updatedDatosMof);

    // Mostrar SweetAlert
    Swal.fire({
      title: `Aprobado por ${trabajador}`,
      text: 'Para mantener los cambios pulsar en "guardar"',
      icon: 'success',
      confirmButtonText: 'Aceptar'
    });
  };


  const handleRecepcionar = async (e) => {

    e.preventDefault();
    // Mostrar alerta de confirmación
    const result = await Swal.fire({
      title: '¿Deseas recepcionar el MOF?',
      html: "Asegúrate de que todos los datos son correctos.<br><br>",
      // "<strong>Datos MOF:</strong><br>" + 
      // datosMofConAsteriscos.map(item => `ID: ${item.id_preguntamof}, Detalle: ${item.detalle_mof}`).join('<br>'),
      icon: 'warning',

      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Sí, recepcionar',
      cancelButtonText: 'No, cancelar'
    });
    if (result.isConfirmed) {
      setOpenModalLoader(true);

      try {
        const result = await MofService.recepcionarMof(mofId, idUsuario);
        if (result.status === CODES.CREATE_201) {
          Toast.fire({
            icon: 'success',
            title: result.data.message || 'Mof recepcionado'
          });
        } else {
          Toast.fire({
            icon: 'error',
            title: (result?.response?.data?.message || 'Error en recepcionar el Mof')
          });
        }
        return [];
      } catch (error) {
        console.log(`Error en recepcionar: ${error}`);
      } finally {
        setOpenModalLoader(false);

      }
    } else {
      Toast.fire({
        icon: 'info',
        title: 'Operación cancelada'
      });
    }

  };

  const getRecepcionado = async () => {
    try {
      const result = await MofService.getRecepcionado(mofId);
      if (result.status === CODES.SUCCESS_200) {
        setRecepcionados(result.data);
        Toast.fire({
          icon: 'info',
          title: 'Recepcionado por'
        });

        let html = `
          <div style="padding: 20px; background-color: white; border-radius: 10px;">
            <div style="max-height: 15rem; overflow-y: auto;"> <!-- Limita la altura de la tabla y permite el desplazamiento -->
              <table style="width: 100%; text-align: left; border-collapse: collapse;">
                <thead>
                  <tr style="background-color: #f2f2f2;">
                    <th style="padding: 10px; border: 1px solid #ddd;">Nombre</th>
                    <th style="padding: 10px; border: 1px solid #ddd;">Fecha de Recepción</th>
                  </tr>
                </thead>
                <tbody>
        `;
        for (let i = 0; i < result.data.length; i++) {
          const { apePaterno, apeMaterno, nombres, fechaRecepcion } = result.data[i];
          html += `
            <tr>
              <td style="padding: 5px; border: 1px solid #ddd;">${nombres.toUpperCase()} ${apePaterno.toUpperCase()} ${apeMaterno.toUpperCase()}</td>
              <td style="padding: 5px; border: 1px solid #ddd;">${new Date(fechaRecepcion).toLocaleString()}</td>
            </tr>
          `;
        }
        html += `
                </tbody>
              </table>
            </div>
          </div>
        `;

        Swal.fire({
          //icon: 'success',
          title: 'Información de recepción',
          html: html,
          width: '50rem', // Ancho de la alerta
          padding: '1rem', // Espaciado interno
          background: '#f5f5f5', // Fondo de la alerta
          showConfirmButton: false // Oculta el botón de confirmación
        });

      } else {
        Toast.fire({
          icon: "info",
          title: "No hay información de las personas que recepcionaron el mof",
        });
      }
    } catch (err) {
      console.log("getRecepcionado", err);
      Toast.fire({
        icon: 'error',
        title: 'Error al obtener la información de recepción'
      });
    }
  };



  const handleEmpresaChange = (value) => {
    setCompanieId(value);
  };

  const handleClienteChange = (value) => {
    setClienteId(value);
  };

  useEffect(() => {
    // Verificar si selectedCliente tiene un valor válido antes de llamar a handleUpdateDatosMof
    if (selectedCliente) {
      handleUpdateDatosMof(1, selectedCliente.label, "respuesta");
    }
  }, [selectedCliente]);

  return (
    <>
      <div
        className="row"
        style={{
          borderRadius: "5px",
          marginRight: "8px",
          marginTop: "-2%",
        }}
      >
        <div className="col-md-4">
          <div className="group">
            <div className="contenido" style={{ width: "100%" }}>
              <label
                className="label"
                style={{ fontSize: "small", top: "-2px", color: "black" }}
              >
                Aprobador Por
              </label>
              <Input
                size="small"
                placeholder="Aprobado por "
                type="text"
                style={{
                  color: "rgba(0, 0, 0, 0.65)",
                  backgroundColor: "#ffffff",
                }}
                disabled
                value={aprobadoPor?.detalle_mof?.respuesta || ""}
              //onChange={(e) => handleUpdateDatosMof(132, e.target.value, "respuesta")}
              />
            </div>
          </div>
        </div>
        <div className="col-md-2">
          <div className="group">
            <div className="contenido" style={{ width: "100%" }}>
              <label
                className="label"
                style={{ fontSize: "small", top: "-2px", color: "black" }}
              >
                Fecha de aprobación
              </label>
              <Input
                size="small"
                placeholder="Fecha de aprobación"
                type="text"
                style={{
                  color: "rgba(0, 0, 0, 0.65)",
                  backgroundColor: "#ffffff",
                }}
                disabled
                value={fechaAprobacion?.detalle_mof?.respuesta || ""}

              />
            </div>
          </div>
        </div>
        <div className="col-md-3">
          <div className="group">
            <div className="contenido" style={{ width: "100%" }}>
              <Radio.Group onChange={handleRadioChange} value={radioValue}>
                <Radio value="0">Corporativo</Radio>
                <Radio value="1">Operaciones</Radio>
              </Radio.Group>
            </div>
          </div>
        </div>
        <div className="col-md-3">
          <div className="group">
            <div className="contenido" style={{ width: "100%" }}>
              <button

                type="button"
                onClick={getRecepcionado}
                className="btn btn-info btn-sm"
                style={{ fontSize: "15px", marginTop: "-0.4rem" }}
              >
                Ver lista de Recepcionados
              </button>
            </div>
          </div>
        </div>
      </div>


      <div
        className="row"
        style={{
          background: "#cddcf9",
          border: "1px solid #9dc5f6",
          padding: "10px",
          borderRadius: "5px",
          margin: "8px",
        }}
      >
        <label
          className="label"
          style={{
            fontSize: "large",
            top: "-2px",
            color: "white",
            fontFamily: "-moz-initial",
            background: "#336ca5",
            marginBottom: "8px",
          }}
        >
          DATOS
        </label>

        <div className="col-md-4">
          <div className="group">
            <div className="contenido" style={{ width: "100%", margin: "0px" }}>
              <label
                className="label"
                style={{ fontSize: "small", top: "-2px", color: "black" }}
              >
                Identificación del Puesto:
              </label>
              <Select
                mode={"single"}
                maxTagCount={"responsive"}
                className="select"
                size={"small"}
                placeholder={"Seleccionar categoría"}
                showSearch
                popupClassName="my-drop-down"
                value={selectedPuesto?.label || ""}
                onChange={handlePuestoChange}
                required={true}
                disabled={selectedPuesto && Object.keys(selectedPuesto).length !== 0}
                style={{ width: "100%", color: "black", backgroundColor: "white" }}
                filterOption={(input, option) => {
                  const childrenText = option?.children; // Captura el texto de los hijos
                  if (typeof childrenText === 'string') { // Verifica si es una cadena de texto
                    return childrenText.toLowerCase().includes(input.toLowerCase());
                  }
                  return false; // Si no es texto, no coincide
                }}
              >
                <Option value={""}>
                  <em>Seleccionar</em>
                </Option>
                {puesto?.map((opt, idx) => (
                  <Option key={`puesto-${idx}`} value={opt?.value}>
                    {opt?.label}
                  </Option>
                ))}
              </Select>
            </div>
          </div>
        </div>
        <div className="col-md-4">
          <div className="group">
            <div className="contenido" style={{ width: "100%", margin: "0px" }}>
              <label
                className="label"
                style={{ fontSize: "small", top: "-2px", color: "black" }}
              >
                Empresa:
              </label>
              <Select
                mode={"single"}
                maxTagCount={"responsive"}
                className="select"
                size={"small"}
                placeholder={"Seleccionar categoría"}
                optionFilterProp="children"
                showSearch
                popupClassName="my-drop-down"
                value={selectedEmpresa?.label || ""}
                onChange={handleEmpresaChange}
                required={true}
                disabled={selectedEmpresa && Object.keys(selectedEmpresa).length !== 0}
                style={{ width: "100%", backgroundColor: "white" }}
              >
                <Option value={""}>
                  <em>Seleccionar</em>
                </Option>
                {companies?.map((opt, idx) => {
                  return (
                    <Option key={`companies-${idx}`} value={opt?.value}>
                      {opt?.label}
                    </Option>
                  );
                })}
              </Select>
            </div>
          </div>
        </div>
        <div className="col-md-4">
          <div className="group">
            <div className="contenido" style={{ width: "100%", margin: "0px" }}>
              <label
                className="label"
                style={{ fontSize: "small", top: "-2px", color: "black" }}
              >
                Cliente:
              </label>
              <Select
                mode={"single"}
                maxTagCount={"responsive"}
                className="select"
                size={"small"}
                placeholder={"Seleccionar categoría"}
                optionFilterProp="children"
                showSearch
                popupClassName="my-drop-down"
                value={selectedCliente?.label || ""}
                onChange={handleClienteChange}
                disabled={selectedCliente && Object.keys(selectedCliente).length !== 0}
                required={true}
                style={{ width: "100%", backgroundColor: "white" }}
              >
                <Option value={""}>
                  <em>Seleccionar</em>
                </Option>
                {clienteEmpresa?.map((opt, idx) => {
                  return (
                    <Option key={`companies-${idx}`} value={opt?.value}>
                      {opt?.label}
                    </Option>
                  );
                })}
              </Select>
            </div>
          </div>
        </div>
        <div className="col-md-3">
          <div className="group">
            <div className="contenido" style={{ width: "100%", margin: "0px" }}>
              <label
                className="label"
                style={{ fontSize: "small", top: "-2px", color: "black" }}
              >
                Ubicación geográfica
              </label>
              <Input
                size="small"
                //placeholder="Ingrese ubicacion (ejem: Lima)"
                type="text"
                style={{
                  color: "rgba(0, 0, 0, 0.65)",
                  backgroundColor: "#ffffff",
                }}
                value={ubicacion?.detalle_mof?.respuesta || "Perú"}
                disabled
              />
            </div>
          </div>
        </div>
        <div className="col-md-3">
          <div className="group">
            <div className="contenido" style={{ width: "100%", margin: "0px" }}>
              <label
                className="label"
                style={{ fontSize: "small", top: "-2px", color: "black" }}
              >
                Área
              </label>
              <Input
                size="small"
                placeholder="Ingrese área"
                type="text"
                style={{
                  color: "rgba(0, 0, 0, 0.65)",
                  backgroundColor: "#ffffff",
                }}
                disabled={opcionVer === 5 || +mofxUsuarioBody?.permiso_id === 1}
                value={area?.detalle_mof?.respuesta || ""}
                onChange={(e) => handleUpdateDatosMof(3, e.target.value, "respuesta")}

              />
            </div>
          </div>
        </div>
        <div className="col-md-3">
          <div className="group">
            <div className="contenido" style={{ width: "100%", margin: "0px" }}>
              <label
                className="label"
                style={{ fontSize: "small", top: "-2px", color: "black" }}
              >
                Nivel:
              </label>
              <Input
                //disabled={opcionVer === 5 || +mofxUsuarioBody?.permiso_id === 1}
                disabled
                size="small"
                placeholder="Ingrese nivel"
                type="text"
                style={{
                  color: "rgba(0, 0, 0, 0.65)",
                  backgroundColor: "#ffffff",
                }}
                value={nivelSel?.desc1}
              // onChange={(e) => handleUpdateDatosMof(4, e.target.value, "respuesta")}
              />
            </div>
          </div>
        </div>
        <div className="col-md-3">
          <div className="group">
            <div className="contenido" style={{ width: "100%", margin: "0px" }}>
              <label
                className="label"
                style={{ fontSize: "small", top: "-2px", color: "black" }}
              >
                Categoría:
              </label>
              <Select
                mode={"single"}
                maxTagCount={"responsive"}
                className="select"
                size={"small"}
                placeholder={"Seleccionar categoría"}
                optionFilterProp="children"
                showSearch
                popupClassName="my-drop-down"
                value={categoriaSel.desc1}
                disabled={opcionVer === 5 || +mofxUsuarioBody?.permiso_id === 1}
                onChange={(value) => handleUpdateDatosMof(5, value, "respuesta_id")}
                required={true}
                style={{ width: "100%", backgroundColor: "white" }}

              >
                <Option value=""><em>Seleccionar</em></Option>
                {datosMof.find((item) => item.id_preguntamof === 5)?.alternativas.map((opcion) => (
                  <Option key={`categoria-${opcion.corr3}`} value={opcion.corr3}>
                    {opcion.desc1}
                  </Option>
                ))}
              </Select>
            </div>
          </div>
        </div>
        <div className="col-md-6">
          <div className="group">
            <div className="contenido" style={{ width: "100%", margin: "0px" }}>
              <label
                className="label"
                style={{ fontSize: "small", top: "-2px", color: "black" }}
              >
                Lo reemplaza a :
              </label>
              <Input
                size="small"
                placeholder="Ingrese nombre"
                type="text"
                style={{
                  color: "rgba(0, 0, 0, 0.65)",
                  backgroundColor: "#ffffff",
                }}
                disabled={opcionVer === 5 || +mofxUsuarioBody?.permiso_id === 1}
                value={reemplaza?.detalle_mof?.respuesta || ""}
                onChange={(e) => handleUpdateDatosMof(6, e.target.value, "respuesta")}
              />
            </div>
          </div>
        </div>
        <div className="col-md-6">
          <div className="group">
            <div className="contenido" style={{ width: "100%", margin: "0px" }}>
              <label
                className="label"
                style={{ fontSize: "small", top: "-2px", color: "black" }}
              >
                Misión del puesto :
              </label>
              <Input
                size="small"
                placeholder="Ingrese misión"
                type="text"
                style={{
                  color: "rgba(0, 0, 0, 0.65)",
                  backgroundColor: "#ffffff",
                }}
                disabled={opcionVer === 5 || +mofxUsuarioBody?.permiso_id === 1}
                value={mision?.detalle_mof?.respuesta || ""}
                onChange={(e) => handleUpdateDatosMof(7, e.target.value, "respuesta")}
              />
            </div>
          </div>
        </div>
      </div>
      <div
        className="row"
        style={{
          marginLeft: "20px",
          marginRight: "8px",
          marginBottom: "16px",
          marginTop: "16px",
        }}
      >
        <div
          className="row col-md-6"
          style={{
            border: "1px solid #9dc5f6",
            padding: "10px",
            borderRadius: "5px",
            background: "#cddcf9",
          }}
        >
          <label
            className="label"
            style={{
              fontSize: "large",
              top: "-2px",
              color: "white",
              fontFamily: "-moz-initial",
              background: "#336ca5",
              marginBottom: "8px",
            }}
          >
            LINEA DE AUTORIDAD
          </label>
          <div className="col-md-6">
            <div className="group">
              <div className="contenido" style={{ width: "100%" }}>
                <label
                  className="label"
                  style={{ fontSize: "small", top: "-2px", color: "black" }}
                >
                  Lo reporta a :
                </label>
                <Input
                  size="small"
                  placeholder="Ingrese nombre"
                  type="text"
                  style={{
                    color: "rgba(0, 0, 0, 0.65)",
                    backgroundColor: "#ffffff",
                  }}
                  disabled={opcionVer === 5 || +mofxUsuarioBody?.permiso_id === 1}
                  value={reporta?.detalle_mof?.respuesta || ""}
                  onChange={(e) => handleUpdateDatosMof(8, e.target.value, "respuesta")}
                />
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div className="group">
              <div className="contenido" style={{ width: "100%" }}>
                <label
                  className="label"
                  style={{ fontSize: "small", top: "-2px", color: "black" }}
                >
                  Supervisa a :
                </label>
                <Input
                  size="small"
                  placeholder="Ingrese nombre"
                  type="text"
                  style={{
                    color: "rgba(0, 0, 0, 0.65)",
                    backgroundColor: "#ffffff",
                  }}
                  disabled={opcionVer === 5 || +mofxUsuarioBody?.permiso_id === 1}
                  value={supervisa?.detalle_mof?.respuesta || ""}
                  onChange={(e) => handleUpdateDatosMof(9, e.target.value, "respuesta")}
                />
              </div>
            </div>
          </div>
        </div>
        <div
          className="row col-md-6"
          style={{
            border: "1px solid #9dc5f6",
            padding: "10px",
            borderRadius: "5px",
            marginLeft: "2%",
            background: "#cddcf9",
          }}
        >
          <label
            className="label"
            style={{
              fontSize: "large",
              top: "-2px",
              color: "white",
              fontFamily: "-moz-initial",
              background: "#336ca5",
              marginBottom: "8px",
            }}
          >
            CARACTERÍSTICAS DE LA SUPERVISIÓN
          </label>
          <div className="col-md-6">
            <div className="group">
              <div className="contenido" style={{ width: "100%" }}>
                <label
                  className="label"
                  style={{ fontSize: "small", top: "-2px", color: "black" }}
                >
                  Supervisión que recibe :
                </label>
                <Select
                  mode={"single"}
                  maxTagCount={"responsive"}
                  className="select"
                  size={"small"}
                  placeholder={"Seleccionar supervision"}
                  optionFilterProp="children"
                  showSearch
                  popupClassName="my-drop-down"
                  value={supRecibeSel.desc1}
                  //value={obtenerSeleccion(10).desc1}
                  onChange={(value) => handleUpdateDatosMof(10, value, "respuesta_id")}
                  required={true}
                  disabled={opcionVer === 5 || +mofxUsuarioBody?.permiso_id === 1}
                  style={{ width: "100%", backgroundColor: "white" }}
                >
                  <Option value=""><em>Seleccionar</em></Option>
                  {datosMof.find((item) => item.id_preguntamof === 10)?.alternativas.map((opcion) => (
                    <Option key={`categoria-${opcion.corr3}`} value={opcion.corr3}>
                      {opcion.desc1}
                    </Option>
                  ))}
                </Select>
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div className="group">
              <div className="contenido" style={{ width: "100%" }}>
                <label
                  className="label"
                  style={{ fontSize: "small", top: "-2px", color: "black" }}
                >
                  Supervisión ejercida sobre trabajadores:
                </label>
                <Select
                  mode={"single"}
                  maxTagCount={"responsive"}
                  className="select"
                  size={"small"}
                  placeholder={"Seleccionar categoria"}
                  optionFilterProp="children"
                  showSearch
                  popupClassName="my-drop-down"
                  value={supEjercidaSel.desc1}
                  onChange={(value) => handleUpdateDatosMof(11, value, "respuesta_id")}
                  required={true}
                  disabled={opcionVer === 5 || +mofxUsuarioBody?.permiso_id === 1}
                  style={{ width: "100%", backgroundColor: "white" }}
                >
                  <Option value=""><em>Seleccionar</em></Option>
                  {datosMof.find((item) => item.id_preguntamof === 11)?.alternativas.map((opcion) => (
                    <Option key={`categoria-${opcion.corr3}`} value={opcion.corr3}>
                      {opcion.desc1}
                    </Option>
                  ))}
                </Select>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
  className="row"
  style={{
    border: "1px solid #9dc5f6",
    padding: "10px",
    borderRadius: "5px",
    marginLeft: "8px",
    marginRight: "8px",
    marginBottom: "16px",
    background: "#cddcf9",
  }}
>
  <label
    className="label"
    style={{
      fontSize: "large",
      top: "-2px",
      color: "white",
      fontFamily: "-moz-initial",
      background: "#336ca5",
      marginBottom: "8px",
    }}
  >
    RESPONSABILIDADES
  </label>
  {/* Usando col-md-6 para dos elementos por fila en pantallas medianas */}
  <div className="col-md-6 col-lg-3">
    <div className="group">
      <div className="contenido" style={{ width: "100%" }}>
        <label
          className="label"
          style={{ fontSize: "small", top: "-2px", color: "black" }}
        >
          En materiales, dinero y productos
        </label>
        <Select
          mode={"single"}
          maxTagCount={"responsive"}
          className="select"
          size={"small"}
          placeholder={"Seleccionar categoría"}
          optionFilterProp="children"
          showSearch
          popupClassName="my-drop-down"
          value={materiaSel.desc1}
          onChange={(value) =>
            handleUpdateDatosMof(12, value, "respuesta_id")
          }
          required={true}
          style={{ width: "100%", backgroundColor: "white" }}
          disabled={opcionVer === 5 || +mofxUsuarioBody?.permiso_id === 1}
        >
          <Option value="">
            <em>Seleccionar</em>
          </Option>
          {datosMof
            .find((item) => item.id_preguntamof === 12)
            ?.alternativas.map((opcion) => (
              <Option key={`categoria-${opcion.corr3}`} value={opcion.corr3}>
                {opcion.desc1}
              </Option>
            ))}
        </Select>
      </div>
    </div>
  </div>
  <div className="col-md-6 col-lg-3">
    <div className="group">
      <div className="contenido" style={{ width: "100%" }}>
        <label
          className="label"
          style={{ fontSize: "small", top: "-2px", color: "black" }}
        >
          Sobre equipos y herramientas
        </label>
        <Select
          mode={"single"}
          maxTagCount={"responsive"}
          className="select"
          size={"small"}
          placeholder={"Seleccionar categoría"}
          optionFilterProp="children"
          showSearch
          popupClassName="my-drop-down"
          value={equipoSel.desc1}
          onChange={(value) =>
            handleUpdateDatosMof(13, value, "respuesta_id")
          }
          required={true}
          disabled={opcionVer === 5 || +mofxUsuarioBody?.permiso_id === 1}
          style={{ width: "100%", backgroundColor: "white" }}
        >
          <Option value="">
            <em>Seleccionar</em>
          </Option>
          {datosMof
            .find((item) => item.id_preguntamof === 13)
            ?.alternativas.map((opcion) => (
              <Option key={`categoria-${opcion.corr3}`} value={opcion.corr3}>
                {opcion.desc1}
              </Option>
            ))}
        </Select>
      </div>
    </div>
  </div>
  <div className="col-md-6 col-lg-3">
    <div className="group">
      <div className="contenido" style={{ width: "100%" }}>
        <label
          className="label"
          style={{ fontSize: "small", top: "-2px", color: "black" }}
        >
          Confidencialidad de la información
        </label>
        <Select
          mode={"single"}
          maxTagCount={"responsive"}
          className="select"
          size={"small"}
          placeholder={"Seleccionar categoría"}
          optionFilterProp="children"
          showSearch
          popupClassName="my-drop-down"
          value={confidencialidadSel.desc1}
          onChange={(value) =>
            handleUpdateDatosMof(14, value, "respuesta_id")
          }
          required={true}
          disabled={opcionVer === 5 || +mofxUsuarioBody?.permiso_id === 1}
          style={{ width: "100%", backgroundColor: "white" }}
        >
          <Option value="">
            <em>Seleccionar</em>
          </Option>
          {datosMof
            .find((item) => item.id_preguntamof === 14)
            ?.alternativas.map((opcion) => (
              <Option key={`categoria-${opcion.corr3}`} value={opcion.corr3}>
                {opcion.desc1}
              </Option>
            ))}
        </Select>
      </div>
    </div>
  </div>
  <div className="col-md-6 col-lg-3">
    <div className="group">
      <div className="contenido" style={{ width: "100%" }}>
        <label
          className="label"
          style={{ fontSize: "small", top: "-2px", color: "black" }}
        >
          Sobre precisión en el trabajo
        </label>
        <Select
          mode={"single"}
          maxTagCount={"responsive"}
          className="select"
          size={"small"}
          placeholder={"Seleccionar categoría"}
          optionFilterProp="children"
          showSearch
          popupClassName="my-drop-down"
          value={precisionSel.desc1}
          onChange={(value) =>
            handleUpdateDatosMof(15, value, "respuesta_id")
          }
          required={true}
          disabled={opcionVer === 5 || +mofxUsuarioBody?.permiso_id === 1}
          style={{ width: "100%", backgroundColor: "white" }}
        >
          <Option value="">
            <em>Seleccionar</em>
          </Option>
          {datosMof
            .find((item) => item.id_preguntamof === 15)
            ?.alternativas.map((opcion) => (
              <Option key={`categoria-${opcion.corr3}`} value={opcion.corr3}>
                {opcion.desc1}
              </Option>
            ))}
        </Select>
      </div>
    </div>
  </div>
</div>

      <div className="row" style={{ margin: "6px", padding: "10px" }}>
        <div
          className="row col-md-6"
          style={{
            border: "1px solid #9dc5f6",
            padding: "10px",
            borderRadius: "5px",
            background: "#cddcf9",
          }}
        >

          <div className="col-md-12">
            <div className="group" style={{ paddingTop: "0px" }}>
              <label
                className="label"
                style={{
                  fontSize: "large",
                  color: "black",
                  textAlign: "left",
                  fontFamily: "-moz-initial",
                }}
              >
                RELACION
              </label>
              <div className="contenido" style={{ width: "100%" }}>
                <label
                  className="label"
                  style={{ fontSize: "small", top: "-2px", color: "black" }}
                >
                  Interna
                </label>
                <Input
                  size="small"
                  placeholder="Ingrese nombre"
                  type="text"
                  style={{
                    color: "rgba(0, 0, 0, 0.65)",
                    backgroundColor: "#ffffff",
                  }}
                  value={relacionInterna?.detalle_mof?.respuesta || ""}
                  onChange={(e) => handleUpdateDatosMof(17, e.target.value, "respuesta")}
                  disabled={opcionVer === 5 || +mofxUsuarioBody?.permiso_id === 1}
                />
              </div>
            </div>
          </div>
          <div className="col-md-12">
            <div className="group">
              <div className="contenido" style={{ width: "100%" }}>
                <label
                  className="label"
                  style={{ fontSize: "small", top: "-2px", color: "black" }}
                >
                  Clientes
                </label>
                <Input
                  size="small"
                  placeholder="Ingrese relación externa"
                  type="text"
                  style={{
                    color: "rgba(0, 0, 0, 0.65)",
                    backgroundColor: "#ffffff",
                  }}
                  disabled={opcionVer === 5 || +mofxUsuarioBody?.permiso_id === 1}
                  value={relacionCliente?.detalle_mof?.respuesta || ""}
                  onChange={(e) => handleUpdateDatosMof(123, e.target.value, "respuesta")}
                />
              </div>
            </div>
          </div>
          <div className="col-md-12">
            <div className="group">
              <div className="contenido" style={{ width: "100%" }}>
                <label
                  className="label"
                  style={{ fontSize: "small", top: "-2px", color: "black" }}
                >
                  Proveedores
                </label>
                <Input
                  size="small"
                  placeholder="Ingrese relación externa"
                  type="text"
                  style={{
                    color: "rgba(0, 0, 0, 0.65)",
                    backgroundColor: "#ffffff",
                  }}
                  disabled={opcionVer === 5 || +mofxUsuarioBody?.permiso_id === 1}
                  value={relacionProveedor?.detalle_mof?.respuesta || ""}
                  onChange={(e) => handleUpdateDatosMof(124, e.target.value, "respuesta")}
                />
              </div>
            </div>
          </div>
        </div>

        <div
          className="row col-md-6"
          style={{
            border: "1px solid #9dc5f6",
            padding: "10px",
            borderRadius: "5px",
            marginLeft: "2%",
            background: "#cddcf9",
          }}
        >
          <label
            className="label"
            style={{
              fontSize: "large",
              color: "black",
              textAlign: "left",
              fontFamily: "-moz-initial",
            }}
          >
            FUNCIONES
          </label>
          <div className="col-md-12">
            <div className="group">
              <div className="contenido" style={{ width: "100%" }}>
                <label
                  className="label"
                  style={{ fontSize: "small", top: "-2px", color: "black" }}
                >
                  Funciones Generales
                </label>
                <Input
                  size="small"
                  placeholder="Ingrese función general"
                  type="text"
                  style={{
                    color: "rgba(0, 0, 0, 0.65)",
                    backgroundColor: "#ffffff",
                  }}
                  disabled={opcionVer === 5 || +mofxUsuarioBody?.permiso_id === 1}
                  value={general?.detalle_mof?.respuesta || ""}
                  onChange={(e) => handleUpdateDatosMof(18, e.target.value, "respuesta")}
                />
              </div>
            </div>
          </div>
          <div className="col-md-12">
            <div className="col-md-12">
              <div className="group">
                <label
                  className="label"
                  style={{
                    fontSize: "large",
                    color: "black",
                    textAlign: "left",
                    fontFamily: "-moz-initial",
                  }}
                >
                  ESPECIFICAS
                </label>
                <div className="contenido" style={{ width: "100%" }}>
                  <label
                    className="label"
                    style={{ fontSize: "small", top: "-2px", color: "black" }}
                  >
                    Diarias
                  </label>
                  <Input
                    size="small"
                    placeholder="Ingrese función diaria"
                    type="text"
                    style={{
                      color: "rgba(0, 0, 0, 0.65)",
                      backgroundColor: "#ffffff",
                    }}
                    disabled={opcionVer === 5 || +mofxUsuarioBody?.permiso_id === 1}
                    value={diaria?.detalle_mof?.respuesta || ""}
                    onChange={(e) => handleUpdateDatosMof(19, e.target.value, "respuesta")}
                  />
                </div>
              </div>
            </div>
            <div className="col-md-12">
              <div className="group">
                <div className="contenido" style={{ width: "100%" }}>
                  <label
                    className="label"
                    style={{ fontSize: "small", top: "-2px", color: "black" }}
                  >
                    Periodicas
                  </label>
                  <Input
                    size="small"
                    placeholder="Ingrese función periódica"
                    type="text"
                    style={{
                      color: "rgba(0, 0, 0, 0.65)",
                      backgroundColor: "#ffffff",
                    }}
                    disabled={opcionVer === 5 || +mofxUsuarioBody?.permiso_id === 1}
                    value={periodica?.detalle_mof?.respuesta || ""}
                    onChange={(e) => handleUpdateDatosMof(20, e.target.value, "respuesta")}
                  />
                </div>
              </div>
            </div>
            <div className="col-md-12">
              <div className="group">
                <div className="contenido" style={{ width: "100%" }}>
                  <label
                    className="label"
                    style={{ fontSize: "small", top: "-2px", color: "black" }}
                  >
                    Eventuales
                  </label>
                  <Input
                    size="small"
                    placeholder="Ingrese función eventual"
                    type="text"
                    style={{
                      color: "rgba(0, 0, 0, 0.65)",
                      backgroundColor: "#ffffff",
                    }}
                    disabled={opcionVer === 5 || +mofxUsuarioBody?.permiso_id === 1}
                    value={eventual?.detalle_mof?.respuesta || ""}
                    onChange={(e) => handleUpdateDatosMof(21, e.target.value, "respuesta")}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="row"
        style={{
          border: "1px solid #9dc5f6",
          padding: "10px",
          borderRadius: "5px",
          marginLeft: "8px",
          marginRight: "8px",
          marginBottom: "16px",
          marginTop: "16px",
          background: "#cddcf9",
        }}
      >
        <label
          className="label"
          style={{
            fontSize: "large",
            top: "-2px",
            color: "white",
            fontFamily: "-moz-initial",
            background: "#336ca5",
            marginBottom: "8px",
          }}
        >
          PERFIL DEL PUESTO
        </label>
        <div className="col-md-3">
          <div className="group">
            <div className="contenido" style={{ width: "100%" }}>
              <label
                className="label"
                style={{ fontSize: "small", top: "-2px", color: "black" }}
              >
                Formación
              </label>
              <Select
                mode={"single"}
                maxTagCount={"responsive"}
                className="select"
                size={"small"}
                placeholder={"Seleccionar formacion"}
                optionFilterProp="children"
                showSearch
                popupClassName="my-drop-down"
                value={formacionSel.desc1}
                onChange={(value) => handleUpdateDatosMof(22, value, "respuesta_id")}
                required={true}
                disabled={opcionVer === 5 || +mofxUsuarioBody?.permiso_id === 1}
                style={{ width: "100%", backgroundColor: "white" }}
              >
                <Option value=""><em>Seleccionar</em></Option>
                {datosMof.find((item) => item.id_preguntamof === 22)?.alternativas.map((opcion) => (
                  <Option key={`categoria-${opcion.corr3}`} value={opcion.corr3}>
                    {opcion.desc1}
                  </Option>
                ))}
              </Select>
            </div>
          </div>
        </div>
        <div className="col-md-3">
          <div className="group">
            <div className="contenido" style={{ width: "100%" }}>
              <label
                className="label"
                style={{ fontSize: "small", top: "-2px", color: "black" }}
              >
                Especialidad
              </label>
              <Input
                size="small"
                placeholder="Ingrese especialidad"
                type="text"
                style={{
                  color: "rgba(0, 0, 0, 0.65)",
                  backgroundColor: "#ffffff",
                }}
                disabled={opcionVer === 5 || +mofxUsuarioBody?.permiso_id === 1}
                value={especialidad?.detalle_mof?.respuesta || ""}
                onChange={(e) => handleUpdateDatosMof(23, e.target.value, "respuesta")}
              />
            </div>
          </div>
        </div>
        <div className="col-md-3">
          <div className="group">
            <div className="contenido" style={{ width: "100%" }}>
              <label
                className="label"
                style={{ fontSize: "small", top: "-2px", color: "black" }}
              >
                Conocimientos/Habilidades 01
              </label>
              <Select
                mode={"single"}
                maxTagCount={"responsive"}
                className="select"
                size={"small"}
                placeholder={"Seleccionar categoria"}
                optionFilterProp="children"
                showSearch
                popupClassName="my-drop-down"
                value={conocimientoSel01.desc1}
                onChange={(value) => handleUpdateDatosMof(127, value, "respuesta_id")}
                required={true}
                style={{ width: "100%", backgroundColor: "white" }} disabled={opcionVer === 5 || +mofxUsuarioBody?.permiso_id === 1}
              >
                <Option value=""><em>Seleccionar</em></Option>
                {datosMof.find((item) => item.id_preguntamof === 127)?.alternativas.map((opcion) => (
                  <Option key={`categoria-${opcion.corr3}`} value={opcion.corr3}>
                    {opcion.desc1}
                  </Option>
                ))}
              </Select>
            </div>
          </div>
        </div>
        <div className="col-md-3">
          <div className="group">
            <div className="contenido" style={{ width: "100%" }}>
              <label
                className="label"
                style={{ fontSize: "small", top: "-2px", color: "black" }}
              >
                Conocimientos/Habilidades 02
              </label>
              <Select
                mode={"single"}
                maxTagCount={"responsive"}
                className="select"
                size={"small"}
                placeholder={"Seleccionar categoria"}
                optionFilterProp="children"
                showSearch
                popupClassName="my-drop-down"
                value={conocimientoSel02.desc1}
                onChange={(value) => handleUpdateDatosMof(128, value, "respuesta_id")}
                required={true}
                style={{ width: "100%", backgroundColor: "white" }} disabled={opcionVer === 5 || +mofxUsuarioBody?.permiso_id === 1}
              >
                <Option value=""><em>Seleccionar</em></Option>
                {datosMof.find((item) => item.id_preguntamof === 128)?.alternativas.map((opcion) => (
                  <Option key={`categoria-${opcion.corr3}`} value={opcion.corr3}>
                    {opcion.desc1}
                  </Option>
                ))}
              </Select>
            </div>
          </div>
        </div>
        <div className="col-md-3">
          <div className="group">
            <div className="contenido" style={{ width: "100%" }}>
              <label
                className="label"
                style={{ fontSize: "small", top: "-2px", color: "black" }}
              >
                Conocimientos/Habilidades 03
              </label>
              <Select
                mode={"single"}
                maxTagCount={"responsive"}
                className="select"
                size={"small"}
                placeholder={"Seleccionar categoria"}
                optionFilterProp="children"
                showSearch
                popupClassName="my-drop-down"
                value={conocimientoSel03.desc1}
                onChange={(value) => handleUpdateDatosMof(129, value, "respuesta_id")}
                required={true}
                style={{ width: "100%", backgroundColor: "white" }} disabled={opcionVer === 5 || +mofxUsuarioBody?.permiso_id === 1}
              >
                <Option value=""><em>Seleccionar</em></Option>
                {datosMof.find((item) => item.id_preguntamof === 129)?.alternativas.map((opcion) => (
                  <Option key={`categoria-${opcion.corr3}`} value={opcion.corr3}>
                    {opcion.desc1}
                  </Option>
                ))}
              </Select>
            </div>
          </div>
        </div>
        <div className="col-md-3">
          <div className="group">
            <div className="contenido" style={{ width: "100%" }}>
              <label
                className="label"
                style={{ fontSize: "small", top: "-2px", color: "black" }}
              >
                Conocimientos/Habilidades 04
              </label>
              <Select
                mode={"single"}
                maxTagCount={"responsive"}
                className="select"
                size={"small"}
                placeholder={"Seleccionar categoria"}
                optionFilterProp="children"
                showSearch
                popupClassName="my-drop-down"
                value={conocimientoSel04.desc1}
                onChange={(value) => handleUpdateDatosMof(130, value, "respuesta_id")}
                required={true}
                style={{ width: "100%", backgroundColor: "white" }} disabled={opcionVer === 5 || +mofxUsuarioBody?.permiso_id === 1}
              >
                <Option value=""><em>Seleccionar</em></Option>
                {datosMof.find((item) => item.id_preguntamof === 130)?.alternativas.map((opcion) => (
                  <Option key={`categoria-${opcion.corr3}`} value={opcion.corr3}>
                    {opcion.desc1}
                  </Option>
                ))}
              </Select>
            </div>
          </div>
        </div>
        <div className="col-md-6">
          <div className="group">
            <div className="contenido" style={{ width: "100%" }}>
              <label
                className="label"
                style={{ fontSize: "small", top: "-2px", color: "black" }}
              >
                Conocimientos/Habilidades
              </label>
              <Input
                size="small"
                placeholder="Ingrese Conocimientos/Habilidades"
                type="text"
                style={{
                  color: "rgba(0, 0, 0, 0.65)",
                  backgroundColor: "#ffffff",
                }}
                disabled={opcionVer === 5 || +mofxUsuarioBody?.permiso_id === 1}
                value={conocimiento?.detalle_mof?.respuesta || ""}
                onChange={(e) => handleUpdateDatosMof(24, e.target.value, "respuesta")}
              />
            </div>
          </div>
        </div>
        <div className="col-md-3">
          <div className="group">
            <div className="contenido" style={{ width: "100%" }}>
              <label
                className="label"
                style={{ fontSize: "small", top: "-2px", color: "black" }}
              >
                Experiencia Laboral relacionada al puesto
              </label>
              <Select
                mode={"single"}
                maxTagCount={"responsive"}
                className="select"
                size={"small"}
                placeholder={"Seleccionar categoria"}
                optionFilterProp="children"
                showSearch
                popupClassName="my-drop-down"
                value={experienciaSel.desc1}
                onChange={(value) => handleUpdateDatosMof(25, value, "respuesta_id")}
                required={true}
                style={{ width: "100%", backgroundColor: "white" }} disabled={opcionVer === 5 || +mofxUsuarioBody?.permiso_id === 1}
              >
                <Option value=""><em>Seleccionar</em></Option>
                {datosMof.find((item) => item.id_preguntamof === 25)?.alternativas.map((opcion) => (
                  <Option key={`categoria-${opcion.corr3}`} value={opcion.corr3}>
                    {opcion.desc1}
                  </Option>
                ))}
              </Select>
            </div>
          </div>
        </div>
        <div className="col-md-3">
          <div className="group">
            <div className="contenido" style={{ width: "100%" }}>
              <label
                className="label"
                style={{ fontSize: "small", top: "-2px", color: "black" }}
              >
                Experiencia Laboral relacionada al puesto
              </label>
              <Input
                size="small"
                placeholder="Ingrese Experiencia"
                type="text"
                style={{
                  color: "rgba(0, 0, 0, 0.65)",
                  backgroundColor: "#ffffff",
                }}
                disabled={opcionVer === 5 || +mofxUsuarioBody?.permiso_id === 1}
                value={experiencia?.detalle_mof?.respuesta || ""}
                onChange={(e) => handleUpdateDatosMof(131, e.target.value, "respuesta")}
              />
            </div>
          </div>
        </div>
        <div className="col-md-3">
          <div className="group">
            <div className="contenido" style={{ width: "100%" }}>
              <label
                className="label"
                style={{ fontSize: "small", top: "-2px", color: "black" }}
              >
                Curva de aprendizaje
              </label>
              <Select
                mode={"single"}
                maxTagCount={"responsive"}
                className="select"
                size={"small"}
                placeholder={"Seleccionar categoria"}
                optionFilterProp="children"
                showSearch
                popupClassName="my-drop-down"
                value={curvaSel.desc1}
                onChange={(value) => handleUpdateDatosMof(26, value, "respuesta_id")}
                required={true}
                style={{ width: "100%", backgroundColor: "white" }} disabled={opcionVer === 5 || +mofxUsuarioBody?.permiso_id === 1}
              >
                <Option value=""><em>Seleccionar</em></Option>
                {datosMof.find((item) => item.id_preguntamof === 26)?.alternativas.map((opcion) => (
                  <Option key={`categoria-${opcion.corr3}`} value={opcion.corr3}>
                    {opcion.desc1}
                  </Option>
                ))}
              </Select>
            </div>
          </div>
        </div>
        <div className="col-md-6">
          <div className="group">
            <div className="contenido" style={{ width: "100%" }}>
              <label
                className="label"
                style={{ fontSize: "small", top: "-2px", color: "black" }}
              >
                Máquinas, equipos y/o programas que opera
              </label>
              <Input
                size="small"
                placeholder="Ingrese datos"
                type="text"
                style={{
                  color: "rgba(0, 0, 0, 0.65)",
                  backgroundColor: "#ffffff",
                }}
                disabled={opcionVer === 5 || +mofxUsuarioBody?.permiso_id === 1}
                value={maquina?.detalle_mof?.respuesta || ""}
                onChange={(e) => handleUpdateDatosMof(27, e.target.value, "respuesta")}
              />
            </div>
          </div>
        </div>
      </div>
      <div
        className="row"
        style={{
          border: "1px solid #9dc5f6",
          padding: "10px",
          borderRadius: "5px",
          marginLeft: "8px",
          marginRight: "8px",
          marginBottom: "16px",
          marginTop: "16px",
          background: "#cddcf9",
        }}
      >
        <label
          className="label"
          style={{
            fontSize: "large",
            top: "-2px",
            color: "white",
            fontFamily: "-moz-initial",
            background: "#336ca5",
            marginBottom: "8px",
          }}
        >
          COMPETENCIAS GENÉRICAS
        </label>
        <div className="col-md-3">
          <div className="group">
            <div className="contenido" style={{ width: "100%" }}>
              <label
                className="label"
                style={{ fontSize: "small", top: "-2px", color: "black" }}
              >
                CG 01
              </label>
              <Input
                size="small"
                placeholder="Ingrese CG 01"
                type="text"
                style={{
                  color: "rgba(0, 0, 0, 0.65)",
                  backgroundColor: "#ffffff",
                }}
                disabled={opcionVer === 5 || +mofxUsuarioBody?.permiso_id === 1 || cg[1]}
                value={cg[1]}
              // onChange={(e) => handleInputChange(1, e.target.value, "cg")}
              />
            </div>
          </div>
        </div>
        <div className="col-md-3">
          <div className="group">
            <div className="contenido" style={{ width: "100%" }}>
              <label
                className="label"
                style={{ fontSize: "small", top: "-2px", color: "black" }}
              >
                CG 02
              </label>
              <Input
                size="small"
                placeholder="Ingrese CG 02"
                type="text"
                style={{
                  color: "rgba(0, 0, 0, 0.65)",
                  backgroundColor: "#ffffff",
                }}
                disabled={opcionVer === 5 || +mofxUsuarioBody?.permiso_id === 1 || cg[2]}
                value={cg[2]}
              //onChange={(e) => handleInputChange(2, e.target.value,"cg")}
              />
            </div>
          </div>
        </div>
        <div className="col-md-3">
          <div className="group">
            <div className="contenido" style={{ width: "100%" }}>
              <label
                className="label"
                style={{ fontSize: "small", top: "-2px", color: "black" }}
              >
                CG 03
              </label>
              <Input
                size="small"
                placeholder="Ingrese CG 03"
                type="text"
                style={{
                  color: "rgba(0, 0, 0, 0.65)",
                  backgroundColor: "#ffffff",
                }}
                disabled={opcionVer === 5 || +mofxUsuarioBody?.permiso_id === 1 || cg[3]}
                value={cg[3]}
              //onChange={(e) => handleInputChange(3, e.target.value,"cg")}
              />
            </div>
          </div>
        </div>
        <div className="col-md-3">
          <div className="group">
            <div className="contenido" style={{ width: "100%" }}>
              <label
                className="label"
                style={{ fontSize: "small", top: "-2px", color: "black" }}
              >
                CG 04
              </label>
              <Input
                size="small"
                placeholder="Ingrese CG 04"
                type="text"
                style={{
                  color: "rgba(0, 0, 0, 0.65)",
                  backgroundColor: "#ffffff",
                }}
                disabled={opcionVer === 5 || +mofxUsuarioBody?.permiso_id === 1 || cg[4]}
                value={cg[4]}
              //onChange={(e) => handleInputChange(4, e.target.value,"cg")}
              />
            </div>
          </div>
        </div>

      </div>
      <div
        className="row"
        style={{
          border: "1px solid #9dc5f6",
          padding: "10px",
          borderRadius: "5px",
          marginLeft: "8px",
          marginRight: "8px",
          marginBottom: "16px",
          marginTop: "16px",
          background: "#cddcf9",
        }}
      >
        <label
          className="label"
          style={{
            fontSize: "large",
            top: "-2px",
            color: "white",
            fontFamily: "-moz-initial",
            background: "#336ca5",
            marginBottom: "8px",
          }}
        >
          COMPETENCIAS ESPECÍFICAS
        </label>
        <div className="col-md-3">
          <div className="group">
            <div className="contenido" style={{ width: "100%" }}>
              <label
                className="label"
                style={{ fontSize: "small", top: "-2px", color: "black" }}
              >
                CE 01
              </label>
              <Input
                size="small"
                placeholder="Ingrese CE 01"
                type="text"
                style={{
                  color: "rgba(0, 0, 0, 0.65)",
                  backgroundColor: "#ffffff",
                }}
                disabled={opcionVer === 5 || +mofxUsuarioBody?.permiso_id === 1}
                value={ce[1]}
                onChange={(e) => handleInputChange(1, e.target.value, "ce")}
              />
            </div>
          </div>
        </div>
        <div className="col-md-3">
          <div className="group">
            <div className="contenido" style={{ width: "100%" }}>
              <label
                className="label"
                style={{ fontSize: "small", top: "-2px", color: "black" }}
              >
                CE 02
              </label>
              <Input
                size="small"
                placeholder="Ingrese CE 02"
                type="text"
                style={{
                  color: "rgba(0, 0, 0, 0.65)",
                  backgroundColor: "#ffffff",
                }}
                disabled={opcionVer === 5 || +mofxUsuarioBody?.permiso_id === 1 || !ce[1]}
                value={ce[2]}
                onChange={(e) => handleInputChange(2, e.target.value, "ce")}
              />
            </div>
          </div>
        </div>
        <div className="col-md-3">
          <div className="group">
            <div className="contenido" style={{ width: "100%" }}>
              <label
                className="label"
                style={{ fontSize: "small", top: "-2px", color: "black" }}
              >
                CE 03
              </label>
              <Input
                size="small"
                placeholder="Ingrese CE 03"
                type="text"
                style={{
                  color: "rgba(0, 0, 0, 0.65)",
                  backgroundColor: "#ffffff",
                }}
                disabled={opcionVer === 5 || +mofxUsuarioBody?.permiso_id === 1 || !ce[2]}
                value={ce[3]}
                onChange={(e) => handleInputChange(3, e.target.value, "ce")}
              />
            </div>
          </div>
        </div>
        <div className="col-md-3">
          <div className="group">
            <div className="contenido" style={{ width: "100%" }}>
              <label
                className="label"
                style={{ fontSize: "small", top: "-2px", color: "black" }}
              >
                CE 04
              </label>
              <Input
                size="small"
                placeholder="Ingrese CE 04"
                type="text"
                style={{
                  color: "rgba(0, 0, 0, 0.65)",
                  backgroundColor: "#ffffff",
                }}
                disabled={opcionVer === 5 || +mofxUsuarioBody?.permiso_id === 1 || !ce[3]}
                value={ce[4]}
                onChange={(e) => handleInputChange(4, e.target.value, "ce")}
              />
            </div>
          </div>
        </div>
        <div className="col-md-3">
          <div className="group">
            <div className="contenido" style={{ width: "100%" }}>
              <label
                className="label"
                style={{ fontSize: "small", top: "-2px", color: "black" }}
              >
                CE 05
              </label>
              <Input
                size="small"
                placeholder="Ingrese CE 05"
                type="text"
                style={{
                  color: "rgba(0, 0, 0, 0.65)",
                  backgroundColor: "#ffffff",
                }}
                disabled={opcionVer === 5 || +mofxUsuarioBody?.permiso_id === 1 || !ce[4]}
                value={ce[5]}
                onChange={(e) => handleInputChange(5, e.target.value, "ce")}
              />
            </div>
          </div>
        </div>
      </div>
      <Box sx={{ width: "100%", bgcolor: "background.paper" }}>
        <Tabs value={body} onChange={handleChangeBody} centered>
          {/*<Tab label="Aptitudes" />
          <Tab label=" Competencias laborales" />*/}
          <Tab label="Ambientales" />
          <Tab label="Riesgo" />
          <Tab label="Exigencias Sensomotrices" />
          <Tab label="Movimientos del puesto" />
        </Tabs>
      </Box>
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
        mt={2}
      >
        <Grid item xs={12} sm={10} md={10}>
          {/*body === 0 && (
            <>
              <BodyAptitudes
                datosMof={datosMof} setDatosMof={setDatosMof}
                handleUpdateDatosMof={handleUpdateDatosMof}
                opcionVer={opcionVer} mofxUsuarioBody={mofxUsuarioBody}
              />
            </>
          )}
          {body === 1 && (
            <>
              <BodyCompetencias
                datosMof={datosMof} setDatosMof={setDatosMof}
                handleUpdateDatosMof={handleUpdateDatosMof}
                opcionVer={opcionVer} mofxUsuarioBody={mofxUsuarioBody}
              />
            </>
          )*/}
          {body === 0 && (
            <>
              <BodyAmbientales
                datosMof={datosMof} setDatosMof={setDatosMof}
                handleUpdateDatosMof={handleUpdateDatosMof}
                opcionVer={opcionVer} mofxUsuarioBody={mofxUsuarioBody} radioValue={radioValue}

              />
            </>
          )}
          {body === 1 && (
            <>
              <BodyRiesgo
                datosMof={datosMof} setDatosMof={setDatosMof}
                handleUpdateDatosMof={handleUpdateDatosMof}
                opcionVer={opcionVer} mofxUsuarioBody={mofxUsuarioBody} radioValue={radioValue}
              />
            </>
          )}
          {body === 2 && (
            <>
              <BodyExigencias
                datosMof={datosMof} setDatosMof={setDatosMof}
                handleUpdateDatosMof={handleUpdateDatosMof}
                opcionVer={opcionVer} mofxUsuarioBody={mofxUsuarioBody} radioValue={radioValue}
              />
            </>
          )}
          {body === 3 && (
            <>
              <BodyMovimientos
                datosMof={datosMof} setDatosMof={setDatosMof}
                handleUpdateDatosMof={handleUpdateDatosMof}
                opcionVer={opcionVer} mofxUsuarioBody={mofxUsuarioBody} radioValue={radioValue}
              />
            </>
          )}
        </Grid>
      </Grid>

      <div
        className="row"
        style={{
          border: "1px solid #9dc5f6",
          padding: "10px",
          borderRadius: "5px",
          marginLeft: "8px",
          marginRight: "8px",
          marginBottom: "16px",
          marginTop: "16px",
          background: "#cddcf9",
        }}
      >
        <div className="col-md-6">
          <div className="group">
            <div className="contenido" style={{ width: "100%" }}>
              <label
                className="label"
                style={{ fontSize: "small", top: "-2px", color: "black" }}
              >
                Esfuerzo Visual
              </label>
              <Select
                mode={"single"}
                maxTagCount={"responsive"}
                className="select"
                size={"small"}
                placeholder={"Seleccionar categoria"}
                optionFilterProp="children"
                showSearch
                popupClassName="my-drop-down"
                value={esfuerzoVisualSel.desc1}
                onChange={(value) => handleUpdateDatosMof(121, value, "respuesta_id")}
                required={true}
                style={{ width: "100%", backgroundColor: "white" }} disabled={opcionVer === 5 || +mofxUsuarioBody?.permiso_id === 1}
              >
                <Option value=""><em>Seleccionar</em></Option>
                {datosMof.find((item) => item.id_preguntamof === 121)?.alternativas.map((opcion) => (
                  <Option key={`categoria-${opcion.corr3}`} value={opcion.corr3}>
                    {opcion.desc1}
                  </Option>
                ))}
              </Select>
            </div>
          </div>
        </div>
        <div className="col-md-6">
          <div className="group">
            <div className="contenido" style={{ width: "100%" }}>
              <label
                className="label"
                style={{ fontSize: "small", top: "-2px", color: "black" }}
              >
                Esfuerzo Fisico
              </label>
              <Select
                mode={"single"}
                maxTagCount={"responsive"}
                className="select"
                size={"small"}
                placeholder={"Seleccionar categoria"}
                optionFilterProp="children"
                showSearch
                popupClassName="my-drop-down"
                value={esfuerzoFisicoSel.desc1}
                onChange={(value) => handleUpdateDatosMof(122, value, "respuesta_id")}
                required={true}
                style={{ width: "100%", backgroundColor: "white" }} disabled={opcionVer === 5 || +mofxUsuarioBody?.permiso_id === 1}
              >
                <Option value=""><em>Seleccionar</em></Option>
                {datosMof.find((item) => item.id_preguntamof === 122)?.alternativas.map((opcion) => (
                  <Option key={`categoria-${opcion.corr3}`} value={opcion.corr3}>
                    {opcion.desc1}
                  </Option>
                ))}
              </Select>
            </div>
          </div>
        </div>
      </div>
      <div
        className="row"
        style={{

          marginLeft: "0px",
          marginRight: "8px",
          marginBottom: "16px",

        }}
      >

        <div className="row">
          {([23,1].includes(idPerfil) && !aprobadoPor?.detalle_mof?.respuesta) ? (
            <div className="col-md-1">
              <div className="group">
                <div className="contenido" style={{ width: "100%" }}>
                  <button
                    type="button"
                    onClick={handleAprobar}
                    className="btn btn-warning btn-sm"
                    style={{ fontSize: "15px" }}
                  >
                    Aprobar
                  </button>
                </div>
              </div>
            </div>
          ) : null}

          <div className={(idPerfil !== 16 && idPerfil !== 1) || aprobadoPor?.detalle_mof?.respuesta ? "col-md-2" : "col-md-1"}>
            <div className="group">
              <div className="contenido" style={{ width: "100%" }}>
                {(+valorEstado === 5) && !recepcionadoPor?.detalle_mof?.respuesta ? (
                  <button
                    type="button"
                    onClick={handleRecepcionar}
                    className="btn btn-info btn-sm"
                    style={{ fontSize: "15px" }}
                  >
                    Recepcionar
                  </button>
                ) : null}
              </div>
            </div>
          </div>
        </div>

      </div>

    </>
  );
};

export default AddRegisterMofsV2;
