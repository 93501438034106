import React, { useState } from 'react';
import './clock.scss'
import { Box, Typography } from '@mui/material';
import ClockA from './ClockA'

const Clock = () => {

    const [factHours, setFactHours] = useState('');
    const [factMinutes, setFactMinutes] = useState(null);
    const [factSeconds, setFactSeconds] = useState(null);
    const [factDayWeek, setFactDayWeek] = useState(null);
    const [factDay, setFactDay] = useState(null);
    const [factMonth, setFactMonth] = useState(null);
    const [factYear, setFactYear] = useState(null);
    const [AMPM, setAMPM] = useState(null);

    const actualizarHora = () => {
        var fecha = new Date(),
            hours = fecha.getHours(),
            ampm,
            minutes = fecha.getMinutes(),
            seconds = fecha.getSeconds(),
            dayWeey = fecha.getDay(),
            day = fecha.getDate(),
            month = fecha.getMonth(),
            year = fecha.getFullYear();

        /**fecha completa */
        var week = ['Domingo', 'Lunes', 'Martes', 'Miercoles', 'Jueves', 'Viernes', 'Sabado'];
        setFactDayWeek(week[dayWeey]);

        setFactDay(day);

        var months = ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'];
        setFactMonth(months[month]);

        setFactYear(year);

        /**calcular la hora */
        if (hours >= 12) {
            hours = hours - 12;
            ampm = 'PM';
        } else {
            ampm = 'AM';
        }

        if (hours === 0) {
            hours = 12;
        }

        setFactHours(hours);
        setAMPM(ampm);
        setFactMinutes(minutes);
        setFactSeconds(seconds);

    }
    setTimeout(actualizarHora, 1000);


    return (
        <Box className='wrap' mb={2}>
            <Box className='widget'
                sx={{
                    width: {
                        lg: '23.5rem', //'26%',
                        md: '22rem', //'25%', //'46%',
                        sm: '20.5rem', //'45%', //'66%',
                        xs: '20rem',//'75%', //'86%',
                    },
                }}
            >
                <Box className='fecha'
                    sx={{
                        fontSize: {
                            lg: '1.2rem',
                            md: '1.1rem',
                            sm: '1rem',
                            xs: '0.9rem'
                        },
                    }}
                >
                    <p className='diaSemana'>{factDayWeek || ''}</p>&nbsp;
                    <p className='dia'>{factDay || ''}</p>&nbsp;
                    <p>de</p>&nbsp;
                    <p className='mes'>{factMonth || ''}</p>&nbsp;
                    <p>del</p>&nbsp;
                    <p className='year'>{factYear || ''}</p>
                </Box>

                <ClockA />

                <Box className='reloj'
                    sx={{
                        fontSize: {
                            lg: '2.1rem',
                            md: '2rem',
                            sm: '1.9rem',
                            xs: '1.8rem'
                        },
                    }}
                >
                    <p className='horas'>{factHours || ''}</p>&nbsp;
                    <p>:</p>&nbsp;
                    <p className='minutos'>{factMinutes < 10 ? '0' + (factMinutes || '0') : factMinutes || ''}</p>&nbsp;
                    <p>:</p>&nbsp;
                    <Box className='caja-segundos'>
                        {/* <p className='ampm'>{AMPM}</p>
                        <p className='segundos'>{factSeconds < 10 ? '0' + factSeconds : factSeconds}</p> */}
                        <Typography variant="body1" className='ampm'>{AMPM}</Typography>
                        <Typography variant="body1" className='segundos'>
                            {factSeconds < 10 ? '0' + (factSeconds || '0') : factSeconds || ''}
                        </Typography>
                    </Box>
                </Box>
            </Box>
        </Box>
    );
};

export default Clock;