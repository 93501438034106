import React, { useState } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';

const columns = [
  { id: 'desMarca', label: 'Concepto', minWidth: 120, },
  { id: 'hora', label: 'Hora', minWidth: 100, align: 'left', },
  { id: 'estadoMarca', label: 'Estado', minWidth: 70, align: 'left', },
];

function createData(Concepto, Hora, Estado) {
  return { Concepto, Hora, Estado };
}

const rows = [
  createData('Ingreso', '09:00:00', 'Tardanza'),
  createData('Ingreso', '10:15:00', 'Tardanza'),
  createData('Ingreso', '09:50:00', 'Tardanza'),
  createData('Ingreso', '09:00:00', 'Tardanza'),
  createData('Ingreso', '11:30:00', 'Tardanza'),
  createData('Ingreso', '09:00:00', 'Tardanza'),
  createData('Ingreso', '10:15:00', 'Tardanza'),
  createData('Ingreso', '09:50:00', 'Tardanza'),
  createData('Ingreso', '09:00:00', 'Tardanza'),
  createData('Ingreso', '11:30:00', 'Tardanza'),
  createData('Ingreso', '09:00:00', 'Tardanza'),
  createData('Ingreso', '10:15:00', 'Tardanza'),
  createData('Ingreso', '09:50:00', 'Tardanza'),
  createData('Ingreso', '09:00:00', 'Tardanza'),
  createData('Ingreso', '11:30:00', 'Tardanza')
];

const BodyAssistance = (props) => {
  const { marks } = props;
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <div className='page-body-main'>
      <div className='page-body__table' style={{ overflow: 'hidden' }} >
        <div className='page-body-table'>
          <TableContainer className='table-height'>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  {columns.map((column) => (
                    <TableCell
                      key={column.id}
                      align={column.align}
                      width={column.minWidth}
                    >
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {marks?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((mark, index) => {
                    return (
                      <TableRow hover role="checkbox" tabIndex={-1} key={"row" + index}>
                        {columns.map((column, indexColumn) => {
                          const value = mark[column.id];
                          return (
                            <TableCell key={'col' + indexColumn} align={column.align}>
                              {column.id === 'hora'
                                ? value.substring(0, 8)
                                : value}
                            </TableCell>
                          );
                        })}
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </TableContainer>
        
        </div>
      </div>
    </div>
  );
}
export default BodyAssistance;