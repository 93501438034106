import React from 'react';
import { useTheme } from '@mui/material/styles';
import { Typography, FormControl, InputLabel, Box, Grid, Divider, Modal, Button, Card, CardContent, CardActions, MenuItem, Select } from '@mui/material';
import AddAlarmIcon from '@mui/icons-material/AddAlarm';
import AddEquipments from '../../../components/Maintenance/AddEquipments/AddEquipments';

const AddEquipment = (props) => {
    const { open, setOpen } = props;
    const theme = useTheme();


    return (
        <Modal
            open={open}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box
                sx={{
                    position: 'absolute',
                    top: '40%', //'50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: '40%', //400,
                    maxHeight: '65vh',
                    bgcolor: 'background.paper',
                    borderRadius: '3%',
                    p: 2,
                   // overflow: 'scroll'
                }}
            >
                <form>

                    <CardContent>
                        <Box textAlign={'left'} >
                            <Typography variant="h6" gutterBottom color={'primary'}>
                                Agregar Equipo
                            </Typography>
                        </Box>
                        <AddEquipments />
                    </CardContent>
                    <Divider />
                    <CardActions disableSpacing sx={{ height: '14%', justifyContent: 'center' }}>
                        <Button
                            onClick={() => setOpen(false)}
                            sx={{
                                background: 'red',
                                marginRight: '10px',
                                color: 'white',
                                border: '1px solid white',
                            }}
                        >
                            Cerrar
                        </Button>
                        <Button
                            onClick={() => ""}
                            sx={{
                                color: 'white',
                                background: theme.palette.primary.main,
                                border: `1px solid white`,
                            }}
                        >
                            Guardar
                        </Button>

                    </CardActions>

                </form>
            </Box>
        </Modal>





    );
};

export default AddEquipment;