import axios from "axios";
import { Global } from '../utils/general';
import headerRequest from "../utils/headers"

const API_URL = Global.url;

class Perfiles {
    getPerfiles() {
        let ruta = `${API_URL}/perfiles`;
        return axios
            .get(ruta, {
                headers: headerRequest(),
            })
            .catch((error) => {
                return error;
            });
    }

}

export default new Perfiles();
