import React, { useState, useEffect } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Checkbox } from '@mui/material';
import { Box, Grid, Select, MenuItem } from '@mui/material';
import { Input } from "antd";

const BodyOtros = (props) => {
  const { } = props;
  return (
    <div className='page-body-main'>
      <Grid item xs={12} sm={10} md={12} paddingLeft={'1rem'} paddingRight={'1rem'}>
        <>
          <Grid container p={1}>
            <Grid item xs={6}>
              <fieldset>
                <legend style={{ fontSize: 'medium' }}>Tipo de producto</legend>
                <Input
                  type="text"
                  style={{
                    marginTop: '0.1rem',
                    paddingTop: '0.7rem',
                    width: '95%',
                    color: "rgba(0, 0, 0, 0.65)",
                    backgroundColor: "#ffffff",

                  }}
                //value={conocimiento?.detalle_mof?.respuesta || ""}
                //onChange={(e) => handleUpdateDatosMof(24, e.target.value, "respuesta")}
                />
              </fieldset>
            </Grid>
            <Grid item xs={6}>
              <fieldset>
                <legend style={{ fontSize: 'medium' }}>Otro</legend>
                <Select style={{ width: '95%' }}
                  size='small'
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                //value={Number(infoClient?.value) || 0}
                //onChange={(e) => setInfoClient(clients?.find(element => Number(element.value) === Number(e.target.value)))}
                >
                  <MenuItem value={0}><em>Seleccionar modelo</em></MenuItem>
                  {/*clients?.map((opt) => {
                      return (
                        <MenuItem key={'cli' + opt.id} value={opt.id}>{opt.id + ' - ' + opt.label}</MenuItem>
                      )
                   })*/}
                </Select>
              </fieldset>
            </Grid>
            <Grid marginTop={'2rem'} item xs={6}>
              <fieldset>
                <legend style={{ fontSize: 'medium' }}>Modalidad</legend>
                <Select style={{ width: '95%' }}
                  size='small'
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                //value={Number(infoClient?.value) || 0}
                //onChange={(e) => setInfoClient(clients?.find(element => Number(element.value) === Number(e.target.value)))}
                >
                  <MenuItem value={0}><em>Seleccionar CECO</em></MenuItem>
                  {/*clients?.map((opt) => {
                                            return (
                                                <MenuItem key={'cli' + opt.id} value={opt.id}>{opt.id + ' - ' + opt.label}</MenuItem>
                                            )
                                        })*/}
                </Select>
              </fieldset>
            </Grid>
            <Grid marginTop={'2rem'} item xs={3}>
              <fieldset>
                <legend style={{ fontSize: 'medium' }}>Cantidad</legend>
                <Input
                  type="text"
                  style={{
                    marginTop: '0.1rem',
                    paddingTop: '0.7rem',
                    width: '95%',
                    color: "rgba(0, 0, 0, 0.65)",
                    backgroundColor: "#ffffff",

                  }}
                //value={conocimiento?.detalle_mof?.respuesta || ""}
                //onChange={(e) => handleUpdateDatosMof(24, e.target.value, "respuesta")}
                />
              </fieldset>
            </Grid>
            <Grid  marginTop={'2rem'} item xs={3}>
              <fieldset>
                <legend style={{ fontSize: 'medium' }}>Presupuesto</legend>
                <Input
                  type="text"
                  style={{
                    marginTop: '0.1rem',
                    paddingTop: '0.7rem',
                    width: '95%',
                    color: "rgba(0, 0, 0, 0.65)",
                    backgroundColor: "#ffffff",

                  }}
                //value={conocimiento?.detalle_mof?.respuesta || ""}
                //onChange={(e) => handleUpdateDatosMof(24, e.target.value, "respuesta")}
                />
              </fieldset>
            </Grid>

          </Grid>
        </>
      </Grid>

    </div>
  );
};

export default BodyOtros;
