import { createTheme } from '@mui/material/styles';
import { colors } from '../assets/colors'
//import { purple, lightGreen, lightBlue } from '@mui/material/colors';
const theme = createTheme({
  palette: {
    primary: {
      main: colors.primary.hex,
      letter: '#FFFFFF'
    },
    secondary: {
      main: colors.secondary.hex,
      letter: '#021A6A'
    },
    tertiary: {
      main: colors.tertiary.hex,
      letter: '#000000'
    },
    quaternary: {
      main: colors.quaternary.hex,
      letter: '#FFFFFF'
    },
  }
});

export default theme;