import React, { } from 'react';
import { Select, MenuItem, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import usuarioPerfil from '../../../services/usuarioPerfil';
import { CODES } from '../../../utils/responseCodes';
import { Toast } from '../../../utils/alerts';

const columns = [
  { id: 'id_usuario', label: 'Id Usuario', minWidth: 8, align: 'left', },
  { id: 'usuario', label: 'Usuario', minWidth: 100, align: 'left', },
  { id: 'desPerfil', label: 'Perfil', minWidth: 250, align: 'left', },
  { id: 'nombresPersona', label: 'Nombres', minWidth: 120, align: 'left', },
  { id: 'apePaternoPersona', label: 'Apellido', minWidth: 200, align: 'left', },
];

const BodyUserPerfil = (props) => {
  const { records, profiles, setRecords } = props;

  const onchangePerfil = async (idx, key, value) => {
    let updRecords = [].concat(records);
    updRecords[idx][key] = value;


    await usuarioPerfil.updateProfileUser(updRecords[idx], updRecords[idx]['id_usuario'])
      .then(result => {
        if (result.status === CODES.CREATE_201) {
          Toast.fire({
            icon: 'success',
            title: "Perfil actualizado"
          })

        } else {
          Toast.fire({
            icon: 'error',
            title: result.response.data.message
          })
        }
      })
      .catch(err => {
        console.log('getMotivoRechazo', err)
      })

    return setRecords(updRecords);
  }

  return (
    <div className='page-body-main'>
      <div className='page-body__table' style={{ overflow: 'hidden' }}>
        <div className='page-body-table'>
          <TableContainer className='table-height'>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  {columns.map((column) => (
                    <TableCell
                      key={column.id}
                      align={column.align}
                      style={{ minWidth: column.minWidth }}
                    >
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {records?.map((record, indexRow) => {
                  return (
                    <TableRow hover role="checkbox" tabIndex={-1} key={"row" + indexRow}>
                      {columns.map((column, indexColumn) => {
                        const value = record[column.id];
                        return (
                          <TableCell key={'col' + indexColumn} align={column.align}>
                            {column.id === 'id_usuario' ?
                              record.id_usuario :
                              column.id === 'desPerfil' ?
                                <Select style={{ width: '80%' }}
                                  size='small'
                                  labelId="demo-simple-select-label"
                                  id="demo-simple-select"
                                  value={record.perfil_id || 0}
                                  onChange={(e) => onchangePerfil(indexRow, 'perfil_id', e.target.value)}
                                >
                                  <MenuItem value={0}><em>Seleccionar</em></MenuItem>
                                  {profiles?.map((type) => {
                                    return (
                                      <MenuItem key={type.id_perfil} value={type.id_perfil}>{type.desPerfil}</MenuItem>
                                    )
                                  })
                                  }
                                </Select>
                                :
                                column.id === 'apePaternoPersona' ?
                                  record.apePaternoPersona + ' ' + record.apeMaternoPersona :
                                  value}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      </div>
    </div>
  );
}
export default BodyUserPerfil;