import React, { useState } from 'react';
import { ButtonGroup, IconButton, MenuItem, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Select } from '@mui/material';
import ImageSearchIcon from '@mui/icons-material/ImageSearch';
import ThumbUpAltIcon from '@mui/icons-material/ThumbUpAlt';
import ThumbDownAltIcon from '@mui/icons-material/ThumbDownAlt';
import CancelIcon from '@mui/icons-material/Cancel';
import { CODES } from '../../../utils/responseCodes';
import { Toast } from '../../../utils/alerts';
import { filterData, convertHour } from '../../../utils/function';
import OverTimeService from '../../../services/overTime';
import _ from 'lodash';

const columns = [
  { id: 'id_sobretiempo', label: '#', width: 50, align: 'left', },
  { id: 'trabajador', label: 'Trabajador', width: 150, align: 'left', },
  { id: 'motivo', label: 'Motivo', width: 300, align: 'left', },
  { id: 'tipoSobreTiempoTrab', label: 'Tipo', width: 120, align: 'left', },
  { id: 'fecha', label: 'Fecha', width: 120, align: 'center', },
  { id: 'tiempo', label: 'Tiempo', width: 120, align: 'center', },
  { id: 'estado', label: 'Estado', width: 80, align: 'center', },
  { id: 'evidence', label: 'Evidencia', width: 80, align: 'center', },
  { id: 'actions', label: 'Acciones', width: 200, align: 'left', },
];

const BodyCheckOverTime = (props) => {
  const { search, setOpenViewImage, typeovertime, rejects, petitions, setPetitions, setInfoImage, } = props;
  const [tipoHabilitado, settipoHabilitado] = useState(null);

  const onchangeEvidence = async (id, idx, key, value, keyEstado, valueEstado) => {
    let updPetitions = _.cloneDeep(petitions);
    let body;
    updPetitions?.forEach(async (e) => {
      if (+e.id_sobretiempo === +id) {
        e[key] = value;
        e[keyEstado] = valueEstado;
        e['minutos'] = e['tiempo'];
        e['habilitado'] = 1;
        body = e;

        if (valueEstado === 'R') {
          delete body.motRechazo_id;
          delete body.tipoSobreTiempo;
        }

        await OverTimeService.saveRequestOverTime(body).then(result => {
          if (result.status === CODES.CREATE_201) {
            Toast.fire({
              icon: 'info',
              title: result?.data.message
            })
            updPetitions[idx]['rechazoDesc'] = result?.data?.rechazoDesc;
          } else {
            Toast.fire({
              icon: 'error',
              title: result.response.data.message
            })
          }
        }).catch(err => {
          console.log('getMotivoRechazo', err)
        })
      }
    })

    return setPetitions(updPetitions);
  }

  const onchange = async (info, idx, key, value) => {
    let updPetitions = _.cloneDeep(petitions);
    updPetitions?.forEach((e) => {
      if (+e.id_sobretiempo === +info.id_sobretiempo) {
        e[key] = value;
      }
    })
    //updPetitions[idx][key] = value;
    return setPetitions(updPetitions);
  }

  let resultSearch = filterData(petitions, search, ['trabajador', 'motivo']);


  return (
    <div className='page-body-main'>
      <div className='page-body__table page-body__table--overtime' style={{ overflow: 'hidden' }}>
        <div className='page-body-table'>
          <TableContainer className='table-height table-height--overtime'>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  {columns.map((column) => (
                    <TableCell
                      key={column.id}
                      align={column.align}
                      style={{ width: column.width, maxWidth: column.width }}
                    >
                      {column.id === 'evidence' ?
                        <ImageSearchIcon fontSize='medium' /> :
                        column.label
                      }
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {resultSearch?.map((petition, idxPetition) => {
                  petition.rutaImagen = petition.evidenciaImg;
                  return (
                    <TableRow hover role="checkbox" tabIndex={-1} key={"row" + idxPetition}
                      style={{ backgroundColor: petition.estado === 'R' ? '#ECEBEB' : '' }}
                    >
                      {columns?.map((column, indexColumn) => {
                        const value = petition[column.id];
                        return (
                          <TableCell key={'col' + indexColumn} align={column.align}>
                            {column.id === 'tipoSobreTiempoTrab' ?
                              typeovertime?.find(element => +element.id === +value)?.label :
                              column.id === 'tiempo' ?
                                convertHour(value) :
                                column.id === 'evidence' ?
                                  <IconButton aria-label="delete" size="small"
                                    disabled={petition.evidenciaImg ? false : true}
                                    onClick={() => { setOpenViewImage(true); setInfoImage(petition); }}>
                                    <ImageSearchIcon fontSize='medium' color={petition.evidenciaImg ? 'primary' : 'error'} />
                                  </IconButton> :
                                  column.id === 'actions' ?
                                    Number(petition.jefe_val) === 1 ?
                                      <>
                                        {Number(petition.habilitado) === 1 && petition.estado === 'E' &&
                                          <ButtonGroup
                                            disableElevation
                                            variant="contained"
                                            aria-label="Disabled elevation buttons"
                                          >
                                            <IconButton aria-label="delete" size="small" onClick={() => { onchange(petition, idxPetition, 'habilitado', 0); settipoHabilitado("like") }}>
                                              <ThumbUpAltIcon fontSize='medium' color={'success'} />
                                            </IconButton>
                                            <IconButton aria-label="delete" size="small" onClick={() => { onchange(petition, idxPetition, 'habilitado', 0); settipoHabilitado("dislike") }}>
                                              <ThumbDownAltIcon fontSize='medium' color={'error'} />
                                            </IconButton>
                                          </ButtonGroup>
                                        }
                                        {Number(petition.habilitado) === 1 && petition.estado === 'R' &&
                                          petition.rechazoDesc
                                        }
                                        {Number(petition.habilitado) === 1 && petition.estado === 'A' &&
                                          typeovertime?.find(element => element.id === Number(petition.tipoSobreTiempo))?.label
                                        }
                                        {Number(petition.habilitado) === 0 && tipoHabilitado === 'like' &&
                                          <>
                                            <Select style={{ width: '80%' }}
                                              size='small'
                                              labelId="demo-simple-select-label"
                                              id="demo-simple-select"
                                              value={petition.tipoSobreTiempo || 0}
                                              onChange={(e) => onchangeEvidence(petition.id_sobretiempo, idxPetition, 'tipoSobreTiempo', e.target.value, 'estado', 'A')}
                                            >
                                              <MenuItem value={0}><em>Seleccionar</em></MenuItem>
                                              {typeovertime?.map((type) => {
                                                return (
                                                  <MenuItem key={type.id} value={type.id}>{type.label}</MenuItem>
                                                )
                                              })
                                              }
                                            </Select>

                                            <IconButton aria-label="delete" size="small" onClick={() => { onchange(petition, idxPetition, 'habilitado', 1); settipoHabilitado(null) }}>
                                              <CancelIcon fontSize='medium' color={'error'} />
                                            </IconButton>
                                          </>
                                        }
                                        {Number(petition.habilitado) === 0 && tipoHabilitado === 'dislike' &&
                                          <>
                                            <Select style={{ width: '80%' }}
                                              size='small'
                                              labelId="demo-simple-select-label"
                                              id="demo-simple-select"
                                              value={petition.motivoRechazo_id || 0}
                                              onChange={(e) => onchangeEvidence(petition.id_sobretiempo, idxPetition, 'motivoRechazo_id', Number(e.target.value), 'estado', 'R')}
                                            >
                                              <MenuItem value={0}><em>Seleccionar</em></MenuItem>
                                              {rejects?.map((reject) => {
                                                return (
                                                  <MenuItem key={reject.descripcion} value={reject.id_motRechazo}>{reject.descripcion}</MenuItem>
                                                )
                                              })}
                                            </Select>
                                            <IconButton aria-label="delete" size="small" onClick={() => { onchange(petition, idxPetition, 'habilitado', 1); settipoHabilitado(null) }}>
                                              <CancelIcon fontSize='medium' color={'error'} />
                                            </IconButton>
                                          </>
                                        }
                                      </>
                                      : "-------"
                                    : value
                            }
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      </div>
    </div>
  );
}
export default BodyCheckOverTime;