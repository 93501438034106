import React, { useState, useEffect } from 'react';
import { Box, Grid, Select, MenuItem,TextField, Typography } from '@mui/material';
import SpinnerLoader from '../../components/general/SpinnerLoader';
import BodyCheckRequests from '../../components/Requests/BodyCheckRequests/BodyCheckRequests';

const CheckRequests = ({ userToken }) => {
    const url = "'" + window.location.hash.replace('#', '') + "'";
    const idUsuario = userToken?.user.id_usuario;
    const [openModalLoader, setOpenModalLoader] = useState(true);

    //********************* */
    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const [infoCompany, setInfoCompany] = useState(null);
    const [infoClient, setInfoClient] = useState(null);
    const [infoUnit, setInfoUnit] = useState(null);
    const [infoCampus, setInfoCampus] = useState(null);
    const [infoArea, setInfoArea] = useState(null);
    const [infoShift, setInfoShift] = useState(null);

    const [companies, setCompanies] = useState(null);
    const [clients, setClients] = useState(null);
    const [units, setUnits] = useState(null);
    const [campus, setCampus] = useState(null);
    const [areas, setAreas] = useState(null);
    const [shift, setShift] = useState(null);

    //********************* */

    useEffect(() => {
        init();
    }, [])

    const init = () => {
        setOpenModalLoader(false);
    }

    //*****************************************/
    const [dataStaffs, setDataStaffs] = useState(null);
    const [searchStaff, setSearchStaff] = useState("");
    const [pageStaff, setPageStaff] = useState(1);
    const pageSizeStaff = 8;
    const [totalPagesStaff, setTotalPagesStaff] = useState(1);



    //*****************************************/



    return (
        <Box sx={{ flexGrow: 1 }}>
             <Box textAlign={'center'} mt={5}>
                <Typography variant="h4" gutterBottom color={'primary'}>
                   Ver solicitud equipo TI
                </Typography>
            </Box>
            <Grid container
                direction="row"
                justifyContent="center"
                alignItems="center"
                mt={4}
            >
                <Grid item xs={12} sm={10} md={10} paddingLeft={'1rem'} paddingRight={'1rem'}>
                    <Grid container p={1}>
                        <Grid item xs={5}>
                            <TextField id="input-with-sx" label="Busqueda por solicitante" variant="standard"
                                fullWidth
                                placeholder='Buscar por nombre del solicitante'
                                style={{
                                    marginTop: '0.8rem',
                                    paddingTop: '0.7rem',
                                    width: '95%',
                                   

                                }}
                                //value={searchComp}
                                //onChange={searcherComp}
                              
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <fieldset>
                                <legend style={{ fontSize: 'medium' }}>Equipo</legend>
                                <Select style={{ width: '95%' }}
                                    size='small'
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                //value={Number(infoCompany?.value) || 0}
                                //onChange={(e) => setInfoCompany(companies?.find(element => Number(element.value) === Number(e.target.value)))}
                                >
                                    <MenuItem value={0}><em>Seleccionar equipo</em></MenuItem>
                                    {/*companies?.map((opt) => {
                                                return (
                                                    <MenuItem key={'comp' + opt.id} value={opt.id}>{opt.id + ' - ' + opt.label}</MenuItem>
                                                )
                                            })*/}
                                </Select>
                            </fieldset>
                        </Grid>
                        <Grid item xs={2}>
                            <fieldset>
                                <legend style={{ fontSize: 'medium' }}>CECO</legend>
                                <Select style={{ width: '95%' }}
                                    size='small'
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                //value={Number(infoClient?.value) || 0}
                                //onChange={(e) => setInfoClient(clients?.find(element => Number(element.value) === Number(e.target.value)))}
                                >
                                    <MenuItem value={0}><em>Seleccionar CECO</em></MenuItem>
                                    {/*clients?.map((opt) => {
                                                return (
                                                    <MenuItem key={'cli' + opt.id} value={opt.id}>{opt.id + ' - ' + opt.label}</MenuItem>
                                                )
                                            })*/}
                                </Select>
                            </fieldset>
                        </Grid>
                        <Grid item xs={2}>
                            <fieldset>
                                <legend style={{ fontSize: 'medium' }}>Estado</legend>
                                <Select style={{ width: '95%' }}
                                    size='small'
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                //value={Number(infoUnit?.value) || 0}
                                //onChange={(e) => setInfoUnit(units?.find(element => Number(element.value) === Number(e.target.value)))}
                                >
                                    <MenuItem value={0}><em>Seleccionar unidad</em></MenuItem>
                                    {/*units?.map((opt) => {
                                                return (
                                                    <MenuItem key={'und' + opt.id} value={opt.id}>{opt.id + ' - ' + opt.label}</MenuItem>
                                                )
                                            })*/}
                                </Select>
                            </fieldset>
                        </Grid>
                    </Grid>
                    <BodyCheckRequests handleOpen={handleOpen}/>
                </Grid>
            </Grid>
            <SpinnerLoader open={openModalLoader} />
        </Box>
    );
};

export default CheckRequests;