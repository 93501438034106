import React, { } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, ButtonGroup, IconButton } from '@mui/material';
import AddBoxIcon from '@mui/icons-material/AddBox';

const columns = [
  { id: 'id_area', label: 'Nro', minWidth: 100, align: 'left', },
  { id: '#', label: 'Solicitante', minWidth: 250, align: 'left', },
  { id: '##', label: 'CECO', minWidth: 100, align: 'left', },
  { id: '###', label: 'Equipo', minWidth: 150, align: 'left', },
  { id: '####', label: 'Estado', minWidth: 100, align: 'left', },
  { id: 'actions', label: 'Acciones', minWidth: 120, align: 'left', },
];

const BodyCheckRequests = (props) => {
  const {} = props;

  return (
    <div className='page-body-main'>
      <div className='page-body__table' style={{ overflow: 'hidden' }}>
        <div className='page-body-table'>
          <TableContainer className='table-height'>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  {columns.map((column) => (
                    <TableCell
                      key={column.id}
                      align={column.align}
                      style={{ minWidth: column.minWidth }}
                    >
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      </div>
    </div>
  );
}
export default BodyCheckRequests;