import React from 'react';
import { CardHeader, CardContent, CardActions, } from '@mui/material';
import Avatar from '@mui/material/Avatar';
import EditInfoform from '../../../components/BillingForm/AddEditForm/EditInfoform';
import EngineeringIcon from '@mui/icons-material/Engineering';
import ServiceService from '../../../../services/service';
import { Modal } from 'antd';
import { getDateOL } from '../../../../utils/function';
import { Toast } from '../../../../utils/alerts';
import { CODES } from '../../../../utils/responseCodes';

const AddEditForm = (props) => {
    const { open, handleClose, setTitleModalLoader, setOpenModalLoader, approversInfo, serviceStatusInfo, infoService, setInfoService, getServicios } = props;

    const url = "'" + window.location.pathname + "'";

    const cleanInfo = () => {
        setInfoService(null);
        handleClose()
    }

    const updateServicios = async (e) => {
        e.preventDefault();
        if (+infoService.id_estadoServ === 2 && (!infoService.id_atentido || +infoService.id_atentido === 0)) {
            Toast.fire({
                icon: 'info',
                title: 'Debe seleccionar un responsable'
            })
            return;
        }
        if (+infoService.id_estadoServ === 2 && (!infoService.comprobante || infoService.comprobante === '')) {
            Toast.fire({
                icon: 'info',
                title: 'Debe ingresar un numero de comprobante'
            })
            return;
        }
        
        const body = {
            comprobante: infoService.comprobante,
            estadoServ_id: infoService.id_estadoServ,
            atendido_id: infoService.id_atentido,
            fechaEnvio: infoService.id_estadoServ !== 1 ? getDateOL(new Date(), 'large') : null,
            motivo: infoService.motivo
        }

        setOpenModalLoader(true);
        setTitleModalLoader('Actualizando ...');
        try {
            const result = await ServiceService.updateServicios(url, infoService.id_servicio, body);
            if (result.status === CODES.SUCCESS_200) {
                Toast.fire({
                    icon: 'success',
                    title: result.data.message || 'Exito en la actualización'
                })
                if (+infoService.id_estadoServ === 3) {
                    responseOfService()
                }
                cleanInfo();
                getServicios();
            } else {
                Toast.fire({
                    icon: 'error',
                    title: (result?.response?.data?.message || 'Error al actualizar el servicio!')
                })
            }
            return [];
        } catch (error) {
            console.log(`Error en updateServicios: ${error}`);
        } finally {
            setOpenModalLoader(false);
            setTitleModalLoader('');
        }
    }

    const responseOfService = async () => {
        const body = {
            email: infoService.email,
            nombres: infoService.nombres,
            motivo: infoService.motivo,
            importeSinIGV: parseFloat(infoService?.importeSinIGV)?.toFixed(2),
            importeTotal: parseFloat(infoService?.importeTotal)?.toFixed(2),
            servicio: infoService.servicio,
        }

        setOpenModalLoader(true);
        setTitleModalLoader('Enviando correo ...');
        try {
            const result = await ServiceService.responseOfServiceMails(body);
            if (result.status === CODES.SUCCESS_200) {
                Toast.fire({
                    icon: 'success',
                    title: result.data.message || 'Correo enviado!'
                })
                cleanInfo();
            } else {
                Toast.fire({
                    icon: 'error',
                    title: (result?.response?.data?.message || 'Error al enviar el correo!')
                })
            }
            return [];
        } catch (error) {
            console.log(`Error en responseOfService: ${error}`);
        } finally {
            setOpenModalLoader(false);
            setTitleModalLoader('');
        }
    }

    return (
        <Modal
            title={
                <CardHeader
                    avatar={
                        <Avatar sx={{ bgcolor: "#13005A", fontSize: 'xx-small' }} aria-label="recipe">
                            <EngineeringIcon />
                        </Avatar>
                    }
                    title={"Datos del servicio"}
                    subheader={"Informacion completa del servicio"}
                    sx={{
                        paddingBottom: '0.3rem',
                        paddingTop: 0,
                        margin: '-0.5rem -1rem -0.1rem -1rem',
                        borderBottom: `solid 0.05rem #D5DBDB`,
                    }}
                />
            }
            open={open}
            onOk={cleanInfo}
            onCancel={cleanInfo}
            destroyOnClose={true}
            footer={null}

            style={{ top: '10%', }}
            width={700}
        >
            <form onSubmit={updateServicios} >
                <CardContent
                    sx={{
                        padding: 0,
                        margin: '0 -0.5rem',
                    }}
                >
                    <EditInfoform
                        approvers={approversInfo}
                        serviceStatus={serviceStatusInfo}
                        infoService={infoService}
                        setInfoService={setInfoService}
                    />
                </CardContent>

                <CardActions
                    sx={{
                        //paddingBottom: '0.3rem',
                        //paddingTop: 0,
                        margin: '0.5rem -1rem -1rem -1rem',
                        borderTop: `solid 0.05rem #00337C`,
                    }}
                >
                    <button
                        type={'submit'}
                        className='btn btn-outline-primary btn-sm'
                        style={{ fontSize: '15px' }}
                    >
                        Guardar
                    </button>
                    <button
                        type={'button'}
                        onClick={cleanInfo}
                        className='btn btn-outline-danger btn-sm'
                        style={{ fontSize: '15px' }}
                    >
                        Cerrar
                    </button>
                </CardActions>
            </form>
        </Modal>
    );
};

export default AddEditForm;