import axios from "axios";
import { Global } from '../utils/general';
import headerRequest from "../utils/headers"

const API_URL = Global.url;

class usuarioPerfil {
    getUserPerfil() {
        let ruta = `${API_URL}/user/users`;
        return axios
            .get(ruta, {
                headers: headerRequest(),
            })
            .catch((error) => {
                return error;
            });
    }


    updateProfileUser(body, id) {
        var ruta = `${API_URL}/user/edit-profile-user/${id}`;
        return axios
            .put(ruta, body, {
                headers: headerRequest(),
            })
            .catch((error) => {
                return error;
            });
    }
}




export default new usuarioPerfil();
