import React, { useState, useEffect } from 'react';
import { Box, Grid, } from '@mui/material';
import BodyBillingForm from '../../components/BillingForm/BodyBillingForm';
import GeneralService from '../../../services/general';
import ServiceService from '../../../services/service';
import AddEditForm from './AddEditForm/AddEditForm';
import { getDateOL } from '../../../utils/function';
import { CODES } from '../../../utils/responseCodes';
import SpinnerLoader from '../../components/general/SpinnerLoader';
import _ from 'lodash';

const BillingEdit = ({ userToken }) => {
    const url = "'" + window.location.pathname + "'";
    const [titleModalLoader, setTitleModalLoader] = useState('');
    const [openModalLoader, setOpenModalLoader] = useState(true);
    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    // Obtener el año y el mes actuales
    const now = new Date();
    const firstDayOfMonth = new Date(now.getFullYear(), now.getMonth(), 1);
    const lastDayOfMonth = new Date(now.getFullYear(), now.getMonth() + 1, 0);
    const [dateStart, setDateStart] = useState(getDateOL(firstDayOfMonth, 'short'));
    const [dateEnd, setDateEnd] = useState(getDateOL(lastDayOfMonth, 'short'));

    /*const [page, setPage] = useState(1);
    const [totalPage, setTotalPage] = useState(null);*/
    const [approversInfo, setApproversInfo] = useState(null);
    const [approvers, setApprovers] = useState(null);
    const [valueApprover, setValueApprover] = useState(0);
    const [companies, setCompanies] = useState(null);
    const [valueCompany, setValueCompany] = useState(0);
    const [serviceStatusInfo, setServiceStatusInfo] = useState(null);
    const [serviceStatus, setServiceStatus] = useState(null);
    const [valueServiceStatus, setValueServiceStatus] = useState(1);
    const [costCenter, setCostCenter] = useState(null);
    const [voucherType, setVoucherType] = useState(null);
    const [paymentCondition, setPaymentCondition] = useState(null);
    const [moneda, setMoneda] = useState(null);

    const [services, setServices] = useState(null);
    const [infoService, setInfoService] = useState(null);

    useEffect(() => {
        init();
    }, [])
    
    useEffect(() => {
        setServices(null);
        if ((valueCompany || valueCompany === 0) &&
            (valueServiceStatus || valueServiceStatus === 0) &&
            (valueApprover || valueApprover === 0) &&
            dateStart && dateEnd) {
            getServicios()
        }
    }, [valueCompany, valueServiceStatus, valueApprover, dateStart, dateEnd])

    const init = async () => {
        Promise.all([
            getEmpresas(),
            getEstadoServ(),
            getCentroCosto(),
            getTipoComprobante(),
            getCondicionPago(),
            getMoneda(),
            getAprobadoresServices(),
        ]).then(() => setOpenModalLoader(false));
        getServicios()
    }

    const getEmpresas = async () => {
        try {
            const result = await GeneralService.getEmpresas();
            if (result.status === CODES.SUCCESS_200) {
                const data = result?.data || [];
                data.forEach((element) => {
                    element.id = element.id_empresa;
                    element.value = element.id_empresa;
                    element.label = element.desEmpresa;
                });
                data.unshift({ id: 0, value: 0, label: 'Todas' });
                setCompanies(data);
            }
        } catch (error) {
            console.log(`Error en getEmpresas: ${error}`);
        }
    };

    const getEstadoServ = async () => {
        try {
            const result = await GeneralService.getEstadoServ();
            if (result.status === CODES.SUCCESS_200) {
                const dataInfo = _.cloneDeep(result?.data);
                dataInfo.forEach((element) => {
                    element.id = element.id_estadoServ;
                    element.value = element.id_estadoServ;
                    element.label = element.descripcion;
                });
                setServiceStatusInfo(dataInfo);
                const data = result?.data || [];
                data.forEach((element) => {
                    element.id = element.id_estadoServ;
                    element.value = element.id_estadoServ;
                    element.label = element.descripcion;
                });
                data.unshift({ id: 0, value: 0, label: 'Todos' });
                setServiceStatus(data);
            }
        } catch (error) {
            console.log(`Error en getEstadoServ: ${error}`);
        }
    };

    const getCentroCosto = async () => {
        try {
            const result = await GeneralService.getCentroCosto();
            if (result.status === CODES.SUCCESS_200) {
                const data = result?.data || [];
                data.forEach((element) => {
                    element.id = element.id_centroCosto;
                    element.value = element.id_centroCosto;
                    element.label = element.names;
                });
                data.unshift({ id: 0, value: 0, label: 'Todos' });
                setCostCenter(data);
            }
        } catch (error) {
            console.log(`Error en getCentroCosto: ${error}`);
        }
    };

    const getTipoComprobante = async () => {
        try {
            const result = await GeneralService.getTipoComprobante();
            if (result.status === CODES.SUCCESS_200) {
                const data = result?.data || [];
                data.forEach((element) => {
                    element.id = element.id_tipoComprobante;
                    element.value = element.id_tipoComprobante;
                    element.label = element.descripcion;
                });
                data.unshift({ id: 0, value: 0, label: 'Todos' });
                setVoucherType(data);
            }
        } catch (error) {
            console.log(`Error en getTipoComprobante: ${error}`);
        }
    };

    const getCondicionPago = async () => {
        try {
            const result = await GeneralService.getCondicionPago();
            if (result.status === CODES.SUCCESS_200) {
                const data = result?.data || [];
                data.forEach((element) => {
                    element.id = element.id_condicionPago;
                    element.value = element.id_condicionPago;
                    element.label = element.descripcion;
                });
                data.unshift({ id: 0, value: 0, label: 'Todas' });
                setPaymentCondition(data);
            }
        } catch (error) {
            console.log(`Error en getCondicionPago: ${error}`);
        }
    };

    const getMoneda = async () => {
        try {
            const result = await GeneralService.getMoneda();
            if (result.status === CODES.SUCCESS_200) {
                const data = result?.data || [];
                data.forEach((element) => {
                    element.id = element.id_moneda;
                    element.value = element.id_moneda;
                    element.label = element.descripcion;
                });
                data.unshift({ id: 0, value: 0, label: 'Todas' });
                setMoneda(data);
            }
        } catch (error) {
            console.log(`Error en getMoneda: ${error}`);
        }
    };

    const getAprobadoresServices = async () => {
        try {
            const result = await ServiceService.getAprobadoresServices(url);
            if (result.status === CODES.SUCCESS_200) {
                const dataInfo = _.cloneDeep(result?.data);
                dataInfo.forEach((element) => {
                    element.id = element.id_aprobador;
                    element.value = element.id_aprobador;
                    element.label = element.aprobador;
                });
                setApproversInfo(dataInfo);
                const data = result?.data || [];
                data.forEach((element) => {
                    element.id = element.id_aprobador;
                    element.value = element.id_aprobador;
                    element.label = element.aprobador;
                });
                data.unshift({ id: 0, value: 0, label: 'Todos' });
                setApprovers(data);
            }
        } catch (error) {
            console.log(`Error en getMoneda: ${error}`);
        }
    };

    const getServicios = async () => {
        try {
            setTitleModalLoader('Buscando servicios ...');
            setOpenModalLoader(true);
            setServices(null);
            const result = await ServiceService.getServicios({ url: url, empresa_id: valueCompany, estadoServ_id: valueServiceStatus, atendido_id: valueApprover, inicio: dateStart, fin: dateEnd })
            if (result.status === CODES.SUCCESS_200) {
                setServices(result?.data);
            }
        } catch (error) {
            console.log(`Error en getServicios: ${error}`);
        } finally {
            setTitleModalLoader(null);
            setOpenModalLoader(false);
        }
    };

    const viewService = (type, info) => {
        setInfoService(info);
        handleOpen();
    }

    return (
        <Box sx={{ flexGrow: 1 }}>
            <AddEditForm
                open={open}
                handleClose={handleClose}
                setTitleModalLoader={setTitleModalLoader}
                setOpenModalLoader={setOpenModalLoader}
                approversInfo={approversInfo}
                serviceStatusInfo={serviceStatusInfo}

                infoService={infoService}
                setInfoService={setInfoService}
                getServicios={getServicios}
            />
            <Grid container direction="row" justifyContent="center" alignItems="center">
                <Grid item xs={6} sm={6} md={2} display={'flex'} justifyContent={'center'}>
                    <div className='group' style={{ width: '100%' }} >
                        <div className="contenido">
                            <label className="label" style={{ fontSize: 'small' }}>Empresa</label>
                            <select
                                className="form-select input form-control-sm mt-1"
                                aria-label="Empresa"
                                style={{ border: 'solid 1px #D5DBDB', fontSize: 'small' }}
                                value={valueCompany}
                                onChange={(e) => setValueCompany(parseInt(e.target.value, 10))}
                            >
                                {companies?.map((company, idxCompany) => (
                                    <option key={`company-${idxCompany}`} value={company.value}>
                                        {company.label}
                                    </option>
                                ))}
                            </select>
                        </div>
                    </div>
                </Grid>

                <Grid item xs={6} sm={6} md={2} display={'flex'} justifyContent={'center'}>
                    <div className='group' style={{ width: '100%' }} >
                        <div className="contenido">
                            <label className="label" style={{ fontSize: 'small', }}>Estado</label>
                            <select
                                className="form-select input form-control-sm mt-1"
                                aria-label="Estado"
                                style={{ border: 'solid 1px #D5DBDB', fontSize: 'small' }}
                                value={valueServiceStatus}
                                onChange={(e) => setValueServiceStatus(parseInt(e.target.value, 10))}
                            >
                                {serviceStatus?.map((state, idxState) => (
                                    <option key={`state-${idxState}`} value={state.value}>
                                        {state.label}
                                    </option>
                                ))}
                            </select>
                        </div>
                    </div>
                </Grid>

                <Grid item xs={6} sm={6} md={2} display={'flex'} justifyContent={'center'}>
                    <div className='group' style={{ width: '100%' }} >
                        <div className="contenido">
                            <label className="label" style={{ fontSize: 'small', }}>Atendido por</label>
                            <select
                                className="form-select input form-control-sm mt-1"
                                aria-label="Atendido por"
                                style={{ border: 'solid 1px #D5DBDB', fontSize: 'small' }}
                                value={valueApprover}
                                onChange={(e) => setValueApprover(parseInt(e.target.value, 10))}
                            >
                                {approvers?.map((approver, idxApprover) => (
                                    <option key={`approver-${idxApprover}`} value={approver.value}>
                                        {approver.label}
                                    </option>
                                ))}
                            </select>
                        </div>
                    </div>
                </Grid>

                <Grid item xs={6} sm={6} md={2} display={'flex'} justifyContent={'center'}>
                    <div className='group' style={{ width: '100%' }} >
                        <div className="contenido">
                            <label className="label" style={{ fontSize: 'small', }}>Fecha Inicio</label>
                            <input type="date" className="input mt-1" aria-describedby="Fecha inicial"
                                style={{ border: 'solid 1px #D5DBDB', fontSize: 'small', }}
                                value={dateStart} onChange={(e) => setDateStart(e.target.value)}
                            />
                        </div>
                    </div>
                </Grid>

                <Grid item xs={6} sm={6} md={2} display={'flex'} justifyContent={'center'}>
                    <div className='group' style={{ width: '100%' }} >
                        <div className="contenido">
                            <label className="label" style={{ fontSize: 'small', }}>Fecha Fin</label>
                            <input type="date" className="input mt-1" aria-describedby="Fecha final"
                                style={{ border: 'solid 1px #D5DBDB', fontSize: 'small', }}
                                value={dateEnd} onChange={(e) => setDateEnd(e.target.value)}
                            />
                        </div>
                    </div>
                </Grid>
                <Grid item xs={1} display={'flex'} justifyContent={'flex-start'}>
                    <button
                        onClick={getServicios}
                        className='btn btn-outline-success btn-sm'
                        style={{ fontSize: '15px' }}
                    >
                        Buscar
                    </button>
                </Grid>
            </Grid>
            <Grid container
                direction="row"
                justifyContent="center"
                alignItems="center"
                mt={2}
            >
                <Grid item xs={12} sm={11}>
                    <BodyBillingForm
                        records={services}
                        viewService={viewService}
                    // records={
                    //     [
                    //         {
                    //             fechaReg: '26/10/2023 10:40:40',
                    //             desEmpresa: 'Obiettivo Lavoro los Andes',
                    //             razonSocial: 'JC GLASS INGENERIA Y SOLUCIONES SAC',
                    //             documento: '20600533712',
                    //             descripcion: 'ROTURA DE 30 TONELAS DE VIDRIO',
                    //             email: 'jguillen@jcglass.com.pe',
                    //             cecos: 'BACKUS LOG AREQUIPA',
                    //             correoAdd: 'richardalvarez@lavoroperu.pe',
                    //             moneda: 'Soles',
                    //             importe: 765,
                    //             tipoDocEmitir: 'Boleta',
                    //             estado: 'Facturado',
                    //             comprobante: 'FE03-9150',
                    //             fechaEnvio: '26/10/2023',
                    //             atendido: 'Milady Valencia'
                    //         },
                    //         {
                    //             fechaReg: '10/1/2024 17:06:31',
                    //             desEmpresa: 'Obiettivo Lavoro Peru',
                    //             razonSocial: 'Ramírez Piérola Doris Melissa',
                    //             documento: '40767917',
                    //             descripcion: 'Venta de celular Samsung A10s Nro. de Serie: ZE2233DZXF Número IMEI:  355689863242989	',
                    //             email: 'jguillen@jelizabethubillus@lavoroperu.pe',
                    //             cecos: 'BACKUS L(93)MER001 - MERCK',
                    //             correoAdd: 'valeriaherrera@lavoroperu.pe',
                    //             moneda: 'Soles',
                    //             importe: 7338.98,
                    //             tipoDocEmitir: 'Boleta',
                    //             estado: 'Facturado',
                    //             comprobante: 'B003-67',
                    //             fechaEnvio: '10/01/2024',
                    //             atendido: 'Yoni Hernandez',
                    //         },
                    //         {
                    //             fechaReg: '10/1/2024 17:06:31',
                    //             desEmpresa: 'Obiettivo Lavoro Peru',
                    //             razonSocial: 'Ramírez Piérola Doris Melissa',
                    //             documento: '40767917',
                    //             descripcion: 'Venta de celular Samsung A10s Nro. de Serie: ZE2233DZXF Número IMEI:  355689863242989	',
                    //             email: 'jguillen@jelizabethubillus@lavoroperu.pe',
                    //             cecos: 'BACKUS L(93)MER001 - MERCK',
                    //             correoAdd: 'valeriaherrera@lavoroperu.pe',
                    //             moneda: 'Soles',
                    //             importe: 7338.98,
                    //             tipoDocEmitir: 'Boleta',
                    //             estado: 'Facturado',
                    //             comprobante: 'B003-67',
                    //             fechaEnvio: '10/01/2024',
                    //             atendido: 'Yoni Hernandez',
                    //         },
                    //         {
                    //             fechaReg: '10/1/2024 17:06:31',
                    //             desEmpresa: 'Obiettivo Lavoro Peru',
                    //             razonSocial: 'Ramírez Piérola Doris Melissa',
                    //             documento: '40767917',
                    //             descripcion: 'Venta de celular Samsung A10s Nro. de Serie: ZE2233DZXF Número IMEI:  355689863242989	',
                    //             email: 'jguillen@jelizabethubillus@lavoroperu.pe',
                    //             cecos: 'BACKUS L(93)MER001 - MERCK',
                    //             correoAdd: 'valeriaherrera@lavoroperu.pe',
                    //             moneda: 'Soles',
                    //             importe: 7338.98,
                    //             tipoDocEmitir: 'Boleta',
                    //             estado: 'Facturado',
                    //             comprobante: 'B003-67',
                    //             fechaEnvio: '10/01/2024',
                    //             atendido: 'Yoni Hernandez',
                    //         },
                    //         {
                    //             fechaReg: '10/1/2024 17:06:31',
                    //             desEmpresa: 'Obiettivo Lavoro Peru',
                    //             razonSocial: 'Ramírez Piérola Doris Melissa',
                    //             documento: '40767917',
                    //             descripcion: 'Venta de celular Samsung A10s Nro. de Serie: ZE2233DZXF Número IMEI:  355689863242989	',
                    //             email: 'jguillen@jelizabethubillus@lavoroperu.pe',
                    //             cecos: 'BACKUS L(93)MER001 - MERCK',
                    //             correoAdd: 'valeriaherrera@lavoroperu.pe',
                    //             moneda: 'Soles',
                    //             importe: 7338.98,
                    //             tipoDocEmitir: 'Boleta',
                    //             estado: 'Facturado',
                    //             comprobante: 'B003-67',
                    //             fechaEnvio: '10/01/2024',
                    //             atendido: 'Yoni Hernandez',
                    //         },
                    //         {
                    //             fechaReg: '26/10/2023 10:40:40',
                    //             desEmpresa: 'Obiettivo Lavoro los Andes',
                    //             razonSocial: 'JC GLASS INGENERIA Y SOLUCIONES SAC',
                    //             documento: '20600533712',
                    //             descripcion: 'ROTURA DE 30 TONELAS DE VIDRIO',
                    //             email: 'jguillen@jcglass.com.pe',
                    //             cecos: 'BACKUS LOG AREQUIPA',
                    //             correoAdd: 'richardalvarez@lavoroperu.pe',
                    //             moneda: 'Soles',
                    //             importe: 765,
                    //             tipoDocEmitir: 'Boleta',
                    //             estado: 'Facturado',
                    //             comprobante: 'FE03-9150',
                    //             fechaEnvio: '26/10/2023',
                    //             atendido: 'Milady Valencia'
                    //         },
                    //         {
                    //             fechaReg: '10/1/2024 17:06:31',
                    //             desEmpresa: 'Obiettivo Lavoro Peru',
                    //             razonSocial: 'Ramírez Piérola Doris Melissa',
                    //             documento: '40767917',
                    //             descripcion: 'Venta de celular Samsung A10s Nro. de Serie: ZE2233DZXF Número IMEI:  355689863242989	',
                    //             email: 'jguillen@jelizabethubillus@lavoroperu.pe',
                    //             cecos: 'BACKUS L(93)MER001 - MERCK',
                    //             correoAdd: 'valeriaherrera@lavoroperu.pe',
                    //             moneda: 'Soles',
                    //             importe: 7338.98,
                    //             tipoDocEmitir: 'Boleta',
                    //             estado: 'Facturado',
                    //             comprobante: 'B003-67',
                    //             fechaEnvio: '10/01/2024',
                    //             atendido: 'Yoni Hernandez',
                    //         },
                    //         {
                    //             fechaReg: '10/1/2024 17:06:31',
                    //             desEmpresa: 'Obiettivo Lavoro Peru',
                    //             razonSocial: 'Ramírez Piérola Doris Melissa',
                    //             documento: '40767917',
                    //             descripcion: 'Venta de celular Samsung A10s Nro. de Serie: ZE2233DZXF Número IMEI:  355689863242989	',
                    //             email: 'jguillen@jelizabethubillus@lavoroperu.pe',
                    //             cecos: 'BACKUS L(93)MER001 - MERCK',
                    //             correoAdd: 'valeriaherrera@lavoroperu.pe',
                    //             moneda: 'Soles',
                    //             importe: 7338.98,
                    //             tipoDocEmitir: 'Boleta',
                    //             estado: 'Facturado',
                    //             comprobante: 'B003-67',
                    //             fechaEnvio: '10/01/2024',
                    //             atendido: 'Yoni Hernandez',
                    //         },
                    //         {
                    //             fechaReg: '10/1/2024 17:06:31',
                    //             desEmpresa: 'Obiettivo Lavoro Peru',
                    //             razonSocial: 'Ramírez Piérola Doris Melissa',
                    //             documento: '40767917',
                    //             descripcion: 'Venta de celular Samsung A10s Nro. de Serie: ZE2233DZXF Número IMEI:  355689863242989	',
                    //             email: 'jguillen@jelizabethubillus@lavoroperu.pe',
                    //             cecos: 'BACKUS L(93)MER001 - MERCK',
                    //             correoAdd: 'valeriaherrera@lavoroperu.pe',
                    //             moneda: 'Soles',
                    //             importe: 7338.98,
                    //             tipoDocEmitir: 'Boleta',
                    //             estado: 'Facturado',
                    //             comprobante: 'B003-67',
                    //             fechaEnvio: '10/01/2024',
                    //             atendido: 'Yoni Hernandez',
                    //         },
                    //         {
                    //             fechaReg: '10/1/2024 17:06:31',
                    //             desEmpresa: 'Obiettivo Lavoro Peru',
                    //             razonSocial: 'Ramírez Piérola Doris Melissa',
                    //             documento: '40767917',
                    //             descripcion: 'Venta de celular Samsung A10s Nro. de Serie: ZE2233DZXF Número IMEI:  355689863242989	',
                    //             email: 'jguillen@jelizabethubillus@lavoroperu.pe',
                    //             cecos: 'BACKUS L(93)MER001 - MERCK',
                    //             correoAdd: 'valeriaherrera@lavoroperu.pe',
                    //             moneda: 'Soles',
                    //             importe: 7338.98,
                    //             tipoDocEmitir: 'Boleta',
                    //             estado: 'Facturado',
                    //             comprobante: 'B003-67',
                    //             fechaEnvio: '10/01/2024',
                    //             atendido: 'Yoni Hernandez',
                    //         },
                    //     ]
                    // }
                    />
                </Grid>
            </Grid>
            <SpinnerLoader open={openModalLoader} title={titleModalLoader} />
        </Box>
    );
};

export default BillingEdit;