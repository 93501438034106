import axios from "axios";
import { Global } from '../utils/general';
import headerRequest from "../utils/headers"

const API_URL = Global.url;

class AssistService {
    saveMarcasAsistencia(body) {
        var ruta = `${API_URL}/assist-mark`;
        return axios
            .post(ruta, body, {
                headers: headerRequest(),
            })
            .catch((error) => {
                return error;
            });
    }

    getMarcasAsistencia(id, date) {
        var ruta = `${API_URL}/assist-mark/${id}/${date}`;
        return axios
            .get(ruta, {
                headers: headerRequest(),
            })
            .catch((error) => {
                return error;
            });
    }

    getMarcasAsistenciaRangoByPersona(id, date1, date2) {
        var ruta = `${API_URL}/assist-mark-range-person/${id}/${date1}/${date2}`;
        return axios
            .get(ruta, {
                headers: headerRequest(),
            })
            .catch((error) => {
                return error;
            });
    }

    updateMark(body, id) {
        var ruta = `${API_URL}/assist-mark/${id}`;
        return axios
            .put(ruta, body, {
                headers: headerRequest(),
            })
            .catch((error) => {
                return error;
            });
    }
}

export default new AssistService();
