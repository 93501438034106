import React, { } from 'react';
import { Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, ButtonGroup, IconButton, } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import MainPagination from '../../general/MainPagination';
import _ from 'lodash';

const columnsStaff = [
    { id: 'num', label: '#', minWidth: 50, align: 'left', },
    { id: 'sel', label: 'Sel', minWidth: 50, align: 'left', },
    { id: 'codSghh', label: 'Código', minWidth: 120, align: 'left', },
    { id: 'trabajador', label: 'Nombre', minWidth: 200, align: 'left', },
];

const columns = [
    { id: 'num', label: '#', minWidth: 50, align: 'left', },
    { id: 'id_supTurno', label: 'id', minWidth: 50, align: 'left', },
    { id: 'codSghh', label: 'Código', minWidth: 120, align: 'left', },
    { id: 'trabajador', label: 'Nombre', minWidth: 200, align: 'left', },
    { id: 'delete', label: 'Eliminar', minWidth: 200, align: 'left', },
];

const SuperTurnos = (props) => {
    const { totalPagesStaff, pageStaff, setPageStaff, idUsuario, infoShift, dataStaffs, dataSuperTurno, setDataSuperTurno, dataSuperTurnoCopy, setDataSuperTurnoCopy, } = props;

    const handleChangeCheck = (value, p_person) => {
        let updDataSuperTurnoCopy = _.cloneDeep(dataSuperTurnoCopy);
        let updDataSuperTurno = _.cloneDeep(dataSuperTurno);

        if (value === false) {
            updDataSuperTurnoCopy = updDataSuperTurnoCopy?.filter((e) => Number(e.id_persona) !== Number(p_person.id_persona));
        } else {
            let newItem = {
                "id_persona": p_person.id_persona,
                "id_turno": infoShift?.id_turno,
                "estado": 1,
                "usuario_id": idUsuario,
                "id_persona": p_person.id_persona,
                "nombres": p_person.nombres,
                "apePaterno": p_person.apePaterno,
                "apeMaterno": p_person.apeMaterno,
                "trabajador": p_person.nombres,
                "codSghh": p_person.codSghh
            }
            updDataSuperTurnoCopy.push(newItem);
            updDataSuperTurno.push(newItem);
        }

        setDataSuperTurno(updDataSuperTurno)
        return setDataSuperTurnoCopy(updDataSuperTurnoCopy);
    }

    const f_deleteSuperTurno = (idx, info) => {
        let updDataSuperTurno = _.cloneDeep(dataSuperTurno);
        let updDataSuperTurnoCopy = _.cloneDeep(dataSuperTurnoCopy);
        updDataSuperTurnoCopy[idx].estado = 2;

        setDataSuperTurno(updDataSuperTurno?.filter(e => Number(e.id_persona) !== Number(info.id_persona)))
        setDataSuperTurnoCopy(updDataSuperTurnoCopy);
    }

    return (
        <>
            <Typography gutterBottom variant="subtitle2" component="div" color={'GrayText'}>
                Lista de trabajadores
            </Typography>
            <div className='page-body-main'>
                <div className='page-body__table' style={{ overflow: 'hidden', height: '23vh' }}>
                    <div className='page-body-table'>
                        <TableContainer className='table-height' style={{ height: '23vh' }}>
                            <Table stickyHeader aria-label="sticky table">
                                <TableHead>
                                    <TableRow>
                                        {columnsStaff.map((column) => {
                                            return (
                                                <TableCell
                                                    key={column.id}
                                                    align={column.align}
                                                    style={{ minWidth: column.minWidth }}
                                                >
                                                    {column.label}
                                                </TableCell>
                                            )
                                        })}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {dataStaffs?.map((record, indexRow) => {
                                        return (
                                            <TableRow hover role="checkbox" tabIndex={-1} key={"row" + indexRow}>
                                                {columnsStaff.map((column, indexColumn) => {
                                                    const value = record[column.id];
                                                    return (
                                                        <TableCell key={'col' + indexColumn} align={column.align}>
                                                            {column.id === 'num' ?
                                                                (indexRow + 1)
                                                                : column.id === 'sel' ?
                                                                    <div className="checkbox-custom"
                                                                        style={{ display: 'flex', justifyContent: 'center' }}
                                                                    >
                                                                        <input type="checkbox"
                                                                            name="check"
                                                                            id={`checkbox-rect` + record.id_persona}
                                                                            checked={dataSuperTurno?.find(e => Number(e.id_persona) === Number(record.id_persona)) ? true : false}
                                                                            disabled={dataSuperTurno?.find(e => Number(e.id_persona) === Number(record.id_persona)) ? true : false}
                                                                            onChange={(e) => handleChangeCheck(e.target.checked, record)}
                                                                        />
                                                                        <label htmlFor={`checkbox-rect` + record.id_persona}></label>
                                                                    </div> :
                                                                    value
                                                            }
                                                        </TableCell>
                                                    );
                                                })}
                                            </TableRow>
                                        );
                                    })}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </div>
                </div>
            </div>
            {totalPagesStaff > 1 &&
                <MainPagination
                    size={'small'}
                    totalPages={totalPagesStaff}
                    page={pageStaff}
                    setPage={setPageStaff}
                />
            }
            <Typography gutterBottom variant="subtitle2" component="div" color={'GrayText'}>
                Lista de supervisores
            </Typography>
            <div className='page-body-main'>
                <div className='page-body__table' style={{ overflow: 'hidden', height: '23vh' }}>
                    <div className='page-body-table'>
                        <TableContainer className='table-height' style={{ height: '23vh' }}>
                            <Table stickyHeader aria-label="sticky table">
                                <TableHead>
                                    <TableRow>
                                        {columns.map((column) => (
                                            <TableCell
                                                key={column.id}
                                                align={column.align}
                                                style={{ minWidth: column.minWidth }}
                                            >
                                                {column.label}
                                            </TableCell>
                                        ))}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {dataSuperTurno?.map((record, indexRow) => {
                                        return (
                                            <TableRow hover role="checkbox" tabIndex={-1} key={"row" + indexRow}>
                                                {columns.map((column, indexColumn) => {
                                                    const value = record[column.id];
                                                    return (
                                                        <TableCell key={'col' + indexColumn} align={column.align}>
                                                            {column.id === 'num' ?
                                                                (indexRow + 1)
                                                                : column.id === 'delete' ?
                                                                    <ButtonGroup
                                                                        disableElevation
                                                                        variant="contained"
                                                                        aria-label="Disabled elevation buttons"
                                                                    >
                                                                        <IconButton aria-label="delete" size="small" style={{ border: `solid 1px red` }}
                                                                            onClick={() => { f_deleteSuperTurno(indexRow, record); }}
                                                                            title='Eliminar supervisor'
                                                                        >
                                                                            <DeleteIcon color={'error'} fontSize={'12px'} />
                                                                        </IconButton>
                                                                    </ButtonGroup> : value}
                                                        </TableCell>
                                                    );
                                                })}
                                            </TableRow>
                                        );
                                    })}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </div>
                </div>
            </div>
        </>
    );
}

export default SuperTurnos;