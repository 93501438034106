import React, { useState, useEffect } from 'react';
import { Box, Grid, Button, TextField, MenuItem, Select, FormControl, InputLabel } from '@mui/material';
import { getDateOL, } from '../../../utils/function';
import { CODES } from '../../../utils/responseCodes';
import { Toast } from '../../../utils/alerts';
import OrganizationalService from '../../../services/organizational';
import ReportService from '../../../services/report';
import AssistService from '../../../services/assist';
import GeneralService from '../../../services/general';
import GetAppIcon from '@mui/icons-material/GetApp';
import BodyRegisterBoss from '../../components/Register/BodyRegisterBoss';
import SpinnerLoader from '../../components/general/SpinnerLoader';

const RegisterBoss = ({ userToken }) => {
    const url = "'" + window.location.pathname + "'";
    const [openModalLoader, setOpenModalLoader] = useState(false);
    const [dateStart, setDateStart] = useState(getDateOL(new Date(), 'short', -2));
    const [dateEnd, setDateEnd] = useState(getDateOL(new Date(), 'short', 7));
    const [records, setRecords] = useState(null);
    const [statusMark, setStatusMark] = useState(null);
    const [idPerson, setIdPerson] = useState(null);
    const jefe_id = userToken?.user.persona_id;
    const [dataTrabajador, setDataTrabajador] = useState(null);

    useEffect(() => {
        init();
    }, [])
    useEffect(() => {
        if (idPerson)
            getMarcasAsistenciaRangoByPersona();
    }, [dateStart, dateEnd, idPerson])

    const init = () => {
        getMarcaEstado();
        getTrabajadoresByJefe();
    }

    const getMarcaEstado = async () => {
        await GeneralService.getMarcaEstado()
            .then(result => {
                if (result.status === CODES.SUCCESS_200) {
                    setStatusMark(result.data);
                }
            })
    }

    const getMarcasAsistenciaRangoByPersona = async () => {
        setOpenModalLoader(true);
        await AssistService.getMarcasAsistenciaRangoByPersona(idPerson, dateStart, dateEnd)
            .then(result => {
                if (result.status === CODES.SUCCESS_200) {
                    setRecords(result.data);
                }
                setOpenModalLoader(false);
            })
            .catch(err => {
                console.log('getMarcasAsistenciaRangoByPersona', err)
            })
    }

    const reporte = () => {
        if (idPerson === "" || idPerson === null || dateStart === null || dateEnd === null) {
            Toast.fire({
                icon: 'error',
                title: '!Debe seleccionar trabajador y fechas!'
            })
        } else {
            let ruta = ReportService.exportMarcasExtraExcel(idPerson, dateStart, dateEnd);
            const link = document.createElement('a');
            link.setAttribute('href', ruta);
            link.setAttribute('download', ruta);
            link.style.visibility = 'hidden';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }
    }

    const getTrabajadoresByJefe = async () => {
        try {
            try {
                const result = await OrganizationalService.getTrabajadoresByJefe(url, jefe_id);
                if (result.status === CODES.SUCCESS_200) {
                    result?.data?.forEach((element) => {
                        element.id = element.id_persona;
                        element.value = element.id_persona;
                        element.label = element.trabajador;
                    });
                    setDataTrabajador(result?.data);
                }
            } catch (error) {
                console.log(`Error en getSupervisorsArea: ${error}`);
            }
            await Promise.all([]);
        } catch (error) {
            console.log(`Error en getSupervisoresAll: ${error}`);
        } finally {
            setOpenModalLoader(false);
        }
    };

    return (
        <Box sx={{ flexGrow: 1 }}>
            <Grid container
                direction="row"
                justifyContent="center"
                alignItems="center"
                spacing={3}
                mt={1} mb={2}
            >
                <Grid item xs={6} sm={4} md={3} textAlign={'center'}>
                    <div className='group' style={{ width: '100%' }} >
                        <div className="contenido">
                            <label className="label">Fecha Inicio</label>
                            <TextField fullWidth required
                                className="input"
                                size='small'
                                id="outlined-required"
                                type={'date'}
                                value={dateStart}
                                onChange={(e) => setDateStart(e.target.value)}
                            />
                        </div>
                    </div>
                </Grid>
                <Grid item xs={6} sm={4} md={3} textAlign={'center'}>
                    <div className='group' style={{ width: '100%' }} >
                        <div className="contenido">
                            <label className="label">Fecha Fin</label>
                            <TextField fullWidth required
                                className="input"
                                size='small'
                                id="outlined-required"
                                type={'date'}
                                value={dateEnd}
                                onChange={(e) => setDateEnd(e.target.value)}
                            />
                        </div>
                    </div>
                </Grid>
            </Grid>
            <Grid container
                direction="row"
                justifyContent="center"
                alignItems="center"
                mt={2}
            >
                <Grid item xs={12} sm={8} md={7} paddingLeft={'1rem'} paddingRight={'1rem'}>
                    <Box display={'flex'} justifyContent={'center'} mb={1}>
                        <FormControl variant="filled" sx={{ m: 1, width: 350 }}>
                            <InputLabel id="demo-simple-select-filled-label">Trabajador</InputLabel>
                            <Select
                                labelId="demo-simple-select-filled-label"
                                id="demo-simple-select-filled"
                                value={idPerson || ''}
                                onChange={(e) => setIdPerson(e.target.value)}
                                color={'secondary'}
                            >
                                <MenuItem value=""><em>Seleccionar</em></MenuItem>
                                {dataTrabajador?.map((trabajador, index) => {
                                    return (
                                        <MenuItem key={'trabajador' + index} value={trabajador.value}>{trabajador?.trabajador}</MenuItem>
                                    )
                                })}
                            </Select>
                        </FormControl>
                    </Box>
                    <Box display={'flex'} justifyContent={'center'} mb={1}>
                        <Button
                            variant="contained"
                            onClick={reporte}
                            color='success'
                            startIcon={<GetAppIcon />}
                        >
                            Exportar
                        </Button>
                    </Box>
                    <BodyRegisterBoss statusMark={statusMark} records={records} setRecords={setRecords} />
                </Grid>
            </Grid>
            <SpinnerLoader open={openModalLoader} />
        </Box>
    );
};

export default RegisterBoss;