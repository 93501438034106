import React, {  } from 'react';
import { Select, MenuItem, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import AssistService from '../../../services/assist';
import { CODES } from '../../../utils/responseCodes';
import { Toast } from '../../../utils/alerts';

const columns = [
  { id: 'trabajador', label: 'Trabajador', minWidth: 250, align: 'left', },
  { id: 'fecha', label: 'Fecha', minWidth: 120, align: 'left', },
  { id: 'hora', label: 'Hora', minWidth: 120, align: 'left', },
  { id: 'desMarca', label: 'Motivo', minWidth: 120, align: 'left', },
  { id: 'descripcion', label: 'Estado', minWidth: 120, align: 'left', },
];

const BodyRegisterBoss = (props) => {
  const { statusMark, records, setRecords } = props;

  const updateMark = async (info, idx, value) => {
    let updRecords = [].concat(records);
    await AssistService.updateMark({ marcaEstado_id: value }, info.id_marca)
      .then(result => {
        if (result.status === CODES.CREATE_201) {
          info.marcaEstado_id = value;
          updRecords[idx] = info;
          Toast.fire({
            icon: 'success',
            title: '!Marca actualizada!'
          })
        } else {
          Toast.fire({
            icon: 'error',
            title: result.response.data.message
          })
        }
      })
      .catch(err => {
        console.log('getMarcasAsistenciaRangoByPersona', err)
      })
    return setRecords(updRecords)
  }

  return (
    <div className='page-body-main'>
      <div className='page-body__table' style={{ overflow: 'hidden' }}>
        <div className='page-body-table'>
          <TableContainer className='table-height'>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  {columns.map((column) => (
                    <TableCell
                      key={column.id}
                      align={column.align}
                      style={{ minWidth: column.minWidth }}
                    >
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {records?.map((record, idxRecord) => {
                  return (
                    <TableRow hover role="checkbox" tabIndex={-1} key={"row" + idxRecord}>
                      <TableCell align={'left'}>
                        {record.nombres + ' ' + record.apePaterno}
                      </TableCell>
                      <TableCell align={'left'}>
                        {record.fecha}
                      </TableCell>
                      <TableCell align={'left'}>
                        {record?.hora?.substr(0, 8)}
                      </TableCell>
                      <TableCell align={'left'}>
                        {record.desMarca}
                      </TableCell>  
                      <TableCell align={'left'}>
                        <Select
                          size='small'
                          labelId="demo-select-small"
                          id="demo-select-small"
                          value={record.marcaEstado_id || ''}
                          onChange={(e) => updateMark(record, idxRecord, e.target.value)}
                        >
                          <MenuItem value=""><em>None</em></MenuItem>
                          {statusMark?.map((status, index) => {
                            return (
                              <MenuItem key={'status' + index} value={status.id_marcaEstado}>{status.descripcion}</MenuItem>
                            )
                          })}
                        </Select>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      </div>
    </div>
  );
}
export default BodyRegisterBoss;