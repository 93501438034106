import React, { useState, useEffect } from 'react';
import { Box, Grid, TextField, } from '@mui/material';
import BodyCheckOverTime from '../../components/OverTime/BodyCheckOverTime';
import BodyTimeCompensatedCheck from '../../components/OverTime/BodyTimeCompensatedCheck';
import OverTimeService from '../../../services/overTime';
import GeneralService from '../../../services/general';
import PersonService from '../../../services/person';
import ViewSummary from './ViewSummary/ViewSummary';
import { getDateOL } from '../../../utils/function';
import { CODES } from '../../../utils/responseCodes';
import SpinnerLoader from '../../components/general/SpinnerLoader';
import ViewImage from '../../components/OverTime/ViewImage';
import xlsImg from '../../../assets/img/excel_filled.png'
import ReportService from '../../../services/report';
import { Toast } from '../../../utils/alerts';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';

const CheckOverTime = ({ userToken }) => {
    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const [openModalLoader, setOpenModalLoader] = useState(false);
    const [openViewImage, setOpenViewImage] = useState(false);

    const [rejects, setRejects] = useState(null);
    const [typeovertime, setTypeovertime] = useState(null);
    const [dateStart, setDateStart] = useState(getDateOL(new Date(), 'short', -2));//useState('2023-02-01');
    const [dateEnd, setDateEnd] = useState(getDateOL(new Date(), 'short', 7));
    const [petitions, setPetitions] = useState(null);
    const [infoImage, setInfoImage] = useState(null);
    const idPersona = userToken?.user.persona_id;
    const jefe_id = userToken?.user.persona_id;
    const [search, setSearch] = useState('');
    const [dataTimeCompesated, setDataTimeCompesated] = useState(null);
    const [timeSummary, setTimeSummary] = useState(null);
    const [searchComp, setSearchComp] = useState('');

    const [body, setBody] = useState(0); //0: body areas, 1: body turnos
    const handleChangeBody = (event, newValue) => setBody(newValue);

    const [dateStartCom, setDateStartCom] = useState(getDateOL(new Date(new Date().getFullYear(), new Date().getMonth(), 1)))
    const [dateEndCom, setDateEndCom] = useState(getDateOL(new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0)));

    useEffect(() => {
        init();
    }, [])

    useEffect(() => {
        getOverTimeByBoss();
    }, [dateStart, dateEnd])

    useEffect(() => {
        getSobreTiempoCompensadoBoss();
    }, [dateStartCom, dateEndCom])

    const init = () => {
        Promise.all([
            getMotivoRechazo(),
            getTipoSobretiempo(),
        ]).then(() => setOpenModalLoader(false));
    }

    useEffect(() => {
        if (body === 1)
            getSobreTiempoCompensadoBoss()
        else
            getOverTimeByBoss()
    }, [body])

    const getTipoSobretiempo = async () => {
        await GeneralService.getTipoSobretiempo()
            .then(result => {
                if (result.status === CODES.SUCCESS_200) {
                    result.data?.forEach(element => {
                        element.id = Number(element.corr3)
                        element.Value = Number(element.corr3)
                        element.label = element.desc1
                    })
                    setTypeovertime(result.data);
                }
                setOpenModalLoader(false);
            })
            .catch(err => {
                console.log('getMotivoRechazo', err)
            })
    }

    const getMotivoRechazo = async () => {
        await GeneralService.getMotivoRechazo()
            .then(result => {
                if (result.status === CODES.SUCCESS_200) {
                    setRejects(result.data);
                }
                setOpenModalLoader(false);
            })
            .catch(err => {
                console.log('getMotivoRechazo', err)
            })
    }

    const getOverTimeByBoss = async () => {
        setOpenModalLoader(true);
        setPetitions(null);
        await OverTimeService.getOverTimeByBoss(idPersona, dateStart, dateEnd)
            .then(result => {
                if (result.status === CODES.SUCCESS_200) {
                    setPetitions(result.data);
                } else {
                    Toast.fire({
                        icon: 'error',
                        title: result?.response?.data?.message
                    })
                }
                setOpenModalLoader(false);
            })
            .catch(err => {
                console.log('getOverTimeByBoss', err)
            })
    }

    const getSobreTiempoCompensadoBoss = async () => {
        if (dateEndCom < dateStartCom) {
            Toast.fire({
                icon: 'warning',
                title: 'La fecha final debe ser mayor o igual a la inicial'
            })
            return;
        }
        setOpenModalLoader(true);
        try {
            setDataTimeCompesated(null)
            const result = await OverTimeService.getSobreTiempoCompensadoBoss(idPersona, dateStartCom, dateEndCom);
            if (result.status === CODES.SUCCESS_200) {
                setDataTimeCompesated(result.data);
            } else {
                Toast.fire({
                    icon: 'warning',
                    title: result?.response?.data?.message
                })
            }
        } catch (error) {
            console.log(`Error en getBolsaSobreTiempo: ${error}`);
        } finally {
            setOpenModalLoader(false);
        }
    }

    const reporte = async () => {
        let ruta = ReportService.exportHorasExtraExcel(jefe_id, dateStart, dateEnd);
        const link = document.createElement('a');
        link.setAttribute('href', ruta);
        link.setAttribute('download', ruta);
        link.style.visibility = 'hidden';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);

    }

    const searcher = (e) => {
        setSearch(e.target.value);
    }

    const searcherComp = (e) => {
        setSearchComp(e.target.value);
    }

    /***/
    const viewSummary = async (persona_id) => {
        try {
            setOpenModalLoader(true);
            const result = await PersonService.getTimeSummaryWorker(persona_id, dateStartCom, dateEndCom);
            if (result.status === CODES.SUCCESS_200) {
                setTimeSummary(result.data);
                handleOpen();
            } else {
                Toast.fire({
                    icon: 'error',
                    title: result?.response?.data?.message || 'Error durante la busqueda'
                })
            }
            return [];
        } catch (error) {
            console.log(`Error en saveApproveCompensation: ${error}`);
        } finally {
            setOpenModalLoader(false);
        }
    }

    return (
        <Box sx={{ flexGrow: 1 }}>
            <ViewSummary
                open={open}
                handleClose={handleClose}
                timeSummary={timeSummary}
                setTimeSummary={setTimeSummary}
            />
            <Box sx={{ width: '100%', bgcolor: 'background.paper' }}>
                <Tabs value={body} onChange={handleChangeBody} centered>
                    <Tab label="Sobre Tiempo" />
                    <Tab label="Compensado" />
                </Tabs>
            </Box>
            {body === 0 ?
                <>
                    <Grid container direction="row" justifyContent="center" alignItems="center" spacing={3} >
                        <Grid item xs={6} sm={4} md={3} display={'flex'} justifyContent={'center'}>
                            <div className='group' style={{ width: '100%' }} >
                                <div className="contenido">
                                    <label className="label">Fecha Inicio</label>
                                    <TextField fullWidth required
                                        className="input"
                                        size='small'
                                        id="outlined-required"
                                        type={'date'}
                                        value={dateStart}
                                        onChange={(e) => setDateStart(e.target.value)}
                                    />
                                </div>
                            </div>
                        </Grid>
                        <Grid item xs={6} sm={4} md={3} display={'flex'} justifyContent={'center'}>
                            <div className='group' style={{ width: '100%' }} >
                                <div className="contenido">
                                    <label className="label">Fecha Fin</label>
                                    <TextField fullWidth required
                                        className="input"
                                        size='small'
                                        id="outlined-required"
                                        type={'date'}
                                        value={dateEnd}
                                        onChange={(e) => setDateEnd(e.target.value)}
                                    />
                                </div>
                            </div>
                        </Grid>
                        <Grid item md={1} display={'flex'} justifyContent={'center'}>
                            <div className='group' style={{ width: '100%' }} >
                                <button
                                    type='button'
                                    className='btn btn-outline-success'
                                    onClick={reporte}
                                >
                                    <img src={xlsImg} alt='Reporte excel' width={25} />
                                </button>
                            </div>
                        </Grid>
                    </Grid>
                    <Grid container direction="row" justifyContent="center" alignItems="center" mt={2} >
                        <Grid item xs={12} sm={10} md={10} paddingLeft={'1rem'} paddingRight={'1rem'} style={{ paddingBottom: 50 }}>
                            <TextField id="input-with-sx" label="Busqueda por trabajador" variant="standard"
                                fullWidth
                                style={{ marginBottom: 20 }}
                                value={search}
                                onChange={searcher}
                            />
                            <BodyCheckOverTime
                                search={search}
                                setOpenViewImage={setOpenViewImage}
                                typeovertime={typeovertime}
                                rejects={rejects}
                                petitions={petitions}
                                setPetitions={setPetitions}
                                setInfoImage={setInfoImage}
                            />
                        </Grid>
                    </Grid>
                </>
                : <>
                    <Grid container direction="row" justifyContent="center" alignItems="center" spacing={3} >
                        <Grid item xs={6} sm={4} md={3} display={'flex'} justifyContent={'center'}>
                            <div className='group' style={{ width: '100%' }} >
                                <div className="contenido">
                                    <label className="label">Fecha Inicio</label>
                                    <TextField fullWidth required
                                        className="input"
                                        size='small'
                                        id="outlined-required"
                                        type={'date'}
                                        value={dateStartCom}
                                        onChange={(e) => setDateStartCom(e.target.value)}
                                    />
                                </div>
                            </div>
                        </Grid>
                        <Grid item xs={6} sm={4} md={3} display={'flex'} justifyContent={'center'}>
                            <div className='group' style={{ width: '100%' }} >
                                <div className="contenido">
                                    <label className="label">Fecha Fin</label>
                                    <TextField fullWidth required
                                        className="input"
                                        size='small'
                                        id="outlined-required"
                                        type={'date'}
                                        value={dateEndCom}
                                        onChange={(e) => setDateEndCom(e.target.value)}
                                    />
                                </div>
                            </div>
                        </Grid>
                    </Grid>
                    <Grid container direction="row" justifyContent="center" alignItems="center">
                        <Grid item xs={12} sm={10} md={10} paddingLeft={'1rem'} paddingRight={'1rem'}>
                            <TextField id="input-with-sx" label="Busqueda por trabajador" variant="standard"
                                fullWidth
                                placeholder='Buscar por nombre del trabajador'
                                style={{ marginBottom: 20 }}
                                value={searchComp}
                                onChange={searcherComp}
                            />
                            <BodyTimeCompensatedCheck
                                searchComp={searchComp}
                                setOpenModalLoader={setOpenModalLoader}
                                dataTimeCompesated={dataTimeCompesated}
                                setDataTimeCompesated={setDataTimeCompesated}
                                viewSummary={viewSummary}
                            />
                        </Grid>
                    </Grid>
                </>
            }
            <ViewImage open={openViewImage} setOpen={setOpenViewImage} infoImage={infoImage} />
            <SpinnerLoader open={openModalLoader} />
        </Box>
    );
};

export default CheckOverTime;