import React, { } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, } from '@mui/material';
import { convertHour } from '../../../utils/function';

const columns = [
  { id: 'id_fechaCom', label: '#', width: 100, align: 'left', },
  { id: '#', label: 'Equipo', width: 200, align: 'left', },
  { id: '##', label: 'Modelo', width: 200, align: 'center'},
  { id: '###', label: 'Acciones', minWidth: 120, align: 'center', },
];

const BodyEquipment = (props) => {
  const { dataTimeCompesated, } = props;

  return (
    <div className='page-body-main'>
      <div className='page-body__table page-body__table--overtime' style={{ overflow: 'hidden' }}>
        <div className='page-body-table'>
          <TableContainer className='table-height table-height--overtime'>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  {columns.map((column) => (
                    <TableCell
                      key={column.id}
                      align={column.align}
                      style={{ width: column.width, maxWidth: column.width }}
                    >
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      </div>
    </div>
  );
}
export default BodyEquipment;