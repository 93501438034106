import React, { useState, useEffect } from "react";
import logoImg from "../../assets/img/logo.png";
import logoShortImg from "../../assets/img/logo_short.png";
import AppBar from "@mui/material/AppBar";
import { Box, ListItemIcon, Toolbar, IconButton, Typography, Menu, Container, Avatar, Tooltip, MenuItem, } from "@mui/material";
import { useNavigate } from "react-router-dom";
import avatarImg from "../../assets/img/avatar.png";
import AddBoxIcon from "@mui/icons-material/AddBox";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import HowToRegIcon from "@mui/icons-material/HowToReg";
import AppRegistrationIcon from "@mui/icons-material/AppRegistration";
import PowerSettingsNewIcon from "@mui/icons-material/PowerSettingsNew";
import PsychologyAltIcon from "@mui/icons-material/PsychologyAlt";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import HourglassBottomIcon from "@mui/icons-material/HourglassBottom";
import EngineeringIcon from "@mui/icons-material/Engineering";
import SupervisedUserCircleIcon from '@mui/icons-material/SupervisedUserCircle';
import ManageHistoryIcon from '@mui/icons-material/ManageHistory';
import EventRepeatIcon from '@mui/icons-material/EventRepeat';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import GradingIcon from '@mui/icons-material/Grading';
import AuthService from "../../services/auth";
import { CODES } from "../../utils/responseCodes";
import Swal from "sweetalert2";

const Headboard = ({ userToken, menu, pathInit }) => {
  const navigate = useNavigate();
  const [anchorElUser, setAnchorElUser] = useState(null);
  const handleOpenUserMenu = (event) => setAnchorElUser(event.currentTarget);
  const handleCloseUserMenu = () => setAnchorElUser(null);
  const [items, setItems] = useState(null);

  useEffect(() => {
    let newItems = [];
    menu?.forEach((item) => {
      newItems.push({
        label: item.desPagina,
        key: item.link,
        icon: searchIcon(item.link),
      });
    });
    setItems(newItems);
  }, [menu]);

  const searchIcon = (icon) => {
    switch (icon) {
      case `${(pathInit || '')}/information`:
        return <HowToRegIcon fontSize="small" color="primary" />;
      case `${(pathInit || '/')}`:
        return <CalendarMonthIcon fontSize="small" />;
      case `${(pathInit || '')}/overtime`:
        return <AddBoxIcon fontSize="small" color="success" />;
      case `${(pathInit || '')}/check-overtime`:
        return <HourglassBottomIcon fontSize="small" color="success" />;
      case `${(pathInit || '')}/config-worker`:
        return <ManageAccountsIcon fontSize="small" color="primary" />;
      case `${(pathInit || '')}/register`:
        return <AppRegistrationIcon fontSize="small" color="tertiary" />;
      case `${(pathInit || '')}/register-boss`:
        return <AppRegistrationIcon fontSize="small" color="tertiary" />;
      case `${(pathInit || '')}/compensation`:
        return <AppRegistrationIcon fontSize="small" color="tertiary" />;
      case `${(pathInit || '')}/help`:
        return <PsychologyAltIcon fontSize="small" color="quaternary" />;
      case `${(pathInit || '')}/supervisors`:
        return <SupervisedUserCircleIcon fontSize="small" color="quaternary" />;
      case `${(pathInit || '')}/execution-big-query`:
        return <ManageHistoryIcon fontSize="small" color="success" />;
      case `${(pathInit || '')}/execution-rotation`:
        return <ManageHistoryIcon fontSize="small" color="success" />;
      case `${(pathInit || '')}/view-time-compensated`:
        return <EventRepeatIcon fontSize="small" color="primary" />;
      case `${(pathInit || '')}/trusted-worker`:
        return <AdminPanelSettingsIcon fontSize="small" color="success" />;
      case `${(pathInit || '')}/mof`:
        return <GradingIcon fontSize="small" color="success" />;
      default:
        return <EngineeringIcon fontSize="small" color="primary" />;
    }
  };

  const ApoyoTI = () => {
    Swal.fire({
      title: "Apoyo TI",
      html: `<b>Correo : </b>soporteti@obiettivolavoro.pe<br />
                <b>Telefono: </b>054-211187<br />`,
      icon: "info",
      showClass: {
        popup: "animate__animated animate__fadeInDown",
      },
      hideClass: {
        popup: "animate__animated animate__fadeOutUp",
      },
    });
  };

  const Logout = async () => {
    let token = userToken?.token;

    let body = { Authorization: "Bearer " + token };
    Swal.fire({
      title: "¿Seguro de Cerrar Sesión?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#218838",
      cancelButtonColor: "#dc3545",
      confirmButtonText: "Si, Cerrar Sesión",
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire({
          icon: "error",
          title: "Cerrando...",
          timer: 4000,
        });
        setTimeout(
          AuthService.signOut(body).then((result) => {
            if (
              result.status === CODES.BAD_TOKEN_498 ||
              result.status === CODES.SUCCESS_200
            ) {
              localStorage.removeItem("infoAssist");
              navigate(`${(pathInit || '/')}`);
              window.location.reload();
            }
          }),
          10000
        );
      }
    });
  };

  return (
    <AppBar
      position="fixed"
      sx={{
        background: { xs: "#2E3B55", md: "#CDDCF9" },
        maxWidth: { xs: "97%", md: "99%" },
        borderRadius: 5,
        mt: 1,
        mr: 1,
        mb: 1,
        ml: 1,
        pt: 1,
        pb: 1,
        zIndex: 2,
      }}
    >
      <Container maxWidth="xl">
        <Toolbar disableGutters variant="dense">
          {/* logo navbar web */}
          <Box
            sx={{
              maxWidth: "3%",
              justifyContent: "center",
              display: { xs: "none", md: "flex" },
            }}
          >
            <img
              src={logoShortImg}
              width="60%"
              alt="logo"
              onClick={() => navigate(`${(pathInit || '/')}`)}
              style={{ cursor: "pointer" }}
            />
          </Box>

          {/* logo navbar movil */}
          <Box
            sx={{
              justifyContent: "center",
              display: { xs: "flex", md: "none" },
              m: "-1% 0",
            }}
          >
            <img
              src={logoImg}
              width="22%"
              alt="logo"
              onClick={() => navigate(`${(pathInit || '/')}`)}
              style={{ cursor: "pointer" }}
            />
          </Box>

          {/* menu para navbar web */}
          <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}>
            <Typography
              variant="h6"
              component="div"
              sx={{ flexGrow: 1, fontSize: "bold" }}
              color="#2E3B55"
            >
              Lavoro Assistance
            </Typography>
          </Box>

          {/* menu avatar */}
          <Box
            sx={{
              flexGrow: 0,
              margin: { xs: "-10% -2%", md: "-10% -1%" },
            }}
          >
            <Tooltip title="Open settings">
              <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                <Avatar
                  alt="Remy Sharp"
                  src={avatarImg}
                  sx={{ bgcolor: "#2E3B55" }}
                />
              </IconButton>
            </Tooltip>
            <Menu
              sx={{ mt: "45px" }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
              {items?.map((setting) => {
                return (
                  <MenuItem
                    key={setting.label}
                    onClick={() => navigate(`${(pathInit || '')}${setting.key}`)}
                  >
                    <ListItemIcon>{setting.icon}</ListItemIcon>
                    <Typography variant="body2" color="text.secondary">
                      {setting.label}
                    </Typography>
                  </MenuItem>
                );
              })}
              <MenuItem onClick={ApoyoTI}>
                <ListItemIcon>
                  <PsychologyAltIcon fontSize="small" color="error" />
                </ListItemIcon>
                <Typography variant="body2" color="text.secondary">
                  Apoyo TI
                </Typography>
              </MenuItem>
              <MenuItem onClick={Logout}>
                <ListItemIcon>
                  <PowerSettingsNewIcon fontSize="small" color="error" />
                </ListItemIcon>
                <Typography variant="body2" color="text.secondary">
                  Cerrar Sesión
                </Typography>
              </MenuItem>
            </Menu>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
};

export default Headboard;
