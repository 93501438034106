import React, { useState, useEffect } from 'react';
import { Box, Grid, Button, TextField, } from '@mui/material';
import GetAppIcon from '@mui/icons-material/GetApp';
import BodyRegister from '../../components/Register/BodyRegister';
import AssistService from '../../../services/assist';
import { getDateOL } from '../../../utils/function';
import { CODES } from '../../../utils/responseCodes';
import SpinnerLoader from '../../components/general/SpinnerLoader';
import ReportService from '../../../services/report'

const Register = ({ userToken }) => {
    const [openModalLoader, setOpenModalLoader] = useState(true);
    const [dateStart, setDateStart] = useState(getDateOL(new Date(), 'short', -2));
    const [dateEnd, setDateEnd] = useState(getDateOL(new Date(), 'short', 7));
    const [records, setRecords] = useState(null);
    const idPersona = userToken?.user.persona_id;

    useEffect(() => {
        init();
    }, [])
    useEffect(() => {
        setRecords(null);
        getMarcasAsistenciaRangoByPersona();
    }, [dateStart, dateEnd])
    const init = () => {
        getMarcasAsistenciaRangoByPersona();
    }
    const getMarcasAsistenciaRangoByPersona = async () => {
        setOpenModalLoader(true);
        await AssistService.getMarcasAsistenciaRangoByPersona(idPersona, dateStart, dateEnd)
            .then(result => {
                if (result.status === CODES.SUCCESS_200) {
                    setRecords(result.data);
                }
                setOpenModalLoader(false);
            })
            .catch(err => {
                console.log('getMarcasAsistenciaRangoByPersona', err)
            })
    }

    const reporte = async () => {
        let ruta = ReportService.exportMarcasExtraExcel(idPersona, dateStart, dateEnd);
        const link = document.createElement('a');
        link.setAttribute('href', ruta);
        link.setAttribute('download', ruta);
        link.style.visibility = 'hidden';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);            
     }

    return (
        <Box sx={{ flexGrow: 1 }}>
            <Grid container
                direction="row"
                justifyContent="center"
                alignItems="center"
                spacing={3}
                mt={1} mb={2}
            >
                <Grid item xs={6} sm={4} md={3} display={'flex'} justifyContent={'center'}>
                    <div className='group' style={{ width: '100%' }} >
                        <div className="contenido">
                            <label className="label">Fecha Inicio</label>
                            <TextField fullWidth required
                                className="input"
                                size='small'
                                id="outlined-required"
                                type={'date'}
                                value={dateStart}
                                onChange={(e) => setDateStart(e.target.value)}
                            />
                        </div>
                    </div>
                </Grid>
                <Grid item xs={6} sm={4} md={3} display={'flex'} justifyContent={'center'}>
                    <div className='group' style={{ width: '100%' }} >
                        <div className="contenido">
                            <label className="label">Fecha Fin</label>
                            <TextField fullWidth required
                                className="input"
                                size='small'
                                id="outlined-required"
                                type={'date'}
                                value={dateEnd}
                                onChange={(e) => setDateEnd(e.target.value)}
                            />
                        </div>
                    </div>
                </Grid>
            </Grid>
            <Grid container
                direction="row"
                justifyContent="center"
                alignItems="center"
                mt={2}
            >
                <Grid item xs={12} sm={8} md={6} paddingLeft={'1rem'} paddingRight={'1rem'}>
                    <Box display={'flex'} justifyContent={'center'} mb={1}>
                        <Button
                            variant="contained"
                            onClick={reporte}
                            color='success'
                            startIcon={<GetAppIcon />}
                        >
                            Exportar
                        </Button>
                    </Box>
                    <BodyRegister records={records} />
                </Grid>
            </Grid>
            <SpinnerLoader open={openModalLoader}/>
        </Box>
    );
};

export default Register;