import React, { useState } from 'react';
import { Box, } from '@mui/material';
import './clockA.scss'
const ClockA = () => {
    const deg = 6;
    const [hh, setHh] = useState(null)
    const [mm, setMm] = useState(null)
    const [ss, setSs] = useState(null)

    const calculeTime = () => {
        // let day = new Date();
        // let hh = day.getHours() * 30;
        // let mm = day.getMinutes() * deg;
        // let ss = day.getSeconds * deg;
        let day = new Date();
        setHh(day.getHours() * 30);
        setMm(day.getMinutes() * deg);
        setSs(day.getSeconds() * deg);
    }
    setTimeout(calculeTime, 1000);

    return (
        <Box className="boxA" mb={1}>
            <Box className="bodyA">
                <Box className="clockA">
                    <Box className="hour">
                        <Box className="hr"
                            sx={{
                                transform: `rotateZ(${(hh) + (mm / 12)}deg)`
                            }}
                        ></Box>
                    </Box>
                    <Box className="min">
                        <Box className="mn"
                            sx={{
                                transform: `rotateZ(${mm}deg)`
                            }}
                        ></Box>
                    </Box>
                    <Box className="sec">
                        <Box className="sc"
                            sx={{
                                transform: `rotateZ(${ss}deg)`
                            }}
                        ></Box>
                    </Box>
                </Box>
            </Box>
        </Box>
    );
};

export default ClockA;