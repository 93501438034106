import { Global } from '../utils/general';

const API_URL = Global.url;

class ReportService {
    exportAsistenciaExcel = function (sede_id, datei, datef) {
        let ruta = `${API_URL}/report/assistance/${sede_id}/${datei}/${datef}`;
        return ruta;
    }

    exportHorasExtraExcel = function (jefe_id, datei, datef) {
        let ruta = `${API_URL}/report/sobreTiempo/${jefe_id}/${datei}/${datef}`;
        return ruta;
    }

    exportMarcasExtraExcel = function (trabajador_id, datei, datef) {
        let ruta = `${API_URL}/report/mark/${trabajador_id}/${datei}/${datef}`;
        return ruta;
    }

}

export default new ReportService();
