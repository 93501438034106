import React from "react";
import "./Styles.scss";

const MainButton = (props) => {
    const { onClick, title, color, background, customStyle, } = props;
    return (
        <button
            className={`main-button`}
            onClick={onClick}
            style={{
                ...customStyle,
                color: color,
                background: background,
                border: `1px solid ${color}`
            }}
        >
            {title}
        </button>
    )
}

export default MainButton;