import * as React from 'react';
import { Avatar, Button, TextField, Paper, Box, Grid, Typography, CssBaseline, Link, } from '@mui/material';
import SpinnerLoader from '../../components/general/SpinnerLoader';
import logoShortImg from '../../../assets/img/logo_short.png';
import login1 from '../../../assets/img/login/login1.jpg';
import login2 from '../../../assets/img/login/login2.jpg';
import login3 from '../../../assets/img/login/login3.jpg';
import login4 from '../../../assets/img/login/login4.jpg';
import login5 from '../../../assets/img/login/login5.jpg';
import AuthService from '../../../services/auth';
import MailsService from '../../../services/mails';
import { CODES } from '../../../utils/responseCodes';
import { Toast } from '../../../utils/alerts';
import { clearBrowserCache } from '../../../utils/function';

const lstImg = [login1, login2, login3, login4, login5];
const random = Math.floor(Math.random() * 999);

const Login = ({ setUserToken }) => {
    const [usuario, setUsuario] = React.useState(null);
    const [openModalLoader, setOpenModalLoader] = React.useState(false);

    const handleSubmit = async (event) => {
        event.preventDefault();
        try {
            setOpenModalLoader(true);
            const data = new FormData(event.currentTarget);
            data.append("sistema", 'la');
            const result = await AuthService.signIn(data);
            if (result?.status === CODES.SUCCESS_200) {
                localStorage.setItem('infoAssist', JSON.stringify(result?.data));
                setUserToken(result?.data);
                clearBrowserCache();
            } else {
                Toast.fire({
                    icon: 'error',
                    title: (result?.data?.message || '!Error de credenciales!')
                })
            }
        } catch (error) {
            console.log(`Error login: ${error}`)
        } finally {
            setOpenModalLoader(false);
        }
    };

    const handleRecoveryPassword = async () => {
        if (usuario === '' || !usuario || !isNaN(usuario)) {
            Toast.fire({
                icon: 'warning',
                title: "Debe ingresar un usuario valido"
            })
            return;
        }
        setOpenModalLoader(true);
        try {
            const result = await MailsService.recoveryPassword(usuario);
            if (result.status === CODES.SUCCESS_200) {
                Toast.fire({
                    icon: 'success',
                    title: (result.data.message || '!Se le enviaron indicaciones al correo!')
                })
            } else {
                Toast.fire({
                    icon: 'success',
                    title: (result?.response?.data?.message || 'Error al solicitar cambio')
                })
            }
            return [];
        } catch (error) {
            console.log(`Error en getUsuarioAreas: ${error}`);
        } finally {
            setOpenModalLoader(false);
        }
    };

    return (
        <Grid container component="main" sx={{ height: '100vh' }}>
            <SpinnerLoader open={openModalLoader} />
            <CssBaseline />
            <Grid
                item
                xs={false}
                sm={4}
                md={7}
                sx={{
                    backgroundImage: `url(${lstImg[random % lstImg?.length]})`,//'url(https://source.unsplash.com/random)',
                    backgroundRepeat: 'no-repeat',
                    backgroundColor: (t) => t.palette.mode === 'light' ? t.palette.grey[50] : t.palette.grey[900],
                    backgroundSize: 'cover', //'contain',
                    backgroundPosition: 'center',
                }}
            />
            <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
                <Box
                    sx={{
                        my: 8,
                        mx: 4,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    <Avatar sx={{ m: 1, }}>
                        <img src={logoShortImg} width='60%' alt="logo" />
                    </Avatar>
                    <Typography component="h1" variant="h5">
                        Login
                    </Typography>
                    <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 1 }}>
                        <TextField
                            margin="normal"
                            id="usuario"
                            label="Usuario"
                            name="usuario"
                            autoComplete="user"
                            required fullWidth autoFocus
                            value={usuario || ''}
                            onChange={(event) => setUsuario(event.target.value)}
                        />
                        <TextField
                            margin="normal"
                            name="clave"
                            label="Contraseña"
                            type="password"
                            id="clave"
                            autoComplete="current-password"
                            required fullWidth
                        />
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            sx={{ mt: 3, mb: 2 }}
                        >
                            Inicie sesión
                        </Button>
                        <Grid container>
                            <Grid item xs>
                                <Link variant="body2" onClick={() => handleRecoveryPassword()} style={{ cursor: 'pointer' }}>
                                    Olvide constraseña
                                </Link>
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
            </Grid>
        </Grid>
    );
}
export default Login;