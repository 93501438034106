import React, { useState, useEffect } from 'react';
import { Box, Grid, Typography, TextField } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { CODES } from '../../../utils/responseCodes';
import { Toast } from '../../../utils/alerts';
import { getDateOL, formatDate } from '../../../utils/function';
import MainButton from '../../components/general/MainButton';
import BatService from '../../../services/bat';
import SpinnerLoader from '../../components/general/SpinnerLoader';

const ExecuteBatRotation = ({ userToken }) => {
  const theme = useTheme();
  const [openModalLoader, setOpenModalLoader] = useState(false);
  const [titleModalLoader, setTitleModalLoader] = useState(null);
  const [msgResponse, setMsgResponse] = useState('Esperando Inicio...');
  const [dateStart, setDateStart] = useState(getDateOL());
  const [fechaMes, setFechaMes] = useState(null);
  const [fechaMesAnt, setFechaMesAnt] = useState(null);

  useEffect(() => {
    let fecha = new Date(`${dateStart}T00:00:00`);
    let fec1 = new Date(fecha.getFullYear(), fecha.getMonth() + 1, 0);
    fec1 = formatDate(fec1, 'date');
    let fec2 = new Date(fecha.getFullYear(), fecha.getMonth(), 0);
    fec2 = formatDate(fec2, 'date');
    setFechaMes(fec1);
    setFechaMesAnt(fec2);
  }, [dateStart])

  const executionValidation = async () => {
    try {
      setOpenModalLoader(true);
      setTitleModalLoader('Validando ejecucion de Rotación!');
      const result = await BatService.executionValidation(2); //! 1 = bigquery.bat, se consulta si esta bloqueado 
      if (result?.status === CODES.SUCCESS_200) {
        if (Number(result?.data?.nro1) !== 1) {
          executeRotacion();
        } else {
          Toast.fire({
            icon: 'info',
            title: 'Actualmente el proceso esta en ejecución (espere a que termine)!',
          });
          setOpenModalLoader(false);
          setTitleModalLoader(null);
        }
      } else {
        Toast.fire({
          icon: 'error',
          title: 'Error comuniquese con TI!',
        });
        setOpenModalLoader(false);
        setTitleModalLoader(null);
      }
      return [];
    } catch (error) {
      setOpenModalLoader(false);
      setTitleModalLoader(null);
      console.log(`Error en executionValidation: ${error}`);
    }
  };

  const executeRotacion = async () => {
    try {
      console.log('🚀🥵 ~ Inicio executeRotacion ~', new Date());
      setOpenModalLoader(true);
      setTitleModalLoader('Espere un momento: estamos ejecutando el programa rotacion ...');
      const result = await BatService.getExecuteRotacion(fechaMes, fechaMesAnt);
      console.log("🚀🥵 ~  executeRotacion ~ result:", result)
      if (result.status === CODES.SUCCESS_200) {
        Toast.fire({
          icon: 'success',
          title: 'Fin de la ejecución!',
        });
        setMsgResponse('Se termino la ejecución de rotacion');
        setOpenModalLoader(false);
        console.log('🚀🥵 ~ Fin executeRotacion ~', new Date());
      } else {
        setMsgResponse('Error en la ejecución comuniquese con TI');
        Toast.fire({
          icon: 'error',
          title: 'Error en la ejecución comuniquese con TI!',
        });
        setOpenModalLoader(false);
        console.log('🚀🥵 ~ Fin executeRotacion ~', new Date());
      }
      return [];
    } catch (error) {
      console.log(`Error en getAsistenciaCondicion: ${error}`);
    }
  };

  return (
    <Box sx={{ flexGrow: 1 }} mt={3}>
      <Grid container direction="row" justifyContent="center" alignItems="center">
        <Grid item xs={2} style={{ display: 'flex', justifyContent: 'center' }}>
          <div className='group' style={{ width: '100%' }} >
            <div className="contenido">
              <label className="label">Fecha Inicio</label>
              <TextField fullWidth required
                className="input"
                size='small'
                id="outlined-required"
                type={'date'}
                value={dateStart}
                onChange={(e) => setDateStart(e.target.value)}
              />
            </div>
          </div>
        </Grid>
      </Grid>
      <Grid container direction="row" justifyContent="center" alignItems="center" sx={{ marginTop: '1rem' }}      >
        <Grid item xs={12}
          style={{ display: 'flex', justifyContent: 'center', paddingTop: '1%', }}         >
          <MainButton
            title="Ejecutar Rotación"
            onClick={executionValidation}
            color="white"
            background={theme.palette.primary.main}
          />
        </Grid>

        <Grid item xs={12}
          style={{
            display: 'flex',
            justifyContent: 'center',
            paddingTop: '1%',
          }}
        >
          <Typography variant="h6" color="text.primary">
            {msgResponse}
          </Typography>
        </Grid>
      </Grid>
      <SpinnerLoader
        open={openModalLoader}
        title={titleModalLoader}
      />
    </Box>
  );
};

export default ExecuteBatRotation;
