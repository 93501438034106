import React from 'react';
import { useTheme } from '@mui/material/styles';
import { Typography, Divider, Modal, Card, CardContent, } from '@mui/material';
import { convertHour } from '../../../../utils/function';

const ViewSummary = (props) => {
    const { open, handleClose, timeSummary, setTimeSummary } = props;
    const theme = useTheme();

    const cleanInfo = () => {
        handleClose();
        setTimeSummary(null);
    }
    return (
        <Modal aria-labelledby="parent-modal-title" aria-describedby="parent-modal-description" open={open} onClose={cleanInfo}>
            <Card className="modal-customizar modal-customizar__xsmall">
                <CardContent sx={{ m: 1, width: 400 }}>
                    <Typography gutterBottom variant="subtitle1" component="div">
                        <strong>{timeSummary?.trabajador}</strong>
                    </Typography>
                    <Divider />
                    <Typography variant="body1" color="text.secondary" m={1}>
                        <strong>Tiempo disponible: </strong>{convertHour(timeSummary?.disponible)}
                    </Typography>
                    <Divider><strong>COMPENSACIÓN</strong></Divider>
                    <ul>
                        <li>
                            <Typography variant="body2" color="text.secondary">
                                <strong>Tiempo pendientes: </strong>&nbsp;&nbsp;{convertHour(timeSummary?.TC_Pendientes)}
                            </Typography>
                        </li>
                        <li>
                            <Typography variant="body2" color="text.secondary">
                                <strong>Tiempo aprobados: </strong>&nbsp;&nbsp;{convertHour(timeSummary?.TC_Aprobados)}
                            </Typography>
                        </li>
                        <li>
                            <Typography variant="body2" color="text.secondary">
                                <strong>Tiempo rechazado: </strong>&nbsp;&nbsp;{convertHour(timeSummary?.TC_Rechazados)}
                            </Typography>
                        </li>
                    </ul>
                    <Divider><strong>SOLICITUDES</strong></Divider>
                    <Divider variant="middle"><strong>HORAS EXTRA</strong></Divider>
                    <ul>
                        <li>
                            <Typography variant="body2" color="text.secondary">
                                <strong>Tiempo pendientes: </strong>&nbsp;&nbsp;{convertHour(timeSummary?.HE_Pendientes)}
                            </Typography>
                        </li>
                        <li>
                            <Typography variant="body2" color="text.secondary">
                                <strong>Tiempo aprobados: </strong>&nbsp;&nbsp;{convertHour(timeSummary?.HE_Aprobados)}
                            </Typography>
                        </li>
                        <li>
                            <Typography variant="body2" color="text.secondary">
                                <strong>Tiempo rechazado: </strong>&nbsp;&nbsp;{convertHour(timeSummary?.HE_Rechazados)}
                            </Typography>
                        </li>
                    </ul>
                    <Divider variant="middle"><strong>COMPENSACIÓN</strong></Divider>
                    <ul>
                        <li>
                            <Typography variant="body2" color="text.secondary">
                                <strong>Tiempo pendientes: </strong>&nbsp;&nbsp;{convertHour(timeSummary?.CO_Pendientes)}
                            </Typography>
                        </li>
                        <li>
                            <Typography variant="body2" color="text.secondary">
                                <strong>Tiempo aprobados: </strong>&nbsp;&nbsp;{convertHour(timeSummary?.CO_Aprobados)}
                            </Typography>
                        </li>
                        <li>
                            <Typography variant="body2" color="text.secondary">
                                <strong>Tiempo rechazado: </strong>&nbsp;&nbsp;{convertHour(timeSummary?.CO_Rechazados)}
                            </Typography>
                        </li>
                    </ul>
                </CardContent>
            </Card>
        </Modal>
    );
};

export default ViewSummary;