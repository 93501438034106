import React, { useEffect } from "react";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, ButtonGroup, IconButton } from "@mui/material";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import EditIcon from '@mui/icons-material/Edit';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { CODES } from "../../../utils/responseCodes";
import { Toast } from "../../../utils/alerts";
import MofService from "../../../services/mof";

const columns = [
  { id: "", label: "#", minWidth: 50, align: "left" },
  { id: "codMof", label: "Codigo", minWidth: 150, align: "left" },
  { id: "cliente.empresa.desEmpresa", label: "Empresa", minWidth: 200, align: "left" },
  { id: "cliente.desCliente", label: "Cliente", minWidth: 200, align: "left" },
  { id: "puesto.desPuesto", label: "Puesto", minWidth: 200, align: "left" },
  { id: "desEstado", label: "Estado", minWidth: 100, align: "left" },
  { id: "trabajador", label: "Ult. Modifiación", minWidth: 200, align: "left" },
  { id: "actions", label: "Acciones", minWidth: 120, align: "center" },
];


const columns2 = [
  { id: "", label: "#", minWidth: 50, align: "left" },
  { id: "codMof", label: "MOF", minWidth: 150, align: "left" },
  { id: "desEmpresa", label: "Empresa", minWidth: 200, align: "left" },
  { id: "desCliente", label: "Cliente", minWidth: 200, align: "left" },
  { id: "desPuesto", label: "Puesto", minWidth: 200, align: "left" },
  { id: "desEstado", label: "Estado", minWidth: 150, align: "left" },
  { id: "trabajador", label: "Ult. Modifiación", minWidth: 200, align: "left" },
  { id: "actions", label: "Acciones", minWidth: 120, align: "center" },
];

const BodyMof = (props) => {
  const { infoConformidad, handleOpen, setOpenModalLoader, setTitleModalLoader, listaMof, setListaMof, listarMof, getDatosMof, setPuestoId,
    setCompanieId, setClienteId, setMofId, mofxUsuarioBody, idPerfil
    , infoClient, infoPuesto, infoEstado, setOpcionVer, setType, getAprobado } = props;


  const url = "'" + window.location.hash.replace("#", "") + "'";

  //----- Para Filtrar la primera tabla-----------------------------------------------
  const filterByClient = () => {
    if (infoClient && infoClient.value !== "" && infoClient.value !== null) {
      return listaMof?.filter(mof => +mof.cliente_id === infoClient.value);
    } else {
      return listaMof;
    }
  };

  // Función para filtrar la lista de MOF filtrada por el puesto seleccionado
  const filterByPosition = (filteredMofList) => {
    if (infoPuesto && infoPuesto.value !== "" && infoPuesto.value !== null) {
      return filteredMofList?.filter(mof => +mof.puesto_id === infoPuesto.value);
    } else {
      return filteredMofList;
    }
  };
  const filterByEstado = (filteredMofList) => {
    if (infoEstado && infoEstado.value !== "" && infoEstado.value !== null) {
      return filteredMofList?.filter(mof => +mof.estado === +infoEstado.value);
    } else {
      // Si el estado seleccionado es "Seleccionar" o nulo, devolver la lista original
      return filteredMofList;
    }
  };

  // Función para filtrar la lista de MOF por conformidad
  const filterByConformidad = (filteredMofList) => {
    if (infoConformidad === "1") {
      return filteredMofList?.filter(mof => mof.pregunta_id !== null);
    } else if (infoConformidad === "0") {
      return filteredMofList?.filter(mof => mof.pregunta_id === null);
    } else {
      return filteredMofList;
    }
  };

  const filteredMofList = filterByConformidad(filterByEstado(filterByPosition(filterByClient())));


  //----------------------Fin de Filtrar Primera Tabla----------------------------------------------------------------


  //-------Para Filtrar la Segunda Tabla--------------------------------------------------------
  const filterByClient2 = () => {
    if (infoClient && infoClient.value !== "" && infoClient.value !== null) {
      return mofxUsuarioBody?.filter(mof => +mof.cliente_id === infoClient.value);
    } else {
      return mofxUsuarioBody;
    }
  };
  // Función para filtrar la lista de mofxUsuarioBody filtrada por el puesto seleccionado
  const filterByPosition2 = (filteredMofList2) => {
    if (infoPuesto && infoPuesto.value !== "" && infoPuesto.value !== null) {
      return filteredMofList2?.filter(mof => +mof.puesto_id === infoPuesto.value);
    } else {
      return filteredMofList2;
    }
  };
  const filterByEstado2 = (filteredMofList2) => {
    if (infoEstado && infoEstado.value !== "" && infoEstado.value !== null) {
      return filteredMofList2?.filter(mof => +mof?.estado === infoEstado?.value);
    } else {
      // Si el estado seleccionado es "Seleccionar" o nulo, devolver la lista original
      return filteredMofList2;
    }
  };

  // Función para filtrar la lista de MOF por conformidad
  const filterByConformidad2 = (filteredMofList2) => {
    if (infoConformidad === "1") {
      return filteredMofList2?.filter(mof => mof.pregunta_id !== null);
    } else if (infoConformidad === "0") {
      return filteredMofList2?.filter(mof => mof.pregunta_id === null);
    } else {
      return filteredMofList2;
    }
  };

  const filteredMofList2 = filterByConformidad2(filterByEstado2(filterByPosition2(filterByClient2())));
  //---------------------Fin de filtrar Segunda Tabla--------------------------------------------------------------------------

  const eliminarMof = async (id_mof) => {
    try {
      const result = await Toast.fire({
        icon: 'question',
        title: '¿Estás seguro que deseas eliminar este MOF?',
        showConfirmButton: true,
        showCancelButton: true,
        confirmButtonText: 'Sí',
        cancelButtonText: 'No',
      });

      if (result.isConfirmed) {
        setOpenModalLoader(true);
        try {
          const result = await MofService.eliminarMof(id_mof);
          if (result.status === CODES.SUCCESS_200) {
            Toast.fire({
              icon: 'success',
              title: result.data.message || 'MOF Eliminado'
            });
            setListaMof(result.data.listaMof);
            listarMof();
          } else {
            Toast.fire({
              icon: 'error',
              title: (result?.response?.data?.message || 'Error, no se eliminó el MOF')
            });
          }
          return [];
        } catch (error) {
          console.log(`Error en eliminarMof: ${error}`);
        } finally {
          setOpenModalLoader(false);

        }
      } else {
        // El usuario canceló la eliminación
        Toast.fire({
          icon: 'info',
          title: 'La eliminación del MOF ha sido cancelada.'
        });
      }
    } catch (error) {
      console.log(`Error en eliminarMof: ${error}`);
    }
  };

  const exportMof = async (id_cliente, id_puesto, desEmpresa, desCliente, desPuesto) => {

    try {
      setTitleModalLoader('Generando MOF en excel...');
      setOpenModalLoader(true);
      const result = await MofService.exportMof(url, {
        id_cliente: id_cliente,
        id_puesto: id_puesto,
      });
      if (result.status === CODES.SUCCESS_200) {
        const blob = new Blob([result.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        const url = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.download = "MOF_" + desEmpresa + "_" + desCliente + "_" + desPuesto + ".xlsx";
        link.click();
        URL.revokeObjectURL(url);
        setOpenModalLoader(false);
      } else {
        setOpenModalLoader(false);
        setTitleModalLoader(null);
      }
      return []
    } catch (error) {
      console.log(`Error en generateSede: ${error}`);
    }
  }

  if (idPerfil === 16 || idPerfil === 1) {
    return (
      <div className="page-body-main">
        <div className="page-body__table" style={{ overflow: "hidden" }}>
          <div className="page-body-table">
            <TableContainer className="table-height">
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    {columns.map((column) => (
                      <TableCell key={column.id} align={column.align} style={{ minWidth: column.minWidth }}>
                        {column.label}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {filteredMofList?.map((record, indexRow) => (
                    <TableRow hover role="checkbox" tabIndex={-1} key={"row" + indexRow}>
                      {columns.map((column, indexColumn) => (
                        <TableCell key={"col" + indexColumn} align={column.align}>
                          {column.id === "" ? indexRow + 1 :
                            column.id === "desEstado" ?
                              <span style={{ color: record.desEstado === 'Proceso' ? 'red' : record.desEstado === 'Terminado' ? 'green' : 'inherit' }}>
                                {eval(`record.${column.id}`)}
                              </span>
                              :
                              column.id === "actions" ?
                                <ButtonGroup disableElevation variant="contained" aria-label="Disabled elevation buttons">
                                  <IconButton size="small" title="Ver Mof"
                                    onClick={() => {
                                      getDatosMof(record.id_mof);
                                      getAprobado(record.id_mof)
                                      setPuestoId(record.puesto_id);
                                      setCompanieId(record.cliente.empresa_id);
                                      setClienteId(record.cliente_id);
                                      setMofId(record.id_mof);
                                      handleOpen();
                                      setOpcionVer(5)
                                    }}
                                  >
                                    <VisibilityIcon color="info" fontSize="small" />
                                  </IconButton>
                                  <IconButton
                                    aria-label="delete"
                                    size="small"
                                    onClick={() => {
                                      getDatosMof(record.id_mof);
                                      getAprobado(record.id_mof)
                                      setPuestoId(record.puesto_id);
                                      setCompanieId(record.cliente.empresa_id);
                                      setClienteId(record.cliente_id);
                                      setMofId(record.id_mof);
                                      handleOpen();
                                      setOpcionVer(null);
                                      setType(1);
                                    }}
                                    title="Editar Mof"
                                  >
                                    <EditIcon color="warning" fontSize="small" />
                                  </IconButton>
                                  <IconButton size="small" title="Descargar Mof Excel"
                                    onClick={() => {
                                      exportMof(
                                        record.cliente.id_cliente,
                                        record.puesto_id,
                                        record.cliente.empresa.desEmpresa,
                                        record.cliente.desCliente,
                                        record.puesto.desPuesto);
                                    }}
                                  >
                                    <FileDownloadIcon color="success" fontSize="small" />
                                  </IconButton>
                                  <IconButton size="small" title="Eliminar Mof"
                                    onClick={() => {
                                      eliminarMof(record.id_mof)
                                    }}
                                  >
                                    <DeleteForeverIcon color="error" fontSize="small" />
                                  </IconButton>
                                </ButtonGroup>
                                : eval(`record.${column.id}`)}
                        </TableCell>
                      ))}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <div className="page-body-main">
        <div className="page-body__table" style={{ overflow: "hidden" }}>
          <div className="page-body-table">
            <TableContainer className="table-height">
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    {columns2.map((column2) => (
                      <TableCell key={column2.id} align={column2.align} style={{ minWidth: column2.minWidth }}>
                        {column2.label}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {/* Mapear los elementos de mofxUsuario y mostrar los valores en la tabla */}
                  {filteredMofList2 && filteredMofList2.map((row, index) => (
                    <TableRow key={index}>
                      <TableCell>{index + 1}</TableCell>
                      <TableCell>{row.codMof}</TableCell>
                      <TableCell>{row.desEmpresa}</TableCell>
                      <TableCell>{row.desCliente}</TableCell>
                      <TableCell>{row.desPuesto}</TableCell>
                      <TableCell style={{ color: row.desEstado === 'Proceso' ? 'red' : row.desEstado === 'Terminado' ? 'green' : 'inherit' }}>
                        {row.desEstado}
                      </TableCell>
                      <TableCell>{row.trabajador}</TableCell>
                      <TableCell>
                        <ButtonGroup disableElevation variant="contained" aria-label="Disabled elevation buttons">
                          {+row.permiso_id === 1 && (
                            <>
                              <IconButton size="small" title="Ver Mof"
                                onClick={() => {
                                  getDatosMof(row.id_mof);
                                  setPuestoId(row.puesto_id);
                                  setCompanieId(row.empresa_id);
                                  setClienteId(row.cliente_id);
                                  setMofId(row.id_mof);
                                  handleOpen();
                                  setOpcionVer(5)
                                }}
                              >
                                <VisibilityIcon color="info" fontSize="small" />
                              </IconButton>
                              <IconButton size="small" title="Descargar Mof Excel" onClick={() => {
                                exportMof(row.cliente_id, row.puesto_id, row.desEmpresa, row.desCliente, row.desPuesto);
                              }}
                              >
                                <FileDownloadIcon color="success" fontSize="small" />
                              </IconButton>
                            </>
                          )}
                          {+row.permiso_id === 2 && (
                            <>
                              <IconButton size="small" title="Ver Mof"
                                onClick={() => {
                                  getDatosMof(row.id_mof);
                                  setPuestoId(row.puesto_id);
                                  setCompanieId(row.empresa_id);
                                  setClienteId(row.cliente_id);
                                  setMofId(row.id_mof);
                                  handleOpen();
                                  setOpcionVer(5)
                                }}
                              >
                                <VisibilityIcon color="info" fontSize="small" />
                              </IconButton>
                              <IconButton size="small" title="Editar Mof"
                                onClick={() => {
                                  getDatosMof(row.id_mof);
                                  setPuestoId(row.puesto_id);
                                  setCompanieId(row.empresa_id);
                                  setClienteId(row.cliente_id);
                                  setMofId(row.id_mof);
                                  handleOpen();
                                  setOpcionVer(null)
                                }}

                              >
                                <EditIcon color="warning" fontSize="small" />
                              </IconButton>
                              <IconButton size="small" title="Descargar Mof Excel"
                                onClick={() => {
                                  exportMof(
                                    row.cliente_id,
                                    row.puesto_id,
                                    row.desEmpresa,
                                    row.desCliente,
                                    row.desPuesto);
                                }}
                              >
                                <FileDownloadIcon color="success" fontSize="small" />
                              </IconButton>
                            </>
                          )}
                        </ButtonGroup>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        </div>
      </div>
    );
  }
};



export default BodyMof;
