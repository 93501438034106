import axios from "axios";
import { Global } from '../utils/general';
import headerRequest from "../utils/headers"

const API_URL = Global.url;
const SYS = Global.system;

class ShiftService {
    getTurno() {
        let ruta = `${API_URL}/shift`;
        return axios
            .get(ruta, {
                headers: headerRequest(),
            })
            .catch((error) => {
                return error;
            });
    }

    getTurnoBySede(url, id) {
        let ruta = `${API_URL}/shift/shift-by-sede/${id}?sys=${SYS}&url=${url}`;
        return axios.get(ruta, {
            headers: headerRequest(),
        }).catch((error) => {
            return error;
        });
    }
}

export default new ShiftService();
