import React, {  } from 'react';
import {Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from '@mui/material';

const columns = [
  { id: 'trabajador', label: 'Trabajador', minWidth: 250, align: 'left', },
  { id: 'fecha', label: 'Fecha', minWidth: 120, align: 'left', },
  { id: 'hora', label: 'Hora', minWidth: 120, align: 'left', },
  { id: 'desMarca', label: 'Motivo', minWidth: 120, align: 'left', },
  { id: 'descripcion', label: 'Estado', minWidth: 120, align: 'left', },
];

const BodyRegister = (props) => {
  const { records } = props;

  return (
    <div className='page-body-main'>
      <div className='page-body__table' style={{ overflow: 'hidden' }}>
        <div className='page-body-table'>
          <TableContainer className='table-height'>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  {columns.map((column) => (
                    <TableCell
                      key={column.id}
                      align={column.align}
                      style={{ minWidth: column.minWidth }}
                    >
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {records?.map((record, indexRow) => {
                  return (
                    <TableRow hover role="checkbox" tabIndex={-1} key={"row" + indexRow}>
                      {columns.map((column, indexColumn) => {
                        const value = record[column.id];
                        return (
                          <TableCell key={'col' + indexColumn} align={column.align}>
                            {column.id === 'trabajador' ?
                              record.nombres + ' ' + record.apePaterno :
                              column.id === 'hora' ?
                                record.hora.substr(0, 8) :
                                value}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      </div>
    </div>
  );
}
export default BodyRegister;