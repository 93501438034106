import React from 'react'
import { Select, Input } from "antd";
const { Option } = Select;
const { TextArea } = Input;

const EditInfoform = ({ approvers, serviceStatus, infoService, setInfoService }) => {

    return (
        <div className='row'>
            <div className='col-md-6'>
                <div className='group'>
                    <div className="contenido" style={{ width: '100%', margin: '0px', }}>
                        <label className="label" style={{ fontSize: 'small', top: '-2px', }}>Atendido por:</label>
                        <Select
                            mode={"single"}
                            maxTagCount={'responsive'}
                            className="select"
                            size={'small'}
                            placeholder={'Seleccionar atendido por'}
                            optionFilterProp="children"
                            showSearch
                            popupClassName='my-drop-down'
                            value={+infoService?.id_atentido}
                            onChange={(value, event) => setInfoService({ ...infoService, id_atentido: value })}
                            required={true}
                            style={{ width: '100%' }}
                        >
                            <Option value={0}><em>Seleccionar</em></Option>
                            {approvers?.map((opt, idx) => {
                                return (
                                    <Option key={`approvers-${idx}`} value={opt?.value}>
                                        {opt?.label}
                                    </Option>
                                )
                            })}
                        </Select>
                    </div>
                </div>
            </div>
            <div className='col-md-3'>
                <div className='group'>
                    <div className="contenido" style={{ width: '100%', margin: '0px', }}>
                        <label className="label" style={{ fontSize: 'small', top: '-2px', }}>Estado:</label>
                        <Select
                            mode={"single"}
                            maxTagCount={'responsive'}
                            className="select"
                            size={'small'}
                            placeholder={'Seleccionar Estado'}
                            optionFilterProp="children"
                            showSearch
                            popupClassName='my-drop-down'
                            value={+infoService?.id_estadoServ}
                            onChange={(value, event) => setInfoService({ ...infoService, id_estadoServ: value })}
                            required={true}
                            style={{ width: '100%' }}
                        >
                            <Option value={0}><em>Seleccionar</em></Option>
                            {serviceStatus?.map((opt, idx) => {
                                return (
                                    <Option key={`serviceStatus-${idx}`} value={opt?.value}>
                                        {opt?.label}
                                    </Option>
                                )
                            })}
                        </Select>
                    </div>
                </div>
            </div>
            <div className='col-md-3'>
                <div className='group'>
                    <div className="contenido" style={{ width: '100%', margin: '0px', }}>
                        <label className="label" style={{ fontSize: 'small', top: '-2px', }}>Comprobante</label>
                        <Input size="small" placeholder="comprobante" type='text'
                            value={infoService?.comprobante}
                            onChange={(event) => setInfoService({ ...infoService, comprobante: event.target.value })} />
                    </div>
                </div>
            </div>
            {+infoService?.id_estadoServ === 3 && (
                <div className={`col-md-12`}>
                    <div className='group'>
                        <div className="contenido" style={{ width: '100%', margin: '0px', }}>
                            <label className="label" style={{ fontSize: 'small', top: '-2px', }}>Motivo de rechazo</label>
                            <TextArea
                                rows={2}
                                placeholder="Motivo (300 caracteres max.)"
                                maxLength={300}
                                value={infoService?.motivo}
                                onChange={(event) => setInfoService({ ...infoService, motivo: event.target.value })}
                                required
                            />
                        </div>
                    </div>
                </div>
            )}
            <div className='col-md-8'>
                <div className='group'>
                    <div className="contenido" style={{ width: '100%', margin: '0px', }}>
                        <label className="label" style={{ fontSize: 'small', top: '-2px', }}>Servicio</label>
                        <Input size="small" placeholder="Servicio" type='text'
                            style={{ color: 'rgba(0, 0, 0, 0.65)', backgroundColor: '#f5f5f5', }}
                            value={infoService?.servicio} disabled={true} />
                    </div>
                </div>
            </div>
            <div className='col-md-4'>
                <div className='group'>
                    <div className="contenido" style={{ width: '100%', margin: '0px', }}>
                        <label className="label" style={{ fontSize: 'small', top: '-2px', }}>Importe</label>
                        <Input size="small" placeholder="Servicio" type='number'
                            style={{ color: 'rgba(0, 0, 0, 0.65)', backgroundColor: '#f5f5f5', }}
                            value={parseFloat(infoService?.importeSinIGV)?.toFixed(2)} />
                    </div>
                </div>
            </div>
            <div className='col-md-8'>
                <div className='group'>
                    <div className="contenido" style={{ width: '100%', margin: '0px', }}>
                        <label className="label" style={{ fontSize: 'small', top: '-2px', }}>Empresa</label>
                        <Input size="small" placeholder="Empresa" type='text'
                            style={{ color: 'rgba(0, 0, 0, 0.65)', backgroundColor: '#f5f5f5', }}
                            value={infoService?.desEmpresa} />
                    </div>
                </div>
            </div>
            <div className='col-md-4'>
                <div className='group'>
                    <div className="contenido" style={{ width: '100%', margin: '0px', }}>
                        <label className="label" style={{ fontSize: 'small', top: '-2px', }}>Tipo Comprobante</label>
                        <Input size="small" placeholder="Tipo Comprobante" type='text'
                            style={{ color: 'rgba(0, 0, 0, 0.65)', backgroundColor: '#f5f5f5', }}
                            value={infoService?.tipoComprobante} />
                    </div>
                </div>
            </div>
            <div className='col-md-8'>
                <div className='group'>
                    <div className="contenido" style={{ width: '100%' }}>
                        <label className="label" style={{ fontSize: 'small', top: '-2px', }}>Razon Social</label>
                        <Input size="small" placeholder="Razon" type='text'
                            style={{ color: 'rgba(0, 0, 0, 0.65)', backgroundColor: '#f5f5f5', }}
                            value={infoService?.nombres} />
                    </div>
                </div>
            </div>
            <div className='col-md-4'>
                <div className='group'>
                    <div className="contenido" style={{ width: '100%' }}>
                        <label className="label" style={{ fontSize: 'small', top: '-2px', }}>Documento</label>
                        <Input size="small" placeholder="Documento" type='text'
                            style={{ color: 'rgba(0, 0, 0, 0.65)', backgroundColor: '#f5f5f5', }}
                            value={infoService?.documento} />
                    </div>
                </div>
            </div>
            <div className='col-md-4'>
                <div className='group'>
                    <div className="contenido" style={{ width: '100%', margin: '0px', }}>
                        <label className="label" style={{ fontSize: 'small', top: '-2px', }}>Condición Pago</label>
                        <Input size="small" placeholder="Condicion" type='text'
                            style={{ color: 'rgba(0, 0, 0, 0.65)', backgroundColor: '#f5f5f5', }}
                            value={infoService?.condicionPago} />
                    </div>
                </div>
            </div>
            <div className='col-md-4'>
                <div className='group'>
                    <div className="contenido" style={{ width: '100%', margin: '0px', }}>
                        <label className="label" style={{ fontSize: 'small', top: '-2px', }}>Moneda</label>
                        <Input size="small" placeholder="Moneda" type='text'
                            style={{ color: 'rgba(0, 0, 0, 0.65)', backgroundColor: '#f5f5f5', }}
                            value={infoService?.moneda} />
                    </div>
                </div>
            </div>
            <div className='col-md-4'>
                <div className='group'>
                    <div className="contenido" style={{ width: '100%', margin: '0px', }}>
                        <label className="label" style={{ fontSize: 'small', top: '-2px', }}>Centro de costos</label>
                        <Input size="small" placeholder="Centro de costos" type='text'
                            style={{ color: 'rgba(0, 0, 0, 0.65)', backgroundColor: '#f5f5f5', }}
                            value={infoService?.names} />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default EditInfoform