import axios from "axios";
import { Global } from '../utils/general';
import headerRequest from "../utils/headers"

const API_URL = Global.url;
const SYS = Global.system;

class SupervisorsService {
    getSupervisorsArea(id) {
        var ruta = `${API_URL}/supervisors-area/${id}`;
        return axios.get(ruta, {
            headers: headerRequest(),
        }).catch((error) => {
            return error;
        });

    }

    getSupervisorsTurno(id) {
        var ruta = `${API_URL}/supervisors-turn/${id}`;
        return axios.get(ruta, {
            headers: headerRequest(),
        }).catch((error) => {
            return error;
        });
    }

    getTrabajadorbysupervisorArea(supervisor_id) {
        var ruta = `${API_URL}/worker-supervisors-area/${supervisor_id}`;
        return axios.get(ruta, {
            headers: headerRequest(),
        }).catch((error) => {
            return error;
        });
    }

    saveSupervisorArea(url, body) {
        let ruta = `${API_URL}/supervisors-area?sys=${SYS}&url=${url}`;
        return axios.post(ruta, body, {
            headers: headerRequest(),
        }).catch((error) => {
            return error;
        });
    }

    saveSupervisorTurno(url, body) {
        let ruta = `${API_URL}/supervisors-shift?sys=${SYS}&url=${url}`;
        return axios.post(ruta, body, {
            headers: headerRequest(),
        }).catch((error) => {
            return error;
        });
    }
}

export default new SupervisorsService();
