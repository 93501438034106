import React from "react";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, ButtonGroup, IconButton } from "@mui/material";
import MofService from '../../../services/mof';
import VisibilityIcon from '@mui/icons-material/Visibility';
import EditIcon from '@mui/icons-material/Edit';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import EditOffIcon from '@mui/icons-material/EditOff';
import { Toast } from '../../../utils/alerts';
import { CODES } from '../../../utils/responseCodes';

const columns = [
  { id: "", label: "#", minWidth: 50, align: "left" },
  { id: "codMof", label: "MOF", minWidth: 150, align: "left" },
  { id: "desEmpresa", label: "Empresa", minWidth: 200, align: "left" },
  { id: "desCliente", label: "Cliente", minWidth: 200, align: "left" },
  { id: "desPuesto", label: "Puesto", minWidth: 200, align: "left" },
  { id: "desEstado", label: "Estado", minWidth: 150, align: "left" },
  { id: "actions", label: "Permisos", minWidth: 120, align: "left" },
];

const BodyAssignMof = (props) => {
  const { mofxUsuario, searchValue, getUsuarioxMof, setOpenModalLoader } = props;


  // Verifica si mofxUsuario es undefined antes de filtrar
  const filteredMofxUsuario = mofxUsuario ?
    mofxUsuario.filter((row) =>
      row.codMof.toLowerCase().includes(searchValue.toLowerCase()) ||
      row.desEmpresa.toLowerCase().includes(searchValue.toLowerCase()) ||
      row.desCliente.toLowerCase().includes(searchValue.toLowerCase()) ||
      row.desPuesto.toLowerCase().includes(searchValue.toLowerCase()) ||
      row.desEstado.toLowerCase().includes(searchValue.toLowerCase())// el valor de Estado
    )
    : [];

 

  const guardado = async (mof_id, usuario_id, permiso_id) => {

    setOpenModalLoader(true);
    try {
      const result = await MofService.actualizarPermiso(mof_id, usuario_id, permiso_id);
      if (result.status === CODES.SUCCESS_200) {
        Toast.fire({
          icon: 'success',
          title: result.data.message || 'Permiso Asignado'
        })
      } else {
        Toast.fire({
          icon: 'error',
          title: (result?.response?.data?.message || 'No se pudo asignar el permiso')
        })
      }
      return [];
    } catch (error) {
      console.log(`Error en guardado: ${error}`);
    } finally {
      getUsuarioxMof();

    }
    setOpenModalLoader(false);

  }

  return (
    <div className="page-body-main">
      <div className="page-body__table" style={{ overflow: "hidden" }}>
        <div className="page-body-table">
          <TableContainer className="table-height">
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  {columns.map((column) => (
                    <TableCell key={column.id} align={column.align} style={{ minWidth: column.minWidth }}>
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredMofxUsuario.map((row, index) => (
                  <TableRow key={index}>
                    <TableCell>{index + 1}</TableCell>
                    <TableCell>{row.codMof}</TableCell>
                    <TableCell>{row.desEmpresa}</TableCell>
                    <TableCell>{row.desCliente}</TableCell>
                    <TableCell>{row.desPuesto}</TableCell>
                    <TableCell style={{ color: row.desEstado === 'Proceso' ? 'red' : row.desEstado === 'Terminado' ? 'green' : 'inherit' }}>
                      {row.desEstado}
                    </TableCell>
                    <TableCell>

                      <ButtonGroup disableElevation variant="contained" aria-label="Disabled elevation buttons">
                        {+row.permiso_id === 1 ? (
                          <IconButton size="small" title="Lectura Activada"
                            onClick={() => { guardado(row.mof_id, row.usuario_id, 0) }}
                          >
                            <VisibilityIcon color="info" fontSize="small" />
                          </IconButton>
                        ) : (
                          <IconButton size="small" title="Lectura Desactivada"
                            onClick={() => { guardado(row.mof_id, row.usuario_id, 1) }}
                          >
                            <VisibilityOffIcon color="gray" fontSize="small" />
                          </IconButton>
                        )}
                        {+row.permiso_id === 2 ? (
                          <IconButton
                            size="small"
                            onClick={() => { guardado(row.mof_id, row.usuario_id, 0) }}
                            title="Edición Activada"
                          >
                            <EditIcon color="warning" fontSize="small" />
                          </IconButton>
                        ) : (
                          <IconButton size="small" title="Edición Desactivada"
                            onClick={() => { guardado(row.mof_id, row.usuario_id, 2) }}
                          >
                            <EditOffIcon color="gray" fontSize="small" />
                          </IconButton>
                        )
                        }
                      </ButtonGroup>

                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      </div>
    </div>
  );
};


export default BodyAssignMof;
