import React, { useState, useEffect } from 'react';
import { Box, Grid, Select, MenuItem, Typography } from '@mui/material';
import { Input } from "antd";
import { useTheme } from '@mui/material/styles';


const AddEquipments = (props) => {
    const { } = props;
    const [selectedOption, setSelectedOption] = useState(0);

    return (
        <>
        
           <Grid item xs={12} sm={10} md={12}>
            <Grid container p={1}>
                <Grid item xs={12}>
                    <fieldset>
                        <legend style={{ fontSize: 'medium' }}>Equipo</legend>
                        <Select
                            style={{ width: '100%' }}
                            size='small'
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={selectedOption}
                            onChange={(e) => setSelectedOption(e.target.value)}
                        >
                            <MenuItem value={0}><em>Seleccionar</em></MenuItem>
                            <MenuItem value={1}>Laptops</MenuItem>
                            <MenuItem value={2}>Celular</MenuItem>
                            <MenuItem value={3}>Otros</MenuItem>
                        </Select>
                    </fieldset>
                </Grid>
                {(selectedOption === 1  || selectedOption === 2) && (
                    <Grid item xs={12} marginTop={'1.5rem'}>
                        <fieldset>
                            <legend style={{ fontSize: 'medium' }}>Modelo</legend>
                            <Input
                                type="text"
                                style={{
                                    marginTop: '0.1rem',
                                    paddingTop: '0.7rem',
                                    color: "rgba(0, 0, 0, 0.65)",
                                    backgroundColor: "#ffffff",
                                }}
                            />
                        </fieldset>
                    </Grid>
                )}
                {selectedOption === 3 && (
                    <>
                        <Grid item xs={12} marginTop={'1.5rem'}>
                            <fieldset>
                                <legend style={{ fontSize: 'medium' }}>Tipo de Producto</legend>
                                <Input
                                    type="text"
                                    style={{
                                        marginTop: '0.1rem',
                                        paddingTop: '0.7rem',
                                        color: "rgba(0, 0, 0, 0.65)",
                                        backgroundColor: "#ffffff",
                                    }}
                                />
                            </fieldset>
                        </Grid>
                        <Grid item xs={12} marginTop={'1.5rem'}>
                            <fieldset>
                                <legend style={{ fontSize: 'medium' }}>Otro</legend>
                                <Input
                                    type="text"
                                    style={{
                                        marginTop: '0.1rem',
                                        paddingTop: '0.7rem',
                                        color: "rgba(0, 0, 0, 0.65)",
                                        backgroundColor: "#ffffff",
                                    }}
                                />
                            </fieldset>
                        </Grid>
                    </>
                )}
            </Grid>
        </Grid>

        </>
    );
};

export default AddEquipments;