import axios from "axios";
import { Global } from '../utils/general';
import headerRequest from "../utils/headers"

const API_URL = Global.url;
const SYS = Global.system;

class OrganizationalService {
    getJefesTrabajador(url, id_trabajador) {
        let ruta = `${API_URL}/organizational/worker-boss/${id_trabajador}?sys=${SYS}&url=${url}`;
        return axios.get(ruta, {
            headers: headerRequest(),
        }).catch((error) => {
            return error;
        });
    }

    getTrabajadoresByJefe(url, id_jefe) {
        let ruta = `${API_URL}/organizational/boss-worker/${id_jefe}?sys=${SYS}&url=${url}`;
        return axios.get(ruta, {
            headers: headerRequest(),
        }).catch((error) => {
            return error;
        });
    }
}

export default new OrganizationalService();
