import React, { } from 'react';
import EditIcon from '@mui/icons-material/Edit';
import { formatDate } from '../../../utils/function';

const columns = [
  { id: 'actions', label: 'Acciones', width: 800, align: 'left', },
  { id: 'fechaRegistro', label: 'Fecha registro', minWidth: 100, align: 'left', format: (value) => formatDate(new Date(value)) },
  { id: 'desEmpresa', label: 'Empresa, emitir el comprobante', minWidth: 180, align: 'left', },
  { id: 'nombres', label: 'Razón Social o Apellidos y Nombres', minWidth: 180, align: 'left', },
  { id: 'documento', label: 'Ruc ó DNI', minWidth: 100, align: 'center', },
  { id: 'servicio', label: 'Descripción', minWidth: 250, align: 'left', },
  { id: 'email', label: 'Correo', minWidth: 150, align: 'center', },
  { id: 'telefono', label: 'Nro. Teléfono', minWidth: 80, align: 'center', },
  { id: 'names', label: 'Centro de costo', minWidth: 180, align: 'left', },
  { id: 'emailAdd', label: 'Correos adicionales', minWidth: 150, align: 'left', },
  { id: 'moneda', label: 'Moneda', minWidth: 80, align: 'center', },
  { id: 'importeSinIGV', label: 'Importe (sin IGV)', minWidth: 100, align: 'center', format: (value) => parseFloat(value)?.toFixed(2), },
  { id: 'importeTotal', label: 'Total', minWidth: 100, align: 'center', format: (value) => parseFloat(value)?.toFixed(2), },
  { id: 'tipoComprobante', label: 'Documento a emitir', minWidth: 120, align: 'center', },
  { id: 'estadoServ', label: 'Estado', minWidth: 80, align: 'center', },
  { id: 'comprobante', label: 'Comprobante', minWidth: 80, align: 'center', },
  { id: 'fechaEnvio', label: 'Fec. de envio', minWidth: 80, align: 'center', format: (value) => (value ? formatDate(new Date(value)) : '') },
  { id: 'atendido', label: 'Atendido por', minWidth: 120, align: 'center', },
];

const BodyBillingForm = (props) => {
  const { records, viewService } = props;

  return (
    <div
      style={{
        height: '62vh',
        width: '100% !important',
        // background: '#ffffff',
        background: '#EDEFFF', // '#F2FFF7',//'#E2F8EB', //'#FFFCFC',
        padding: '5px 20px 0 20px',
        borderRadius: '20px',
        // overflow: 'auto',
        // filter: 'drop-shadow(0px 0px 62px rgba(0, 0, 0, 0.05))',
        // fontSize: '12px !important',
        border: 'solid 1px #FF9CA6',
      }}
    >
      <div
        style={{
          // overflowY: 'hidden',
          // overflowX: 'auto',
          fontFamily: '"Monserrat Regular"',
          fontSize: '11px',
          // boxSizing: 'border-box',
        }}
      >
        <div
          className="table-responsive"
          style={{
            height: '55vh', marginBottom: '0.2rem'
          }}>
          <table className="table table-hover table-bordered border-primary caption-top">
            <caption>Lista de solicitudes</caption>
            <thead className='table-primary border-warning text-center align-middle sticky-top' style={{ zIndex: 1 }}>
              <tr>
                {columns.map((column) => (
                  <th
                    scope='col'
                    key={column.id}
                    align={column.align}
                    style={{ minWidth: column.minWidth }}
                  >
                    {column.id === 'actions' ?
                      <EditIcon fontSize='small' />
                      : column.label
                    }
                  </th>
                ))}
              </tr>
            </thead>
            <tbody className="table-group-divider align-middle">
              {records?.map((record, indexRow) => {
                return (
                  <tr key={"row" + indexRow}
                    className={`${+record?.id_estadoServ !== 1 ? 'table-success' : ''}`}
                  >
                    {columns.map((column, indexColumn) => {
                      const value = record[column.id];
                      return (
                        <td key={'col' + indexColumn}
                          style={{
                            textAlign: column.align,
                            width: `${column.width}px`,
                            maxWidth: `${column.width}px`,
                            overflowX: 'scroll !important',
                          }}
                        >
                          {column.id === 'actions' ?
                            <button
                              onClick={() => viewService(2, record)}
                              className='btn btn-outline-dark btn-sm'
                              style={{ fontSize: '8px', padding: '0px', borderRadius: '6px' }}
                            >
                              <EditIcon fontSize='small' />
                            </button>
                            : column.format ?
                              column.format(value)
                              : value}
                        </td>
                      );
                    })}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
        {/* <MainPagination
            size={'small'}
            totalPages={10}// {totalPagesStaff}
            page={1}// {pageStaff}
          //setPage={setPageStaff}
          /> */}
      </div>
    </div>
  );
}
export default BodyBillingForm;