import React, { useEffect, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import { Modal, Card, CardActions, CardContent, TextField, IconButton, Checkbox, FormControlLabel, FormGroup, } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import BodyWorkers from '../../../components/TrustedWorker/AddTrustedWorker/BodyWorkers';
import TrustedWorkerService from '../../../../services/trustedWorker';
import { CODES } from '../../../../utils/responseCodes';
import { Toast } from '../../../../utils/alerts';
import _ from 'lodash';

const AddTrustedWorker = (props) => {
    const { idUser, open, handleClose, setOpenModalLoader, dataStaffs, setDataStaffs, search, setSearch,
        getTrustedWorker, infoAreas, setInfoAreas, areasOrg, getOrganigramaArea, } = props;

    const theme = useTheme();
    const url = "'" + window.location.pathname + "'";
    const [infoTrusted, setInfoTrusted] = useState(null);
    const [workerSel, setWorkerSel] = useState(null);

    useEffect(() => {
        if (!infoTrusted) {
            setInfoTrusted({
                confianza: null,
                noFiscalizado: null,
                direccion: null,
                fiscalizado: null,
                persona_id: null,
                usuario_id: idUser
            });
        }
    }, [infoTrusted])

    useEffect(() => {
        setInfoTrusted({
            confianza: null,
            noFiscalizado: null,
            direccion: null,
            fiscalizado: null,
            persona_id: null,
            usuario_id: idUser
        });
        setWorkerSel(null);
    }, [dataStaffs])

    useEffect(() => {
        setDataStaffs(null);
    }, [infoAreas])

    const cleanInfo = () => {
        setInfoTrusted(null);
        setWorkerSel(null);
        setSearch(null);
        setInfoAreas(null);
        setDataStaffs();
        handleClose();
    }

    const selectPerson = (info) => {
        let updInfo = _.cloneDeep(infoTrusted);
        updInfo.persona_id = info.id_persona || info.id_Trabajador;
        setWorkerSel(info);
        setInfoTrusted(updInfo);
    };

    const saveTrustedWorker = async (e) => {
        e.preventDefault();
        try {
            setOpenModalLoader(true);
            const result = await TrustedWorkerService.saveTrustedWorker(url, infoTrusted);
            if (result.status === CODES.CREATE_201) {
                Toast.fire({
                    icon: 'success',
                    title: result.data.message || 'Guardado correctamente'
                })
                cleanInfo();
                getTrustedWorker();
            } else {
                Toast.fire({
                    icon: 'error',
                    title: result.response.data.message || 'Error no identificado'
                })
            }
        } catch (error) {
            console.log(`Error en saveTrustedWorker: ${error}`);
        } finally {
            setOpenModalLoader(false);
        }
    };

    return (
        <Modal open={open} onClose={cleanInfo} aria-labelledby="parent-modal-title" aria-describedby="parent-modal-description">
            <form onSubmit={saveTrustedWorker}>
                <Card className="modal-customizar modal-customizar__small modal-customizar__top">
                    <CardContent>
                        <fieldset>
                            <legend style={{ fontSize: 'medium' }}>Áreas</legend>
                            <select
                                name="select"
                                value={+infoAreas || 0}
                                onChange={(e) => setInfoAreas(+e.target.value)}
                                style={{
                                    borderRadius: '8px',
                                    fontSize: '14px',
                                    padding: '5px',
                                    color: '#868686',
                                    border: 'solid 1px #C9C9C9',
                                    width: '100%',
                                }}
                            >
                                <option value={0}>Seleccionar área</option>
                                {areasOrg?.map((opt) => {
                                    return (
                                        <option key={'comp' + opt.id} value={opt.id} >{opt.label}</option>
                                    )
                                })}
                            </select>
                        </fieldset>
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }} >
                            <TextField
                                id="input-with-sx"
                                variant="standard"
                                label="busqueda de trabajadores"
                                size='small'
                                sx={{ paddingBottom: 1 }}
                                fullWidth
                                value={search || ''}
                                onChange={(e) => setSearch(e.target.value)}
                            />
                            <IconButton aria-label="buscar" size="small" style={{ border: 'solid 0.05rem', color: theme.palette.primary.main }}
                                onClick={getOrganigramaArea}>
                                <SearchIcon fontSize="inherit" />
                            </IconButton>
                        </div>
                        <BodyWorkers
                            data={dataStaffs}
                            action={selectPerson}
                            search={search}
                        />
                        {workerSel?.trabajador &&
                            <label className="label" style={{ marginTop: '1rem' }}>{workerSel?.trabajador}</label>
                        }
                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                            <FormGroup>
                                <FormControlLabel control={<Checkbox
                                    checked={+infoTrusted?.confianza === 1 ? true : false}
                                    onChange={(e) => setInfoTrusted({ ...infoTrusted, confianza: (e.target.checked === true ? 1 : 0) })}
                                />} label="Confianza" />
                                <FormControlLabel control={<Checkbox
                                    checked={+infoTrusted?.noFiscalizado === 1 ? true : false}
                                    onChange={(e) => setInfoTrusted({ ...infoTrusted, noFiscalizado: (e.target.checked === true ? 1 : 0) })}
                                />} label="No Fiscalizado" />
                            </FormGroup>
                            <FormGroup>
                                <FormControlLabel control={<Checkbox
                                    checked={+infoTrusted?.direccion === 1 ? true : false}
                                    onChange={(e) => setInfoTrusted({ ...infoTrusted, direccion: (e.target.checked === true ? 1 : 0) })}
                                />} label="Dirección" />
                                <FormControlLabel control={<Checkbox
                                    checked={+infoTrusted?.fiscalizado === 1 ? true : false}
                                    onChange={(e) => setInfoTrusted({ ...infoTrusted, fiscalizado: (e.target.checked === true ? 1 : 0) })}
                                />} label="Fiscalizado" />
                            </FormGroup>
                        </div>
                    </CardContent>
                    <CardActions
                        style={{
                            display: 'flex',
                            justifyContent: 'center'
                        }}
                    >
                        <button
                            className="main-button"
                            type='submit'
                            style={{
                                color: 'white',
                                background: theme.palette.primary.main,
                                border: `1px solid white`
                            }}
                        >
                            Guardar
                        </button>
                        <button
                            className="main-button"
                            onClick={cleanInfo}
                            style={{
                                color: 'white',
                                background: theme.palette.quaternary.main,
                                border: `1px solid white`
                            }}
                        >
                            Cancelar
                        </button>
                    </CardActions>
                </Card>
            </form>
        </Modal >
    );
}

export default AddTrustedWorker;