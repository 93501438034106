import React, { } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, } from '@mui/material';
import { convertHour } from '../../../utils/function';

const columns = [
  { id: 'id_fechaCom', label: '#', width: 50, align: 'left', },
  { id: 'fecha', label: 'Fecha', width: 120, align: 'left', },
  { id: 'tiempo', label: 'Tiempo Compensado', width: 200, align: 'center', format: (value) => convertHour(value) },
  { id: 'estado', label: 'Estado', width: 80, align: 'center', },
];

const BodyTimeCompensated = (props) => {
  const { dataTimeCompesated, } = props;

  return (
    <div className='page-body-main'>
      <div className='page-body__table page-body__table--overtime' style={{ overflow: 'hidden' }}>
        <div className='page-body-table'>
          <TableContainer className='table-height table-height--overtime'>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  {columns.map((column) => (
                    <TableCell
                      key={column.id}
                      align={column.align}
                      style={{ width: column.width, maxWidth: column.width }}
                    >
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {dataTimeCompesated?.map((row, idxRow) => {
                  return (
                    <TableRow hover role="checkbox" tabIndex={-1} key={"row" + idxRow}>
                      {columns?.map((column, indexColumn) => {
                        const value = row[column.id];
                        return (
                          <TableCell key={'col' + indexColumn} align={column.align}>
                            {column.format ?
                              column.format(+value)
                              : value}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      </div>
    </div>
  );
}
export default BodyTimeCompensated;