import axios from "axios";
import { Global } from '../utils/general';
import headerRequest from "../utils/headers"

const API_URL = Global.url;
const SYS = Global.system;

class SetviceService {
    getAprobadoresServices(url) {
        const ruta = `${API_URL}/service/approvers?sys=${SYS}&url=${url}`;
        return axios
            .get(ruta, {
                headers: headerRequest(),
            })
            .catch((error) => {
                return error;
            });
    }

    getServicios({ url, pagination = 0, page = 1, pageSize = 20, empresa_id, estadoServ_id, atendido_id, inicio, fin }) {
        const params = {
            sys: SYS,
            url: url,
            pagination: pagination,
            page: page,
            pageSize: pageSize,
            empresa_id: empresa_id,
            estadoServ_id: estadoServ_id,
            atendido_id: atendido_id,
            inicio: inicio,
            fin: fin,
        };
        const ruta = `${API_URL}/service`;
        return axios
            .get(ruta, {
                headers: headerRequest(),
                params: params
            })
            .catch((error) => {
                return error;
            });
    }

    updateServicios(url, id_servicio, body) {
        const ruta = `${API_URL}/service/${id_servicio}?sys=${SYS}&url=${url}`;
        return axios.put(ruta, body, {
            headers: headerRequest(),
        }).catch((error) => {
            return error;
        });
    }

    responseOfServiceMails(body) {
        const ruta = `${API_URL}/mails/response-of-service`;
        return axios.post(ruta, body, {
            headers: headerRequest(),
        }).catch((error) => {
            return error;
        });
    }
}

export default new SetviceService();
