import React, { useState, useEffect, useMemo } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Checkbox } from '@mui/material';

const BodyAmbientales = (props) => {
  const { datosMof, handleUpdateDatosMof, opcionVer, mofxUsuarioBody,radioValue } = props;

 
  // Filtrar las preguntas que pertenecen a la subcategoría "Ambientales"
  const preguntasAmbientales = useMemo(() => datosMof.filter(pregunta => pregunta.subCategoria_id === "3"), [datosMof]);
 

  // Inicializar el estado seleccionados basado en datosMof
  const initializeSeleccionados = () => {
    const newSeleccionados = {};
    preguntasAmbientales.forEach(pregunta => {
      if (pregunta.detalle_mof && pregunta.detalle_mof.respuesta_id) {
        const selectedIndex = pregunta.alternativas.findIndex(alternativa => alternativa.corr3.toString() == +pregunta.detalle_mof.respuesta_id);
        if (selectedIndex !== -1) {
          newSeleccionados[pregunta.id_preguntamof] = {};
          for (let i = 0; i <= selectedIndex; i++) {
            newSeleccionados[pregunta.id_preguntamof][i] = true;
          }
        }
      }
    });
    return newSeleccionados;
  };

  const [seleccionados, setSeleccionados] = useState(initializeSeleccionados);

  // Recalcular seleccionados si datosMof cambia
  useEffect(() => {
    setSeleccionados(initializeSeleccionados());
  }, [datosMof]);

  // Manejar el cambio en los checkboxes
  const handleChange = (index, preguntaId, corr3) => {
    console.log("handleChange called with:", index, preguntaId, corr3); // Añadir este log
    const newSelection = {};
    for (let i = 0; i <= index; i++) {
      newSelection[i] = true;
    }
    setSeleccionados(prevState => ({
      ...prevState,
      [preguntaId]: newSelection
    }));
    handleUpdateDatosMof(preguntaId, corr3.toString(), "respuesta_id");
  };

  return (
    <div className='page-body-main'>
      <div className='page-body__table' style={{ overflow: 'hidden' }}>
        <TableContainer className='table-height'>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                <TableCell>Ambientales</TableCell>
                <TableCell>Niveles</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {preguntasAmbientales.map((pregunta) => (
                <TableRow key={pregunta.id_preguntamof}>
                  <TableCell>{pregunta.descripcion}</TableCell>
                  <TableCell>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <span style={{ marginLeft: '0px' }}>{pregunta.alternativas[0].desc2}</span>
                      {pregunta.alternativas.map((alternativa, index) => (
                        <Checkbox
                          key={index}
                          color="primary"
                          checked={seleccionados[pregunta.id_preguntamof]?.[index] || false}
                          onChange={() => handleChange(index, pregunta.id_preguntamof, alternativa.corr3)}
                          disabled={+opcionVer === 5 || +mofxUsuarioBody?.permiso_id === 1 || +radioValue === 0 }
                        />
                      ))}
                       <span style={{ marginRight: '0px' }}>{pregunta.alternativas[0].desc3}</span>
                    </div>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </div>
  );
};

export default BodyAmbientales;
