import React, { } from 'react';
import { useTheme } from '@mui/material/styles';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { IconButton } from '@mui/material';
import { filterData } from '../../../../utils/function';

const columns = [
  { id: '#', label: '#', minWidth: 20, },
  { id: 'numdoc', label: 'Documento', minWidth: 50, align: 'center', },
  { id: 'trabajador', label: 'Trabajador', minWidth: 500, align: 'left', },
  { id: 'action', label: 'Sel.', minWidth: 50, align: 'center', },
];

const BodyWorkers = (props) => {
  const { data, action, search } = props;
  const theme = useTheme();
  let resultSearch = filterData(data, search, ['numdoc', 'trabajador']);

  return (
    <div class="table-responsive-sm"
      style={{
        maxHeight: '10rem',
        overflow: 'auto',
        border: 'solid 1px #868686',
        borderRadius: 15,
      }}
    >
      <table class="table table-sm table-striped table-hover table-bordered align-middle" style={{ fontSize: '12px', }}>
        <thead class="table-light">
          <tr
            style={{
              position: 'sticky',
              top: '0',
              zIndex: '1',
              background: 'white',
            }}
          >
            {columns.map((column) => (
              <th scope="col"
                key={column.id}
                align={column.align}
                width={column.minWidth}
              >{column.label}</th>
            ))}
          </tr>
        </thead>
        <tbody class="table-group-divider">
          {resultSearch?.map((row, index) => {
            return (
              <tr key={"row" + index}>
                {columns.map((column, indexColumn) => {
                  const value = row[column.id];
                  return (
                    <td key={'col' + indexColumn} align={column.align}>
                      {column.id === '#'
                        ? index + 1
                        : column.id === 'action' ?
                          <IconButton aria-label="buscar" size="small" style={{ border: 'solid 0.02rem', color: theme.palette.primary.main }} onClick={() => action(row)}>
                            <CheckBoxIcon fontSize="inherit" />
                          </IconButton>
                          : value}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
}
export default BodyWorkers;