export const colors =
{
    primary: {
        hex: '#0F5FA7',
        rg: 'rgb(15, 95, 167)'
    },
    secondary: {
        hex: '#00C367',
        rg: 'rgb(0, 195, 103)'
    },
    tertiary: {
        hex: '#FFC107',
        rg: 'rgb(255, 193, 7)'
    },
    quaternary: {
        hex: '#F04003',
        rg: 'rgb(240, 64, 3)'
    },
}